/* global chrome */

let console = {};
console.log = function () { };
console.error = function () { };

class ChromeExtension {
    constructor() {
        this.Id = "iloehcmieidbonccnmhmcnnhfdbmfadf"
        // this.Id = "pahnjfflleejojihcadiehlepcppjhbc";
        this.enableExtension = true;
    }

    getTabs(cb) {
        if (this.enableExtension && chrome.runtime) {
            chrome.runtime.sendMessage(
                this.Id,
                { action: "get-tabs", data: {} },
                function (response) {
                    cb(response)
                });
        }
    }

    getWindowsFocused(cb) {
        if (this.enableExtension && chrome.runtime) {
            chrome.runtime.sendMessage(
                this.Id,
                { action: "get-windows-focused", data: {} },
                function (response) {
                    cb(response)
                });
        }
    }

    checkExtension(resultCallback) {
        try {
            this.enableExtension && chrome.runtime.sendMessage(
                this.Id,
                { action: "check-extension" },
                function (resp) {
                    resultCallback(resp === true)
                }
            )
        } catch (err) {
            resultCallback(false)
        }
    }

    register() {
        if (this.enableExtension && chrome.runtime) {
            chrome.runtime.sendMessage(
                this.Id,
                { action: "register-proctoring" },
                function (ts) {
                }
            )
        }
    }

    setQuizUrl(url = "canvas.instructure.com") {
        if (this.enableExtension && chrome.runtime) {
            chrome.runtime.sendMessage(
                this.Id,
                {
                    action: "set-quiz-url",
                    quiz_url: url
                },
                function (resp) {
                    console.log("[set-quiz-url] resp=", resp);
                }
            )
        }
    }

    setAllowedUrls(urls = [
        "localhost",
        "amanin.id",
        "chrome://extensions/",
        "chrome://"]
    ) {
        if (this.enableExtension && chrome.runtime) {
            chrome.runtime.sendMessage(
                this.Id,
                {
                    action: "set-allowed-urls",
                    allowed_urls: urls
                },
                function (resp) {
                    console.log("[set-allowed-urls] resp=", resp);
                }
            )
        }
    }
}

export default ChromeExtension;
