import AudioAnalyzer from "../../libs/AudioAnalyzer";
import FileWebsocket from "../../libs/FileWebsocket";

// let console = {};
// console.log = function () { };
// console.error = function () { };

export const __Audio__ = function (_this, classes) {
    console.log("AUDIO INIT");
    this.audioAnalyzerWS = null;

    // audio Analyzer
    this.audioAnalyzer = new AudioAnalyzer();
    this.audioAnalyzerLastRecord = null;

    this.AUDIO_THRESHOLD = () => {
        switch (_this.state.examProfile.audio_sensitivity) {
            case "very-high":
                return 50
            case "high":
                return 100
            case "medium":
                return 150
            case "low":
                return 200
            case "very-low":
                return 250
            default:
                return 150
        }
    }

    this.analyzeOnDetectedAudio = (averageAmplitude, data) => {
        // send to commonws warning
        if (_this.commonWs) {
            if ( _this.sendAIWarningAudioTs === undefined ) {
                _this.sendAIWarningAudioTs = 0;
            }
            if (Date.now() - _this.sendAIWarningAudioTs > 30000) {
                _this.sendAIWarningAudioTs = Date.now();
                _this.commonWs.sendAIWarning(
                    _this.context.profile.id,
                    5,
                    Date.now(),
                    null
                );
            }
        }
        // for telkom case just send aiwarning
        return;

        if (_this.record === false) {
            console.log("[audio warning] not ready yet");
            return;
        }

        let soundThreshold = this.AUDIO_THRESHOLD();
        if (this.audioAnalyzerLastRecord !== null) {
            if (this.audioAnalyzerLastRecord !== -1) {
                // console.log("[audio] check avgAmplitude > thres, ", averageAmplitude, soundThreshold)
                if (averageAmplitude > soundThreshold) {
                    // update it, to have longer audio file
                    this.audioAnalyzerLastRecord = Date.now();
                } else if (Date.now() - this.audioAnalyzerLastRecord > 20000) {
                    console.log("[audio] send signal audio to stop");
                    this.audioAnalyzerLastRecord = -1;
                }
            }
        } else if (averageAmplitude > soundThreshold) {
            this.audioAnalyzerLastRecord = Date.now();
            console.log("[audio] === activate ===");
            this.audioAnalyzerWSActivate();
        }
    }

    this.analyzeStream = (stream) => {
        this.audioAnalyzer.analyzeStream(stream,
            this.analyzeOnDetectedAudio,
            null
        )
    }

    this.recordToWebsocketAudioAnalyzer = (streamWs, stream) => {
        let mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start(5000);

        mediaRecorder.ondataavailable = (blob) => {
            if (blob.data.size > 0) {
                if (this.audioAnalyzerLastRecord === null) {
                    return;
                }
                if (this.audioAnalyzerLastRecord !== -1) {
                    console.log("[audio] \tsend data");
                    streamWs.sendBlob(blob.data);
                } else {
                    console.log("[audio] === stop audio analyzer ===");
                    this.audioAnalyzerLastRecord = null;
                    mediaRecorder.stop();
                }
            }
        }
        return mediaRecorder;
    }

    this.audioAnalyzerWSActivate = () => {
        console.log("[audio] audio analyzer activate websocket");
        if (this.audioAnalyzerWS !== null && this.audioAnalyzerWS !== undefined) {
            this.audioAnalyzerWS.close();
            this.audioAnalyzerWS = null;
        }
        if (this.audioAnalyzerWS === null) {
            console.log("[audio] init File WebSocket audio");
            this.audioAnalyzerWS = new FileWebsocket(true, _this.context.profile.id, "audio", _this.context.profile.folder_rand, (err) => {
                delete this.audioAnalyzerWS;
                this.audioAnalyzerWS = null;
            });

            this.audioWsMediaRecorder = this.recordToWebsocketAudioAnalyzer(
                this.audioAnalyzerWS,
                _this.state.userMediaStream
            );
        } else {
            // _this.setState({ audioWsRegistered: true })
        }
    }
}