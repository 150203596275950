import React from "react";
import classes from "./ProgressStatus.module.scss";
import studentmono from "../assets/imgs/student-mono.png";
import studentcolor from "../assets/imgs/student2.png";
import connectionmono from "../assets/imgs/connection-mono.png";
import connectioncolor from "../assets/imgs/connection-color.png";
import robotmono from "../assets/imgs/robot-mono.png";
import robotcolor from "../assets/imgs/robot-color.png";
import equal from "../assets/imgs/equal.png";
import notequal from "../assets/imgs/notequal.png";
import PropTypes from "prop-types";

class ProgressStatus extends React.Component {
    constructor(props) {
        super(props);

        this.imgmono = studentmono;
        this.imgcolor = studentcolor;

        if (this.props.type === "connection") {
            this.imgmono = connectionmono;
            this.imgcolor = connectioncolor;
        } else if (this.props.type === "ai") {
            this.imgmono = robotmono;
            this.imgcolor = robotcolor;
        } else if (this.props.type === "equal") {
            this.imgmono = equal;
            this.imgcolor = notequal;
        }
    }

    render() {
        return <div className={classes.container}>
            <div style={{ visibility: this.props.state !== "undone" ? "visible" : "hidden" }}>
                <img alt="robot" className={this.props.state === "processing" ? classes.animateflicker : null} src={this.imgcolor} />
            </div>
            <div>
                <img alt="mono" src={this.imgmono} />
            </div>
        </div>
    }
}

ProgressStatus.propTypes = {
    type: PropTypes.string,
    state: PropTypes.string.isRequired
}

export default ProgressStatus;