// EmbeddedFrame.jsx
import React, { forwardRef, Component } from 'react';

class EmbeddedFrame extends Component {
  render() {
    return (
      <div>
        <h1>Embedded Website</h1>
        {/* Konten situs tersemat */}
      </div>
    );
  }
}

export default forwardRef((props, ref) => <EmbeddedFrame {...props} forwardedRef={ref} />);
