import { useState, useEffect } from "react";
import { getExamTokenName, getIframeTokenName, getTokenName } from "./proctor_utils";
import { Lang } from "./Language";

let console = {};
console.log = function () { };
console.error = function () { };

export const LANG = () => {
  return localStorage.getItem("lang") ? localStorage.getItem("lang") : "id"
};

export const initializeMedias = (
  videoResolution,
  captureFace = true,
  captureScreen = false,
  captureWebRtc = false,
  captureFaceHandler = null,
  captureScreenHandler = null,
  captureWebRTCHandler = null,
) => {
  let faceDefaultConstraints = {
    audio: true,
    video: {
      frameRate: { ideal: 10, max: 10 },
      width: { ideal: 480, max: 480 },
      height: { ideal: 360, max: 360 }
    }
  };

  let a = videoResolution.split("@")[0];
  let b = videoResolution.split("@")[1];
  let width = a.split("x")[0];
  let height = a.split("x")[1];
  let fps = b.replace("fps", "");

  faceDefaultConstraints.video = {
    frameRate: { ideal: fps, max: fps },
    width: { ideal: width, max: width },
    height: { ideal: height, max: height }
  }

  const screenDefaultConstraints = {
    // this configuration is used for the webrtc live 
    audio: false,
    video: {
      facingMode: "user",
      // 50KBps => 0.4mbps
      frameRate: { ideal: 10, max: 10 },
      width: { ideal: 1080, max: 1080 },
      height: { ideal: 840, max: 840 }
    },
  };

  if (captureFace === true) {
    navigator.mediaDevices
      .getUserMedia(faceDefaultConstraints)
      .then((stream) => {
        if (captureFaceHandler !== null) {
          captureFaceHandler(stream);
        }
      })
      .catch((err) => {
        console.log(
          "error occured when trying to get an access to your camera"
        );
        console.log(err);
      });
  }

  if (captureScreen === true) {
    navigator.mediaDevices
      .getDisplayMedia(screenDefaultConstraints)
      .then((stream) => {
        stream.onaddtrack = (t) => {
          console.log("on add track", t);
        }

        // stream.onremovetrack = (t) {
        //   console.log("on remove track", t)
        // }

        stream.onactive = (a) => {
          console.log("on active", a);
        }

        stream.oninactive = (a) => {
          console.log("on inactive", a);
        }

        if (captureScreenHandler !== null) {
          captureScreenHandler(stream);
        }
      })
      .catch((err) => {
        console.log(
          "error occured when trying to get an access to your camera"
        );
        console.log(err);
      });
  }

  if (captureWebRtc === true) {
    // webrtc live video
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        facingMode: "user",
        frameRate: { ideal: 8, max: 8 },
        width: { ideal: 160, max: 160 },
        height: { ideal: 120, max: 120 }
      }
    }).then((stream) => {
      captureWebRTCHandler(stream);
    }).catch((err) => {
      console.error("[error] get media", err);
    })
  }
};

export const toggleDevelopment = false;

export const convertTimestampToExportDate = (time) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const lowerCaseMode = (mode) => {
  switch (mode) {
    case "Automatic Number Plate Recognition":
      return "anpr";
    case "Automatic Something 1":
      return "anpr1";
    case "Automatic Something 2":
      return "anpr2";
    default:
      return mode.toLowerCase().replace(" ", "-");
  }
};

export const beautyMode = (mode) => {
  switch (mode) {
    case "object-detection":
      return "Object Detection";
    case "queue-detection":
      return "Queue Detection";
    case "face-recognition":
      return "Face Recognition";
    case "motion-detection":
      return "Motion Detection";
    case "Recording":
      return "Recording";
    case "heat-map":
      return "Heat Map";
    case "human-skeleton":
      return "Human Skeleton";
    case "fire-and-smoke-detection":
      return "Fire and Smoke Detection";
    case "anpr":
      return "Automatic Number Plate Recognition";
    default:
      return "Unknown-" + mode;
  }
};

export const convertTimestampToNumericDate = (time) => {
  let options = {
    // weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("id-ID", options).format(time);
};

export const convertTimestampToDate = (time) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat(Lang("id-ID", LANG(), { en: 'en-EN' }), options).format(time);
};

export const convertTimestampToDayMonth = (time) => {
  let options = {
    // weekday: "long",
    // year: "numeric",
    month: "short",
    day: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("id-ID", options).format(time);
};


export const convertTimestampToDateShort = (time) => {
  let options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",

    // hour: "numeric",
    // minute: "numeric",
    // second: "numeric",
    // hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("id-ID", options).format(time);
};

export const convertTimestampToTimeHourMinute = (time) => {
  let options = {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const convertTimestampToTime = (time) => {
  let options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const convertTimestampToSecond = (time) => {
  let options = {
    hour: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };
  let hour = new Intl.DateTimeFormat("en-US", options).format(time);

  options = {
    minute: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };
  let minute = new Intl.DateTimeFormat("en-US", options).format(time);

  options = {
    second: "numeric",
    hour12: false,
    fractionalSecondDigits: 3,
  };
  let second = new Intl.DateTimeFormat("en-US", options).format(time);

  return parseInt(hour) * 3600 + parseInt(minute) * 60 + parseFloat(second);
};

export const convertTimestampToTimeInt = (time) => {
  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    // fractionalSecondDigits: 3,
  };

  return new Intl.DateTimeFormat("en-US", options).format(time);
};

export const convertDurationToBetterFormatFraction3 = (dur) => {
  let minute = Math.floor(dur / 60);
  let second = (dur % 60).toFixed(3);
  let str_minute = minute !== 0 ? minute + "m " : "";
  let str_second = second <= 0.0 ? "" : second + "d";
  let str =
    dur.toFixed(3) +
    "d (" +
    str_minute +
    (str_second !== "" ? " " + str_second : "") +
    ")";
  return str;
};

export const convertDurationToBetterFormatInt = (dur) => {
  dur = Math.floor(dur);
  let minute = Math.floor(dur / 60);
  let second = dur - minute * 60;
  let str_minute = minute !== 0 ? minute + "m " : "";
  let str_second = second <= 0.0 ? "" : second + "d";
  let str =
    dur.toFixed(0) +
    "d (" +
    str_minute +
    (str_second !== "" ? " " + str_second : "") +
    ")";
  return str;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const modeToMpegTsPort = (
  mode,
  camera_id,
  url_substream_available = false
) => {
  let port;
  if (url_substream_available === false) {
    if (mode === "Watch") {
      mode = "WatchHD";
    }
  }
  switch (mode) {
    case "Watch":
      port = 26000 + camera_id;
      break;
    case "WatchHD":
      port = 6000 + camera_id;
      break;
    case "Face Recognition":
      port = 8000 + camera_id;
      break;
    case "Motion Detection":
      port = 10000 + camera_id;
      break;
    case "Queue Detection":
      port = 12000 + camera_id;
      break;
    case "Object Detection":
      port = 14000 + camera_id;
      break;
    case "Heat Map":
      port = 16000 + camera_id;
      break;
    case "Human Skeleton":
      port = 18000 + camera_id;
      break;
    case "Fire and Smoke Detection":
      port = 20000 + camera_id;
      break;
    case "Automatic Number Plate Recognition":
      port = 22000 + camera_id;
      break;
    default:
      port = 26000 + camera_id;
  }
  return port;
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const CameraModes = [
  "Inactive",
  "Watch",
  "Recording",
  "Face Recognition",
  "Motion Detection",
  "Queue Detection",
  "Object Detection",
  "Heat Map",
  "Human Skeleton",
  "Fire and Smoke Detection",
  "Automatic Number Plate Recognition",
];

export const fetchDataJSON = async (url, jsonData, errorListener) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getAccessToken()}`
    },
    body: JSON.stringify(jsonData),
  };
  return fetch(url, requestOptions)
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        let error = response.status;
        if (data) {
          error = data.message
        }
        return Promise.reject(error);
      } else {
        return data;
      }
    })
    .catch((error) => {
      errorListener(error);
    });
};

export const EncryptDataFromJson = (data) => {
  let CryptoJS = require("crypto-js");
  return CryptoJS.AES.encrypt(
    JSON.stringify(data),
    localStorage.getItem(getTokenName())
  ).toString();
};

export const DecryptDataToJson = (ciphertext) => {
  let CryptoJS = require("crypto-js");
  let bytes = CryptoJS.AES.decrypt(ciphertext, localStorage.getItem(getTokenName()));
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// export const MergeDefaultUserSettings = (username, data) => {
//   let priv = {};

//   if (data === null) {
//     priv = JSON.parse(JSON.stringify(defaultUserSettings));
//     data = priv;
//   }

//   priv = JSON.parse(JSON.stringify(defaultUserSettings));
//   for (let a in priv) {
//     delete priv[a].img;
//     delete priv[a].title;
//     if (username === "admin") {
//       priv[a]["enable"] = true;
//     } else if (data[a] !== undefined) {
//       if (data[a]["enable"] !== undefined) {
//         priv[a]["enable"] = data[a]["enable"];
//       }
//     }

//     for (let b in priv[a]["options"]) {
//       delete priv[a]["options"][b].title;
//       if (username === "admin") {
//         priv[a]["options"][b]["value"] = true;
//       } else if (data[a]["options"][b]["value"]) {
//         priv[a]["options"][b]["value"] = data[a]["options"][b]["value"];
//       }
//     }
//   }
//   // handle cam group
//   priv["forbidden_cam_group"] = data["forbidden_cam_group"];
//   return priv;
// };

export const formatRupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    maximumSignificantDigits: 10,
    style: "currency",
    currency: "IDR",
  }).format(number);
};

export const getBilling = (page, ratePerPage) => {
  return formatRupiah(page * ratePerPage);
};

export const sumPrice = (items, ratePerPage) => {
  return items.reduce((acc, curr) => acc + curr * ratePerPage, 0);
};

export const totalPage = (items) => {
  return items.reduce((acc, curr) => acc + curr, 0);
};

const ones = [
  "",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
];

const tens = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];

const teens = [
  "ten",
  "eleven",
  "twelve",
  "thirteen",
  "fourteen",
  "fifteen",
  "sixteen",
  "seventeen",
  "eighteen",
  "nineteen",
];


const convert_millions = (num) => {
  if (num >= 1000000) {
    return (
      convert_millions(Math.floor(num / 1000000)) +
      " million " +
      convert_thousands(num % 1000000)
    );
  } else {
    return convert_thousands(num);
  }
};
const convert_thousands = (num) => {
  if (num >= 1000) {
    return (
      convert_hundreds(Math.floor(num / 1000)) +
      " thousand " +
      convert_hundreds(num % 1000)
    );
  } else {
    return convert_hundreds(num);
  }
};
const convert_hundreds = (num) => {
  if (num > 99) {
    return (
      ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100)
    );
  } else {
    return convert_tens(num);
  }
};
const convert_tens = (num) => {
  if (num < 10) return ones[num];
  else if (num >= 10 && num < 20) return teens[num - 10];
  else {
    return tens[Math.floor(num / 10)] + " " + ones[num % 10];
  }
};

export const convertNumberToWords = (num) => {
  if (num === 0) return "zero";
  else return convert_millions(num);
};

//export const urlLocal = 'console.amanin.id';
// export const urlLocal = window.location.hostname === "localhost" ? 'proktor.amanin.id' : window.location.host;
export const urlLocal = window.location.hostname === "localhost" ? 'console.amanin.id' : window.location.host;

export const formatTime = (times) => {
  const result = times < 10 && times !== "" ? `0${times}` : times;
  return result || "-"
}

export const convertEpochTime = (epochTime) => {
  const dateObj = new Date(epochTime * 1000); // Convert epoch time to milliseconds
  return dateObj
}

export const convertStringIdToNumberId = (v) => {
  let i;
  let r = v;
  // Using for loop for (A-Z):
  for (i = 65; i <= 90; i++) {
    r = r.replaceAll(String.fromCharCode(i), "9");
  }

  // Using for loop for (a-z):
  for (i = 97; i <= 122; i++) {
    r = r.replaceAll(String.fromCharCode(i), "9");
  }
  return r;
}
export const validationValueColor2 = (dynvalidation) => {
  return validationValueColor(
    dynvalidation.green,
    dynvalidation.yellow,
    dynvalidation.orange,
    dynvalidation.pink,
    dynvalidation.red,
    dynvalidation.value
  )
}

export const validationValueColor = (green, yellow, orange, pink, red, value) => {
  if (value < green) {
    return "green"
  } else if (value < yellow) {
    return "yellow"
  } else if (value < orange) {
    return "orange"
  } else if (value < pink) {
    return "pink"
  }
  return "red";
};

export const mobileMediaQueryHandleScreenChange = (_this, event) => {
  if (event.matches) {
    // mobile handler
    console.log("set state desktop view = false")
    _this.setState({ desktopView: false })
  } else {
    // desktop handler
    _this.setState({ desktopView: true })
  }
};

export const mobileMediaQueryRemoveHandler = (mobileMediaQuery, handleScreenChange) => {
  mobileMediaQuery?.removeEventListener('change', handleScreenChange);
}

export const mobileMediaQueryHandler = (handleScreenChange) => {
  const mobileMediaQuery = window.matchMedia('(max-width: 767px)');
  handleScreenChange(mobileMediaQuery); // Cek status awal
  mobileMediaQuery.addEventListener('change', handleScreenChange)
}

export const renderMobileWarning = () => {
  return <span style={{
    color: "white",
    fontSize: "1.3rem",
    padding: "10px",
    textAlign: "center"
  }}>Untuk pengalaman terbaik, silakan akses melalui perangkat desktop atau laptop.</span>
}

export const getAccessToken = () => {
  return localStorage.getItem(getTokenName());
}

export const getAccessExamToken = () => {
  return localStorage.getItem(getExamTokenName());
}
export const getIframeToken = () => {
  return localStorage.getItem(getIframeTokenName());
}

export const getQuestionUrl = (question_list = [], question_id = 0) => {
  if (question_list.length === 0) {
    return ""
  }
  const q = question_list[question_id];
  return q || question_list[0];
}

export const telkomProktorURL = "https://console.amanin.id";
// export const telkomProktorURL = "http://localhost:3000";
// export const telkomProktorURL = "https://event.proktor.amanin.id/";

export const telkomPageURL = "https://console.amanin.id:10443";
// export const telkomPageURL = "http://localhost:5173";
