import man1 from "../../assets/imgs/manual/man1.png";
import man2 from "../../assets/imgs/manual/man2.png";
import man3 from "../../assets/imgs/manual/man3.png";
import man4 from "../../assets/imgs/manual/man4.png";
import man5 from "../../assets/imgs/manual/man5.png";
import man6 from "../../assets/imgs/manual/man6.png";
import man7 from "../../assets/imgs/manual/man7.png";
import man8 from "../../assets/imgs/manual/man8.png";
import man9 from "../../assets/imgs/manual/man9.png";
import { Lang } from "../../libs/Language";

export const __Manual__ = function (_this, classes) {
    this.renderManual = () => {
        return this.onRenderManual();
    }

    this.renderManualImage = (src, width, gbrText = "gambar something") => {
        return <div className={classes.manual_img}>
            <img alt="" src={src} width={width} />
            <span style={{
                fontSize: ".65rem",
                fontWeight: "bold"
            }}>{gbrText}</span>
        </div>
    }

    this.renderManualExam = () => {
        return <></>;
        // return <div className={classes.manonexam}>
        //     <span>Panduan Menggunakan Sistem Saat Ujian</span>
        //     <div>
        //         <ol>
        //             <li>
        //                 Silahkan masukkan kembali username dan password yang anda miliki di halaman login yang telah tersedia.
        //             </li>
        //             <li>
        //                 Apabila sudah selesai mengerjakan, anda bisa langsung keluar dengan cara klik tombol logout di halaman soal ujian, kemudian tutuplah browser anda.
        //             </li>
        //         </ol>
        //     </div>
        // </div>
    }

    this.onRenderManual = () => {
        return <div style={{ display: _this.props.mode === "mobile" ? "none" : "flex" }} className={[classes.manual, classes.ascroll].join(" ")}>
            {/* <span>Panduan Menggunakan Sistem Pengawasan Online</span> */}
            <span>{
                Lang(`Panduan Menggunakan Sistem Pengawasan Online`,
                    _this.state.lang,
                    {
                        en: `Guide to Using the Online Proctoring System`
                    })
            }</span>
            <div>
                <ol>
                    <li>
                        {/* Baca dengan seksama informasi - informasi yang di sediakan di halaman.
                        Apabila kurang jelas atau anda mengalami kendala silahkan bertanya pada penyelenggara
                        ujian lewat tombol bantuan pengawas. (Gambar.1). */}
                        {Lang(`Baca dengan seksama informasi - informasi yang di sediakan di halaman.
                        Apabila kurang jelas atau anda mengalami kendala silahkan bertanya pada penyelenggara
                        ujian lewat tombol bantuan pengawas. (Gambar.1).`,
                            _this.state.lang,
                            {
                                en: `Read carefully the information provided on this page. If you are unclear or experiencing problems, please ask the exam organizer via the  help button. (Figure 1).`
                            })}
                        {this.renderManualImage(man1, "150px", Lang("Gambar. 1", _this.state.lang, { en: 'Figure. 1' }))}
                    </li>
                    <li>
                        {/* Spesifikasi System & Hardware yang disarankan */}
                        {Lang('Spesifikasi System & Hardware yang disarankan',
                            _this.state.lang,
                            {
                                en: 'Recommended System & Hardware Specifications'
                            })}
                        <ul>
                            <li>
                                {/* Prosessor: Core i3 atau yang setara. */}
                                {Lang('Prosessor: Core i3 atau yang setara.',
                                    _this.state.lang,
                                    {
                                        en: 'Prosessor: Core i3 or equivalent.'
                                    })}
                            </li>
                            <li>
                                {/* Memory: 8GB */}
                                {Lang('Memory: 8GB',
                                    _this.state.lang,
                                    {
                                        en: 'Memory: 8GB'
                                    })}

                            </li>
                            <li>
                                {/* Browser yang disarankan adalah Google Chrome versi terbaru. */}
                                {Lang('Browser yang disarankan adalah Google Chrome versi terbaru.',
                                    _this.state.lang,
                                    {
                                        en: 'Recommended browser is the latest version of Google Chrome.'
                                    })}
                            </li>
                            <li>
                                {/* Bandwidth Internet yang tersedia setara dengan penggunaan Youtube secara wajar (membuka video Youtube dengan lancar). */}
                                {Lang('Bandwidth Internet yang tersedia setara dengan penggunaan Youtube secara wajar (membuka video Youtube dengan lancar).',
                                    _this.state.lang,
                                    {
                                        en: 'The available Internet bandwidth is equivalent to reasonable Youtube usage (opening Youtube videos smoothly).'
                                    })}
                            </li>
                            <li>
                                {/* Disarankan untuk menghapus cache pada browser anda. */}
                                {Lang('Disarankan untuk menghapus cache pada browser anda.',
                                    _this.state.lang,
                                    {
                                        en: 'It is recommended to clear the cache on your browser.'
                                    })}
                            </li>
                            <li>
                                {/* Disarankan untuk mempunyai setidaknya 1 GigaByte tersedia pada drive C: anda. */}
                                {Lang('Disarankan untuk mempunyai setidaknya 1 GigaByte tersedia pada drive C: anda.',
                                    _this.state.lang,
                                    {
                                        en: 'It is recommended to have at least 1 GigaByte available on your C: drive.'
                                    })}
                            </li>
                        </ul>
                    </li>
                    <li>
                        {/* Pencahayaan yang disarankan */}
                        {Lang('Pencahayaan yang disarankan',
                            _this.state.lang,
                            {
                                en: 'Recommended lighting'
                            })}
                        <ul>
                            <li>
                                {/* Pencahayaan pada ruangan harus cukup, tidak boleh terlalu redup. */}
                                {Lang('Pencahayaan pada ruangan harus cukup, tidak boleh terlalu redup.',
                                    _this.state.lang,
                                    {
                                        en: 'Lighting in the room should be sufficient, not too dim.'
                                    })}
                            </li>
                            <li>
                                {/* Hindari membelakangi sumber cahaya. */}
                                {Lang('Hindari membelakangi sumber cahaya.',
                                    _this.state.lang,
                                    {
                                        en: 'Avoid backlighting.'
                                    })}
                            </li>
                            <li>
                                {/* Disarankan, sumber cahaya ada di depan anda. */}
                                {Lang('Disarankan, sumber cahaya ada di depan anda.',
                                    _this.state.lang,
                                    {
                                        en: 'It is recommended that the light source is in front of you.'
                                    })}
                            </li>
                            <li>
                                {/* Cek kamera, dan pastikan wajah anda terlihat jelas di kamera. */}
                                {Lang('Cek kamera, dan pastikan wajah anda terlihat jelas di kamera.',
                                    _this.state.lang,
                                    {
                                        en: 'Check the camera, and make sure your face is clearly visible on the camera.'
                                    })}
                            </li>
                            <li>
                                {/* Posisikan tubuh anda tegak dan nyaman di depan kamera. */}
                                {Lang('Posisikan tubuh anda tegak dan nyaman di depan kamera.',
                                    _this.state.lang,
                                    {
                                        en: 'Position your body upright and comfortable in front of the camera.'
                                    })}
                            </li>
                            <li>
                                {/* Posisikan wajah untuk selalu dapat ditangkap oleh kamera. */}
                                {Lang('Posisikan wajah untuk selalu dapat ditangkap oleh kamera.',
                                    _this.state.lang,
                                    {
                                        en: 'Position your face to always be captured by the camera.'
                                    })}
                            </li>
                        </ul>
                    </li>
                    <li>
                        {/* Untuk memulai ujian, peserta <b>DIWAJIBKAN</b> untuk mengaktifkan Suara, Web Camera dan Tangkapan Layar. Berikut langkah - langkahnya: */}
                        {Lang('Untuk memulai ujian, peserta <b>DIWAJIBKAN</b> untuk mengaktifkan Suara, Web Camera dan Tangkapan Layar. Berikut langkah - langkahnya:',
                            _this.state.lang,
                            {
                                en: 'To start the exam, participants are <b>REQUIRED</b> to enable Voice, Web Camera and Screen Capture. Here are the steps:'
                            })}
                        <ul>
                            <li>
                                {/* Klik tombol "Aktifkan Web Camera dan Screen Capture" (Gambar.7). */}
                                {Lang('Klik tombol "Aktifkan Web Camera dan Screen Capture" (Gambar.7).',
                                    _this.state.lang,
                                    {
                                        en: 'Click the "Enable Web Camera and Screen Capture" button (Figure.7).'
                                    })}
                                {this.renderManualImage(man7, "350px", Lang("Gambar.7", _this.state.lang, { en: 'Figure. 7' }))}
                            </li>
                            <li>
                                {/* Apabila muncul dialog untuk mengijinkan web camera, pilih Ijinkan (Allow) (Gambar.2). */}
                                {Lang('Apabila muncul dialog untuk mengijinkan web camera, pilih Ijinkan (Allow) (Gambar.2).',
                                    _this.state.lang,
                                    {
                                        en: 'If a dialog appears to allow the web camera, select Allow (Figure.2).'
                                    })}
                                {this.renderManualImage(man2, "300px", Lang("Gambar. 2", _this.state.lang, { en: 'Figure. 2' }))}
                            </li>
                            <li>
                                {/* Juga akan muncul Kotak Dialog untuk memilih Tangkapan Layar sebagai berikut (Gambar.3). */}
                                {Lang('Juga akan muncul Kotak Dialog untuk memilih Tangkapan Layar sebagai berikut (Gambar.3).',
                                    _this.state.lang,
                                    {
                                        en: 'A Dialog Box for selecting Screenshots will also appear as follows (Figure.3).'
                                    })}
                                {this.renderManualImage(man3, "400px", Lang("Gambar. 3", _this.state.lang, { en: 'Figure. 3' }))}

                            </li>
                            <li>
                                {/* Untuk mengaktifkan tangkapan layar, pilih "Entire Screen" (Gambar.4). */}
                                {Lang('Untuk mengaktifkan tangkapan layar, pilih "Entire Screen" (Gambar.4).',
                                    _this.state.lang,
                                    {
                                        en: 'To enable screenshots, select "Entire Screen" (Fig.4).'
                                    })}
                                {this.renderManualImage(man4, "400px", Lang("Gambar. 4", _this.state.lang, { en: 'Figure. 4' }))}
                            </li>
                            <li>
                                {/* Pada tab entire screen, anda harus memilih gambar yang terdapat di dalam nya (Gambar.4) */}
                                {Lang('Pada tab entire screen, anda harus memilih gambar yang terdapat di dalam nya (Gambar.4)',
                                    _this.state.lang,
                                    {
                                        en: 'On the entire screen tab, you must select the image contained within (Figure 4).'
                                    })}
                                {this.renderManualImage(man5, "400px", Lang("Gambar. 5", _this.state.lang, { en: 'Figure. 5' }))}
                            </li>
                            <li>
                                {/* Kemudian tekan tombol share. (Gambar.6). */}
                                {Lang('Kemudian tekan tombol share. (Gambar.6).',
                                    _this.state.lang,
                                    {
                                        en: 'Then press the share button. (Fig.6).'
                                    })}
                                {this.renderManualImage(man6, "400px", Lang("Gambar. 6", _this.state.lang, { en: 'Figure. 6' }))}

                            </li>
                            <li>
                                {/* Apabila berhasil, tombol "Aktifkan Web Camera dan Screen Capture" akan diganti dengan tombol "Validasi Wajah". */}
                                {Lang('Apabila berhasil, tombol "Aktifkan Web Camera dan Screen Capture" akan diganti dengan tombol "Validasi Wajah".',
                                    _this.state.lang,
                                    {
                                        en: 'If successful, the "Enable Web Camera and Screen Capture" button will be replaced with the "Face Validation" button.'
                                    })}
                            </li>

                            <li>
                                {/* Sembunyikan tombol stop sharing, dengan cara <b>KLIK HIDE</b>, supaya tidak tertekan tanpa sengaja (Gambar.8). */}
                                {Lang('Sembunyikan tombol stop sharing, dengan cara KLIK HIDE, supaya tidak tertekan tanpa sengaja (Gambar.8).',
                                    _this.state.lang,
                                    {
                                        en: 'Hide the stop sharing button, by CLICKING HIDE, so that it is not pressed accidentally (Figure.8).'
                                    })}
                                {this.renderManualImage(man8, "400px", Lang("Gambar. 8", _this.state.lang, { en: 'Figure. 8' }))}
                            </li>
                        </ul>
                    </li>
                    <li>
                        {/* Langkah selanjutnya adalah melakukan validasi wajah. Berikut langkah - langkahnya. */}
                        {Lang('Langkah selanjutnya adalah melakukan validasi wajah. Berikut langkah - langkahnya.',
                            _this.state.lang,
                            {
                                en: 'The next step is to perform face validation. Here are the steps.'
                            })}
                        <ul>
                            <li>
                                {/* Klik tombol validasi Wajah (Gambar.9). */}
                                {Lang('Klik tombol validasi Wajah (Gambar.9).',
                                    _this.state.lang,
                                    {
                                        en: 'Click the Face validation button (Figure.9).'
                                    })}
                                {this.renderManualImage(man9, "200px", Lang("Gambar. 9", _this.state.lang, { en: 'Figure. 9' }))}

                            </li>
                            <li>
                                {/* Anda akan diarahkan ke halaman validasi wajah. */}
                                {Lang('Anda akan diarahkan ke halaman validasi wajah.',
                                    _this.state.lang,
                                    {
                                        en: 'You will be redirected to the face validation page.'
                                    })}
                            </li>
                            <li>
                                {/* Baca dengan seksama dan ikuti petunjuk - petunjuk yang ada di halaman tersebut. */}
                                {Lang('Baca dengan seksama dan ikuti petunjuk - petunjuk yang ada di halaman tersebut.',
                                    _this.state.lang,
                                    {
                                        en: 'Read carefully and follow the instructions on the page.'
                                    })}
                            </li>
                            <li>
                                {/* Apabila anda telah siap untuk di validasi, tekan tombol "Proses Validasi". */}
                                {Lang('Apabila anda telah siap untuk di validasi, tekan tombol "Proses Validasi".',
                                    _this.state.lang,
                                    {
                                        en: 'When you are ready for validation, press the "Validation Process" button.'
                                    })}
                            </li>
                            <li>
                                {/* Tunggu sampai proses validasi selesai. */}
                                {Lang('Tunggu sampai proses validasi selesai.',
                                    _this.state.lang,
                                    {
                                        en: 'Wait for the validation process to complete.'
                                    })}
                            </li>
                            <li>
                                {/* Apabila validasi anda gagal atau anda memerlukan bantuan dari pengawas. Silakan tekan tombol Bantuan Pengawas (Gambar.1). */}
                                {Lang('Apabila validasi anda gagal atau anda memerlukan bantuan dari pengawas. Silakan tekan tombol Bantuan Pengawas (Gambar.1).',
                                    _this.state.lang,
                                    {
                                        en: 'If your validation fails or you need help from the supervisor. Please press the Help button (Figure.1).'
                                    })}
                                {this.renderManualImage(man1, "150px", Lang("Gambar. 1", _this.state.lang, { en: 'Figure. 1' }))}
                            </li>
                        </ul>
                    </li>
                    <li>
                        {/* Bantuan Pengawas */}
                        {Lang('Bantuan Pengawas',
                            _this.state.lang,
                            {
                                en: 'Help'
                            })}
                        <ul>
                            <li>
                                {/* Tombol bantuan pengawas disediakan untuk membantu anda (Gambar.1). */}
                                {Lang('Tombol bantuan pengawas disediakan untuk membantu anda (Gambar.1).',
                                    _this.state.lang,
                                    {
                                        en: 'The help button is provided to assist you (Figure.1).'
                                    })}
                                {this.renderManualImage(man1, "150px", Lang("Gambar. 1", _this.state.lang, { en: 'Figure. 1' }))}
                            </li>
                            <li>
                                {/* Cukup tekan tombol pengawas, dan tunggu sampai dihubungi Pengawas yang tersedia via Private Chat. */}
                                {Lang('Cukup tekan tombol pengawas, dan tunggu sampai dihubungi Pengawas yang tersedia via Private Chat.',
                                    _this.state.lang,
                                    {
                                        en: 'Simply press the help button, and wait to be contacted by an available Supervisor via Private Chat.'
                                    })}
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>
        </div>
    }
}