/**** Common Ws ****/

import CommonWebsocket from "../../libs/CommonWebsocket";
import { Lang } from "../../libs/Language";
import WebRTC from "../../libs/WebRTC";

const DISABLE_WEBRTC = false;
const COMMONWS_RECONNECT_PERIODE = 60000 * 10; // in ms, 60000 = 1m

export const __CommonWs__ = function (_this) {
    _this.updateTsRoutineLastTs = 0;
    _this.commonWsAge = Date.now();
    _this.webrtc = null;
    _this.commonWsLastReadyTs = Date.now();
    _this.commonWsReinitialized = false;
    _this.currentTs = 0;

    this.startWebRTCHandler = (msg) => {
        if (DISABLE_WEBRTC === true) {
            return;
        }
        const participantId = msg.participantId;
        const proctorerId = msg.proctorerId;
        const proctorId = msg.proctorId;
        const videoRouter = msg.videoRouter;

        if (videoRouter === null) {
            console.error("Video Router is null, video stream will not be available.")
            return;
        }

        if ((_this.state.displayMediaStream === null && _this.mode !== "mobile") ||
            _this.state.userMediaStream === null) {
            _this.commonWs.sendWebRTCFailed(false, proctorerId, participantId, proctorId);
            return;
        }
        if (_this.webrtc !== null) {
            _this.webrtc.close();
            delete _this.webrtc;
            _this.webrtc = null;
        }

        _this.webrtc = new WebRTC(
            _this.context.profile.ice_id,
            _this.context.profile.id,
            _this.context.profile.name,
            videoRouter,
            () => {
                _this.commonWs.sendWebRTCStarted(proctorerId, participantId, proctorId);
            },
            true,
            _this.state.webRtcMediaStream,
            null,
            (error) => {
                _this.commonWs.sendWebRTCFailed(true, proctorerId, participantId, proctorId);
            }
        )
    }

    this.validationStatusHandler = (msg) => {
        // Update API fetchExamProfile validation_status
        _this._action.setValidationStatus(msg.status === "valid" ? 1 : 0)
    }

    this.commonWsOnMessageReceived = (_this, msg) => {
        msg = JSON.parse(msg);
        if (msg.type === 'start-webrtc') {
            this.startWebRTCHandler(msg);
        } else if (msg.type === "store-participant-image") {
            const use_ts = Date.now()
            _this._action.sendWebCamImageToServer("face", use_ts, true);
            _this._action.sendWebCamImageToServer("screen", use_ts, true);
        } else if (msg.type === "broadcast") {
            _this.setState({ broadcastMessage: msg });
        } else if (msg.type === "broadcast-to-participant") {
            if (_this.state.broadcastFromProctorerMessage !== msg["text"]) {
                _this.setState({ broadcastFromProctorerMessage: msg["text"] });

                alert(`PERHATIAN : ${msg["text"]}`)
            }

        } else if (msg.type === "validation-status") {
            this.validationStatusHandler(msg)
        } else if (msg.type === "currentTS") {
            _this.currentTs = _this.currentTs !== 0 && msg.ts;
        } else if (msg.type === "update-ts-confirmed") {
            /*
            _this.updateTsRoutineLastTs is the important state which schedule
            proctorerImageRef, do not comment it !!!
            */
            _this.updateTsRoutineLastTs = Date.now();
            // console.log('[update-ts] confirmed');
        }

        // Detect Keluar tab dan print screen
        if (_this.state.initExam === false) {
            document.addEventListener('visibilitychange', () => { _this._warning._openNewTabOrBrowserWarning(_this) });
            document.addEventListener('keyup', (e) => { _this._warning._printScreenWarning(_this, e) });
        }
    }

    this.commonWsOnError = (_this, err) => {
        console.error("[commonws] commonws on error, close commonws, error=", err);
        delete _this.commonWs;
        _this.commonWs = null;
        _this.pai.setCommonWs(null);
    }

    this.commonWsRoutine = () => {
        if (_this.state.initProktorAIStatus === false ||
            _this.state.fetchExamStatus === false ||
            _this.state.fetchParticipantStatus === false) {
            // console.log("fetch All is not yet done");
            _this.commonWsLastReadyTs = Date.now()
            return;
        }

        if (_this.commonWs === null) {
            if (Date.now() - _this.commonWsLastReadyTs > 20000) {
                _this.setState({
                    commonWsRegistered: false,
                    connectingMessage: Lang("Koneksi anda terputus, mencoba terhubung kembali.", _this.state.lang, { en: `Your connection is lost, try reconnecting.` })
                });
            }
        } else if (_this.commonWs.ready() === true) {
            _this.commonWsLastReadyTs = Date.now();
        } else if (Date.now() - _this.commonWsLastReadyTs > 10000) {
            _this.setState({
                commonWsRegistered: false,
                connectingMessage: Lang("Koneksi anda terputus, mencoba terhubung kembali.", _this.state.lang, { en: `Your connection is lost, try reconnecting.` })
            });
        }

        // disconnect commonws on purpose to take the benefit of autoscale
        if (_this.commonWs !== null && Date.now() - _this.commonWsAge >
            COMMONWS_RECONNECT_PERIODE
        ) {
            console.log("close commonws, because reconnect");
            _this.commonWs.sendRefreshConnection(_this.context.profile.id);

            // on reconnect do not call below, because it will trigger
            // on failed connection and set ts of participants or proctorer to 0, 
            // make online indicator become unstable
            // // _this.commonWs.close();
            // // _this.commonWsAge = Date.now();
        }

        if (_this.commonWs === null) {
            _this.commonWs = new CommonWebsocket(_this, _this.context.profile.folder_rand, this.commonWsOnMessageReceived, this.commonWsOnError);
            _this.commonWsReinitialized = true;
            _this.updateTsRoutineLastTs = Date.now();
            _this.commonWsAge = Date.now();
        }
        if (_this.state.commonWsRegistered === false || _this.commonWsReinitialized === true) {
            let reg = _this.commonWs.registerParticipant(_this.context.profile.id);

            _this.commonWsReinitialized = !reg;
            if (reg === true) {
                _this.setState({
                    commonWsRegistered: !_this.commonWsReinitialized
                });
                _this.pai.setCommonWs(_this.commonWs);
                _this._action.getCurrentTsRoutine();
            }
        }
    }
}