import { Lang } from "../../libs/Language"
import { getPhotoFromLocal, urlFaceInWebCam, urlKTPImage } from "../../libs/proctor_utils"
import { LANG, validationValueColor2 } from "../../libs/utils"

export const __ProfileImages__ = function (_this, classes) {
    this.showImages = ()=> {
        return <div
            className={classes.modal_c_profile_right}>
            <div className={classes.modal_c_profile_right_photos}>
                <div>
                    <span>{Lang(`Identitas`, LANG(), { en: `Identity` })}</span>
                    {_this.props.photo ?
                        <img
                            alt="ktp"
                            src={getPhotoFromLocal(_this.props.photo)}
                            height="200px"
                            ref={_this.identityImageRef}
                            onClick={(event) => {
                                if (_this.props.openImage) {
                                    _this.props.onImageClose()
                                } else {
                                    _this.props.onImageOpen([event.target.src])
                                }
                            }}
                            style={{ cursor: "zoom-in" }}
                        />
                        :
                        <img
                            alt="ktp"
                            src={urlKTPImage(_this.props.data?.id, _this.props.data?.folder_rand)}
                            height="200px"
                            ref={_this.identityImageRef} />
                    }
                </div>

                <div>
                    <span>Image</span>
                    {_this.state.faceImgUrl !== null ?
                        <img
                            className={[classes.FadeInPage1s].join(" ")}
                            alt="webcam"
                            src={_this.state.faceImgUrl}
                            height="200px"
                            ref={_this.participantImageRef}
                            onError={() => {
                                setTimeout(() => {
                                    _this.participantImageRef.current.src = _this.state.faceImgUrl;
                                }, 1000);
                            }}
                            onClick={(event) => {
                                if (_this.props.openImage) {
                                    _this.props.onImageClose()
                                } else {
                                    _this.props.onImageOpen([event.target.src])
                                }
                            }}
                            style={{ cursor: "zoom-in" }}
                        />
                        : null}
                </div>

                <div className={classes.facevalidated}>
                    <span>{Lang(`Wajah Tervalidasi`, LANG(), { en: `Face Validated` })}</span>
                    <img
                        alt="faceincam"
                        src={urlFaceInWebCam(_this.props.data?.id, _this.props.data?.folder_rand, Math.round(Date.now() / 10000))}
                        height="100px"
                        ref={_this.validatedImageRef}
                        onError={() => {
                            setTimeout(() => {
                                _this.validatedImageRef.current.src = urlFaceInWebCam(_this.props.data?.id, _this.props.data?.folder_rand, Math.round(Date.now() / 10000))
                            }, 1000)
                        }}
                        onClick={(event) => {
                            if (_this.props.openImage) {
                                _this.props.onImageClose()
                            } else {
                                _this.props.onImageOpen([event.target.src])
                            }
                        }}
                        style={{ cursor: "zoom-in" }}
                    />
                    {_this.props.dynvalidation.value !== "" && <>
                        <div
                            className={classes.sim}
                            style={{ color: validationValueColor2(_this.props.dynvalidation) }}
                        >
                            {Lang(`kemiripan`, LANG(), { en: `similarity` })} {((1 - _this.props.dynvalidation.value) * 100).toFixed(2)}%
                        </div>
                        {_this.props.data.confirmed !== "1" && <button
                            className={classes.btnHelp}
                            onClick={() => {
                                _this.props.faceConfirmation()
                            }}
                        >
                            {Lang(`Sesuai ?`, LANG(), { en: `Matched ?` })}
                        </button>
                        }
                    </>}
                </div>
            </div>
            <div className={classes.modal_c_profile_right_photos}>
                <div>
                    <span>Screen Capture</span>
                    <img
                        alt="livescreen"
                        src={_this.state.screenImgUrl}
                        height="250px"
                        ref={_this.screenImageRef}
                        onError={() => {
                            setTimeout(() => {
                                _this.screenImageRef.current.src = _this.state.screenImgUrl;
                            }, 1000);
                        }}
                        onClick={(event) => {
                            if (_this.props.openImage) {
                                _this.props.onImageClose()
                            } else {
                                _this.props.onImageOpen([event.target.src])
                            }
                        }}
                        style={{ cursor: "zoom-in" }}
                    />
                </div>
                <div>
                    {
                        _this.state.videoEl === null ? null : <>
                            <span>Video</span>
                            <video
                                id="popupVideo"
                                autoPlay
                                height="200px"
                                muted={_this.state.isAudioMuted}>
                                <track kind='captions' />
                            </video>
                            {/* VIDEO {`check ${videoEl}`} */}
                        </>
                    }
                </div>
            </div>
            {_this.openImageModal && this}
        </div>
    }
}