import CommonWebsocket from "../../libs/CommonWebsocket";
import ProktorAI from "../../libs/ProktorAI";

export const __CommonWs__ = function (_this) {
    /** CommonWS */
    /*
    /** */
    _this.commonWs = null;
    _this.updateTsRoutineLastTs = 0;
    _this.stateEmotion = {};
    _this.commonWsLastReadyTs = 0;
    _this.commonWsReinitialized = false;

    this.commonWsProctorOnError = (_this, err) => {
        //handle on close and on error
        if (err !== null) {
            console.log("close connection with error=", err);
        }
        delete _this.commonWs;
        _this.commonWs = null;
    }

    this.commonWsProctorOnMessageReceived = (_this, msg) => {
        msg = JSON.parse(msg);
        // console.log(msg);
        if (msg.type === 'webrtc-failed') {
            let participantId = msg.participantId;
            let mediaStatus = msg.mediaStatus;
            // console.log(log.warning("webrtc failed to start, id=", participantId, ", do nothing"));
            _this.pm.webRTCAction(participantId, false, mediaStatus);
        } else if (msg.type === 'webrtc-started') {
            let participantId = msg.participantId;
            // console.log(log.warning("webrtc is started, do webrtc, id=", participantId));
            _this.pm.webRTCAction(participantId, true);
        } else if (msg.type === "broadcast-to-participant") {
            // broadcast-to-participant will be handled by commonws not chatws
            _this.setState({ broadcastToParticipantMessage: msg });
        } else if (msg.type === "aiwarning") {
            let from = msg.from;
            let warningtype = msg.warningtype;
            let sAI = _this.state.stateAI;
            let eAI = _this.stateEmotion;
            if (msg.warningdata !== null) {
                let wa = ProktorAI.warningAnalytic(msg.warningdata,
                    eAI[from] === undefined ? { calm: 0, anxiety: 0, pasrah: 0 } :
                        eAI[from].emotion);
                sAI[from] = {
                    ts: Date.now(),
                    wt: warningtype,
                }

                eAI[from] = wa;
                _this.setState({
                    stateAI: sAI,
                    // stateEmotion: eAI
                })
                _this.stateEmotion = eAI;
            } else {
                // console.log("warning data is null, perhaps it's sound handler");
                _this.pm.setSoundDetectedTs(from, Date.now());
                _this.setState(prevState => ({
                    soundDetectedTs: { ...prevState.soundDetectedTs, [from]: Date.now() }
                }))
            }
        } else if (msg.type === "update-ts-confirmed") {
            _this.updateTsRoutineLastTs = Date.now();
        } else if (msg.type === "update-validation-confirmed") {
            let status = msg.status
            if (status !== "success") {
                alert(`${_this.state.participantId} - ${_this.state.participantProfile.name} gagal, ulangi kembali,`);
            } else {
                alert(`${_this.state.participantId} - ${_this.state.participantProfile.name} telah di set ${msg.validation_status}`);
                _this._component.showPopupDetailParticipant(_this.state.participantId, _this.state.participantUUId, _this.state.temporaryVideoElId);
            }
        }
    }

    this.onValidProfile = () => {
        if (_this.commonWs === null) {
            _this.pm.setCommonWs(null);
            _this.commonWsReinitialized = true;
            _this.commonWs = new CommonWebsocket(_this, null, this.commonWsProctorOnMessageReceived, this.commonWsProctorOnError);
        }

        if (_this.state.commonWsRegistered === false || _this.commonWsReinitialized === true) {
            let listOfParticipants = [];
            for (let p in _this.pm.getParticipants()) {
                listOfParticipants.push(p)
            }

            let reg = _this.commonWs.registerProctorer(_this.context.profile.id, listOfParticipants);
            _this.commonWsReinitialized = !reg;

            if (reg === true) {
                // _this.commonWs.sendGetBroadcastUpdate(_this.context.profile.id, _this.broadcastMessageLastTs)

                _this.setState({
                    commonWsRegistered: reg
                });
                setTimeout(() => {
                    _this.pm.setCommonWs(_this.commonWs);
                    _this.setState({ participantManagerCommonWs: true });
                }, 1000);
            }
        }
    }

    this.commonWsRoutine = () => {
        if (_this.commonWs === null) {
            if (Date.now() - _this.commonWsLastReadyTs > 10000) {
                _this.setState({
                    commonWsRegistered: false
                });
            }
        } else if (_this.commonWs.ready() === true) {
            _this.commonWsLastReadyTs = Date.now();
        } else if (Date.now() - _this.commonWsLastReadyTs > 10000) {
            _this.setState({
                commonWsRegistered: false
            });
        }

        if (_this.state.participantManagerStatus === false ||
            _this.state.fetchParticipantsStatus === false) {
            return;
        }
        if (_this.context.profile !== undefined) {
            this.onValidProfile();
        }
    }
}