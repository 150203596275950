import "./App.css";

import { Switch, Route, Redirect } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { useContext } from "react";
import AuthContext from "./store/auth-context";
import LoginPageMobileRouter from "./pages/ProktorEventLoginPageMobile/LoginPageMobile";
import ExamPage from "./pages/ExamPage/__index__";
import AuthPage from "./pages/ProktorEventLoginPage/__index__";
import ProctorPage from "./pages/ProctorPage/__index__";
import EmbeddedFrame from "./pages/EmbeddedFrame/__index__";
import EmbeddedParent from "./pages/EmbeddedParent/__index__";

function App() {
  const authCtx = useContext(AuthContext);
  return (
    <Switch>
      <Route path="/embedded" exact>
        <EmbeddedFrame />
      </Route>
      <Route path="/embeddedparent" exact>
        <EmbeddedParent />
      </Route>

      {authCtx.isLoggedIn && (
        <Route path="/exam" exact>
          <BrowserView>
            <ExamPage
              mode="web"
            // mode="mobile"
            />
          </BrowserView>
          <MobileView>
            <ExamPage mode="mobile" />
          </MobileView>
        </Route>
      )}
      {authCtx.isLoggedIn && (
        <Route path="/proctor" exact>
          <BrowserView>
            <ProctorPage />
          </BrowserView>
          <MobileView>
            <ProctorPage />
          </MobileView>
        </Route>
      )}
      {/* <Route path="/" exact>
        <BrowserView>
          <LoginPageRouter />
        </BrowserView>
      </Route> */}
      {/* ============================================== */}
      <Route path="/participant" exact>
        {/* <BrowserView> */}
        <AuthPage />
        {/* </BrowserView> */}
        {/* <MobileView> */}
        {/* <LoginPageMobileRouter as={"participant"} /> */}
        {/* </MobileView> */}
      </Route>
      <Route path="/proctorer" exact>
        <BrowserView>
          {/* <LoginPageRouter as={"proctorer"} /> */}
          <AuthPage />
        </BrowserView>
        <MobileView>
          <LoginPageMobileRouter as={"proctorer"} />
        </MobileView>
      </Route>
      {/* ============================================== */}
      <Route path="/management" exact component={() => {
        window.location.href = "https://" + window.location.hostname + ":4443";
      }} >
      </Route>
      <Route path="*">
        <Redirect to="/participant" />
      </Route>
      {/* <Route path="*">
        <Redirect to="/" />
      </Route> */}
    </Switch>
  );
}

export default App;
