import React from "react";
import classes from "./Language.module.scss";
import id from "../assets/imgs/flagid.png";
import en from "../assets/imgs/flagen.png";
import ch from "../assets/imgs/flagch.png";
import sp from "../assets/imgs/flagsp.png";
import jp from "../assets/imgs/flagjp.png";
import ko from "../assets/imgs/flagko.png";

class Language extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false
        }
    }

    storeLocalStorage(v) {
        localStorage.setItem("lang", v);
    }

    onChangeLang(v) {
        this.storeLocalStorage(v)
        this.props.onChangeLang(v);
    }

    getLangImg(lang) {
        switch (lang) {
            case "id":
                return id;
            case "en":
                return en;
            case "sp":
                return sp;
            case "ch":
                return ch;
            case "jp":
                return jp;
            case "ko":
                return ko;
            default:
                return id;
        }
    }

    getLangString() {
        return this.props.currentLang.toUpperCase();
    }

    render() {
        return <div
            onMouseOver={() => {
                this.setState({
                    showModal: true
                })
            }}
            onMouseLeave={() => {
                this.setState({
                    showModal: false
                })
            }}
            className={classes.container}
        >
            <img alt="flag" src={this.getLangImg(this.props.currentLang)} />
            <span>{this.getLangString()}</span>
            {this.state.showModal && <div className={[
                classes.container_modal,
                this.props.left ? classes.left : classes.right,
                classes.FadeInPage1s,
            ].join(" ")}>
                <div
                    onClick={() => this.onChangeLang("id")}
                >
                    <img alt="flag" src={this.getLangImg("id")} />
                    ID - Indonesia
                </div>
                <div
                    onClick={() => this.onChangeLang("en")}
                >
                    <img alt="flag" src={this.getLangImg("en")} />
                    EN - English
                </div>
                {/* <div
                    onClick={() => this.onChangeLang("sp")}
                >
                    <img alt="flag" src={this.getLangImg("sp")} />
                    SP - Spain
                </div>
                <div
                    onClick={() => this.onChangeLang("ch")}
                >
                    <img alt="flag" src={this.getLangImg("ch")} />
                    CH - China
                </div>
                <div
                    onClick={() => this.onChangeLang("jp")}
                >
                    <img alt="flag" src={this.getLangImg("jp")} />
                    JP - Japan
                </div>
                <div
                    onClick={() => this.onChangeLang("ko")}
                >
                    <img alt="flag" src={this.getLangImg("ko")} />
                    KO - Korea
                </div> */}
            </div>
            }
        </div>
    }
}

export default Language;