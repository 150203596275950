import React from "react";
import classes from "./Troubleshooting.module.scss";
import troubleshooting from "../assets/imgs/troubleshooting.png";
import { TELKOM_CASE } from "../libs/proctor_utils";

class Troubleshooting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // troubleshooting
            troubleshooting: {
                show: false,
                chosen: 0
            }
        }
    }

    render() {
        const trs = [
            {
                title: "1. KENDALA SOAL UJIAN TIDAK TAMPIL DI MACOS",
                img_url: "https://amanin.id/troubleshootings_guidelines/tidaktampilmacos.png",
                width: "500px"
            },
            {
                title: "2. Panduan Peserta Ujian",
                img_url: "https://amanin.id/troubleshootings_guidelines/guideline_participants.png",
                width: "700px"

            },
            {
                title: "3. Hal - hal Yang Mungkin Terjadi Selama Ujian Online",
                img_url: "https://amanin.id/troubleshootings_guidelines/guideline_problems_in_exams.png",
                width: "700px"
            },
            // {
            //     title: "KENDALA LOGIN MENUJU HALAMAN SOAL",
            //     img_url: "https://amanin.id/troubleshootings_guidelines/troubleshooting_login_soal.png"
            // },
            {
                title: "5. Tidak dapat mengaktifkan kamera pada MacOS",
                img_url: "https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_camera_problems.png",
                width: "1500px"
            },
            {
                title: "6. Tidak dapat mengaktifkan Screen dan Audio pada MacOS",
                img_url: "https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_screen_audio_problems.png",
                width: "1500px"
            },
            {
                title: "7. Tidak dapat mengaktifkan kamera, microphone ataupun screen capture",
                img_url: "https://amanin.id/troubleshootings_guidelines/troubleshooting_permission.png",
                width: "1500px"
            },
            {
                title: "8. Tidak dapat mengaktifkan microphone pada MacOS",
                img_url: "https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_microphone_problems.png",
                width: "1500px"
            },
            {
                title: "9. Bermasalah dengan Ekstensi Chrome",
                img_url: "https://amanin.id/troubleshootings_guidelines/ekstensi%20troubleshooting%20guide.png",
                width: "1500px"
            },
        ]

        return <div className={[classes.troubleshooting, classes.FadeInPage1s].join(" ")}>
            <div
                className={classes.troubleshooting_title}
                onClick={() => {
                    this.setState(prevState => ({
                        troubleshooting: {
                            ...prevState.troubleshooting,
                            ["show"]: !prevState.troubleshooting.show
                        }
                    }))
                }}>
                <img alt="troubleshooting" src={troubleshooting} />
                <span>troubleshooting</span>
            </div>
            {!this.state.troubleshooting.show ? <></> :
                <div className={[
                    classes.troubleshooting_content,
                    classes.FadeInPage1s
                ].join(" ")}
                    style={{ left: TELKOM_CASE ? "0px" : "-50px" }}
                >
                    {
                        trs.map((v, index) => {
                            return <div
                                key={v.title}
                                onClick={() => {
                                    this.setState(prevState => ({
                                        troubleshooting: {
                                            ...prevState.troubleshooting,
                                            ["chosen"]: index
                                        }
                                    }))
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight: this.state.troubleshooting.chosen === index ? "bold" : "normal"
                                    }}>{v.title}</span>
                            </div>
                        })
                    }
                    {this.state.troubleshooting.chosen !== -1 ?
                        <div className={[classes.img, classes.ascroll].join(" ")}>
                            <img
                                alt="troubleshooting_img"
                                src={trs[this.state.troubleshooting.chosen].img_url}
                                width={trs[this.state.troubleshooting.chosen].width}
                            />
                        </div>
                        : <></>
                    }
                </div>
            }
        </div>
    }
}

export default Troubleshooting;