import React from "react";
import classes from "./CheckBox.module.scss";
import PropTypes from "prop-types";

class CheckBox extends React.Component {
  render() {
    return (
      <div className={classes.container}>
        <label className={classes.checkbox}>
          <input
            type="checkbox"
            id={this.props.id}
            name={this.props.name}
            onChange={this.props.onChange}
            checked={this.props.checked}
          />
          <div className={classes.checkmark} />
        </label>
      </div>
    );
  }
}

CheckBox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired
}
export default CheckBox;
