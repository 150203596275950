import React from 'react'
import Tips from '../../../components/Tips'
import info from "../../../assets/imgs/info.png";
import position from "../../../assets/imgs/good_position.jpg";
import classes from "./__index__.module.scss";
import { Lang } from '../../../libs/Language';

export const __IdentityValidation__ = function (_this, this_) {

    this.identityCard = () => {

        return <div className={classes.container}>
            {this_.renderDisplayEl()}
            <Tips />
            <h2>{Lang(`Selamat Datang`, _this.state.lang, { en: `Hi` })}, {_this.context.profile.name}</h2>
            <h1>{Lang(`Validasi Identitas`, _this.state.lang, { en: `Identity Validation` })}</h1>
            <div className={classes.container_profile}>
                <div className={classes.container_profile_info}>
                    <img alt="info" src={info} />
                    <ol type="*">
                        <li>
                            {Lang(`Tegakkan badan anda, pastikan cahaya di ruangan anda cukup terang`, _this.state.lang, { en: `Stand straight, make sure the lighting in your room is sufficiently bright.` })}
                        </li>
                        <li>
                            {Lang(`Posisikan wajah dan kartu identitas anda di kotak yang tersedia di layar.`, _this.state.lang, { en: `Position your face and ID Card within the box provided on the screen.` })}
                        </li>
                        <li>
                            {Lang(`Mas Robot akan mencoba untuk mengambil foto anda beserta kartu identitas, klik tombol "Capture"`, _this.state.lang, { en: `Mr. Robot will now attempt to capture a picture with your identity card, click "Capture" button.` })}
                        </li>
                        <li>
                            {Lang(`Selanjutnya, klik tombol "Next" untuk melakukan validasi video`, _this.state.lang, { en: `Next step, Click "Next" button to video validation.` })}
                        </li>
                    </ol>

                    <div className={classes.example}>
                        <img alt="pos" src={position} />
                        <span>{Lang(`Contoh posisi wajah dan kartu identitas yang diharapkan Mas Robot`, _this.state.lang, { en: `Example of the expected face and ID Card position as desired by Mr. Robot.` })}</span>
                    </div>

                </div>
            </div>
            <div className={classes.video_message}>
                <div className={classes.container_bordervideo}>
                    <div className={[classes.container_video, classes.FadeInPage1s].join(" ")}
                        style={{
                            // visibility: getVisibility()
                            visibility: "inherit"
                        }}
                    >
                        <video
                            ref={_this.onValidationWebCamRef}
                            width="640px"
                            height="320px"
                            autoPlay
                            muted
                            playsInline
                            style={{
                                transform: "scaleX(1)"
                            }}
                        ></video>
                    </div>
                </div>
            </div>

            <div
                style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
                <button
                    className={classes.btnOk}
                    onClick={this_.onCaptureIdentity}
                >Capture</button>
                {
                    this_.identity_validation.identity_img_validation &&

                    <button
                        className={classes.btnLink}
                        onClick={this_.onNextValidation}
                    >Next</button>
                }

            </div>

            <div style={{ marginTop: "10px" }}>
                {this_.identity_validation.identity_img_validation && <img src={this_.identity_validation.identity_img_validation} alt="Captured" />}
            </div>
        </div>
    }
}


