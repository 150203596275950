import React from "react";
import classes from "./Tips.module.scss";
import tip from "../assets/imgs/tip.png";
import { Lang } from "../libs/Language";
import { LANG } from "../libs/utils";

class Tips extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showTips: localStorage.getItem("showTips") ? localStorage.getItem("showTips") : "true"
        }
        if (this.props.alwaysShow === true) {
            this.alwaysShow = true;
            this.state = {
                showTips: true
            }
        } else {
            this.alwaysShow = false
        }
    }

    render() {
        return <div className={classes.container}>
            <img alt="tip" src={tip}
                className={this.state.showTips === "false" ? classes.hide : null}
                onClick={() => {
                    this.setState({ showTips: "true" })
                    localStorage.setItem("showTips", "true")
                }}
            />
            {(this.state.showTips === "true" || this.alwaysShow) &&
                <div>
                    <span>
                        {
                            Lang(`Untuk kenyamanan, gunakan fitur Zoom In/Zoom Out yang disediakan oleh browser.`,
                                LANG(),
                                {
                                    en: `For convenience, use the Zoom In/Zoom Out feature provided by the browser.`
                                })
                        }
                    </span>
                    <span className={classes.keep}>
                        Windows: Ctrl + [plus], Ctrl + [minus]
                    </span>
                    <span className={classes.keep}>
                        Mac: Command + [plus], Command + [minus]
                    </span>
                    {this.alwaysShow === false && <button
                        className={classes.btnHelp}
                        onClick={() => {
                            this.setState({ showTips: "false" })
                            localStorage.setItem("showTips", "false")
                        }}
                    >
                        close
                    </button>
                    }
                </div>
            }
        </div>
    }
}

export default Tips;