import React from "react";
import classes from "./ProgressBar.module.scss";
import PropTypes from "prop-types";

class ProgressBar extends React.Component {
  render() {
    let type = 1; // ordinary progress
    if (this.props.completed === undefined || this.props.completed === -1) {
      type = 2; // animated progress
    }
    return (
      <div
        className={[classes.container, type === 2 ? classes.center : classes.left].join(
          " "
        )}
      >
        {type === 2 ? (
          <div className={classes.container_funny}>
            <div
              className={[
                classes.container_funny_width,
                classes.animateme,
              ].join(" ")}
            ></div>
            <div className={[classes.container_funny_dot].join(" ")}></div>
            {this.props.innerText !== undefined ? (
              <span>{this.props.innerText}</span>
            ) : null}
          </div>
        ) : (
          <div
            className={classes.container_first}
            style={{ width: this.props.completed + "%" }}
          >
            <span>{this.props.completed}%</span>
          </div>
        )}
      </div>
    );
  }
}

ProgressBar.propTypes = {
    completed: PropTypes.number,
    innerText: PropTypes.string,
}

export default ProgressBar;
