import { convertTimestampToTime } from "../../libs/utils";

export const currentTsRoutine = (_this, ts) => {
    let currentTime = Math.floor(ts / 1000);
    let startTimeTS = _this.state?.examProfile?.start_time_ts;
    let endTimeTS = _this.state?.examProfile?.end_time_ts;

    let faceValidationStatus = _this.state?.participantProfile?.validation_status === 1;
    let cameraOn = _this.state?.userMediaStream;
    let screenOn = _this.state?.displayMediaStream;

    // Untuk memulai rekaman ujian peserta
    if (startTimeTS !== null && currentTime >= startTimeTS) {
        _this.record = true;
    }

    //Status untuk validation button
    // go to exam here
    // Otomatis ketika syarat terpenuhi && Camera screen monitor sudah di share
    if (
        (_this.props.mode !== "mobile" && faceValidationStatus && cameraOn !== null &&
            screenOn !== null &&
            _this.state.examProfile.direct_exam === 1
            && _this.state.numberOfScreen === 1
        )
        ||
        (
            _this.props.mode !== "mobile" && faceValidationStatus && currentTime >= startTimeTS &&
            cameraOn !== null && screenOn !== null
            && _this.state.numberOfScreen === 1
        )
        ||
        (
            _this.props.mode === "mobile" && faceValidationStatus && cameraOn !== null && _this.state.examProfile.direct_exam === 1)
        ||
        (
            _this.props.mode === "mobile" && faceValidationStatus && currentTime >= startTimeTS && cameraOn !== null
        )
    ) {
        clearInterval(_this.sendImageInterval);

        _this.setState({ initExam: false })

        if (_this.state.examProfile.useExtension === true) {
            _this.CE.setQuizUrl(_this.quizActivity.host);
        }


        _this.firstTimeValidation = false
    } else {
        // console.log("belum terpenuhi ", currentTime, startTimeTS,_this.props.mode , faceValidationStatus);
    }

    // untuk auto logout
    if (currentTime >= endTimeTS) {
        alert("Sesi Ujian telah berakhir, anda akan logout secara otomatis.");
        _this._action.onLogout();
    }

    if (_this.state.initExam === false && _this.state.numberOfScreen > 1) {
        alert("Jumlah layar terhubung ke device anda ada lebih dari satu. Anda hanya diperbolehkan untuk menggunakan layar utama saja. Putuskan layar tambahan anda.")
        _this.setState({ initExam: true });
        _this._preparation.recoverVideoForPreparation(_this);
    }

    //Menuju Waktu Ujian
    const examCountDown = startTimeTS - currentTime;
    const daysECD = Math.floor(examCountDown / (24 * 60 * 60))
    const hoursECD = Math.floor((examCountDown % (24 * 60 * 60)) / (60 * 60));
    const minutesECD = Math.floor((examCountDown % (60 * 60)) / 60);
    const secondsECD = Math.floor(examCountDown % 60);

    const ecd = {
        days: daysECD,
        hours: hoursECD > 0 ? hoursECD : 0,
        minutes: minutesECD >= 0 ? minutesECD : 0,
        seconds: secondsECD >= 0 ? secondsECD : 0,
    }

    //Ujian Sedang Berlangsung
    // const examTimes = (_this.state?.examProfile?.end_time_ts * 1000) - ts;
    const examTimes = endTimeTS - currentTime;
    const hoursET = Math.floor((examTimes % (24 * 60 * 60)) / (60 * 60));
    const minutesET = Math.floor((examTimes % (60 * 60)) / 60);
    const secondsET = Math.floor(examTimes % 60);

    const et = {
        hours: hoursET >= 0 ? hoursET : 0,
        minutes: minutesET >= 0 ? minutesET : 0,
        seconds: secondsET >= 0 ? secondsET : 0,
    }
    _this.setState({ examTimes: et, examCountDown: ecd });

    if (startTimeTS > currentTime) {
        _this.setState({
            displayTimer: "menuju waktu ujian",
            examStage: {
                // title: "Menuju Waktu Ujian",
                // time: `${_this.state?.examCountDown?.days} Hari ${formatTime(_this.state?.examCountDown?.hours)}:${formatTime(_this.state?.examCountDown?.minutes)}:${formatTime(_this.state?.examCountDown?.seconds)}`
                time: `${convertTimestampToTime(ts)}`
            }
        })
    } else if (endTimeTS > currentTime) {
        _this.setState({
            displayTimer: "ujian",
            examStage: {
                time: `${convertTimestampToTime(ts)}`
            }
        })
    } else {
        _this.setState({
            displayTimer: "selesai",
            examStage: {
                time: `${convertTimestampToTime(ts)}`
            }
        })
    }

    // ALert Kurang dari 5 menit
    // _this.timesAlert(currentTime, endTimeTS)

    // Untuk Display Warning Ke Peserta
    // clearInterval(_this.warningInterval);
    // _this.warningInterval = setInterval(() => {
    //   console.log("DISPLAY FROM WARNING INTERVAL")
    //   const { display_warning_on_exam_thresh, interprocess_ai_delay } = _this.state.examProfile;
    //   // let displayWarning = "Warning";
    //   // let limit = (display_warning_on_exam_thresh / (interprocess_ai_delay * 1000)) / 2;
    //   let limit = ((1 * 30 * 1000) / (interprocess_ai_delay * 1000)) / 2;

    //   if (_this.totalWarning >= limit) {
    //     _this.totalWarning = 0
    //     setTimeout(() => {
    //       console.log("DISPLAY WARNING");
    //     }, 30000);
    //     console.log("DISPLAY NORMAL");
    //   }

    //   // }, 1000);
    //   // }, _this.state.examProfile.display_warning_on_exam_thresh);
    // }, 1 * 30 * 1000);
}