import { getAccessToken, urlLocal } from "./utils";

let console = {};
console.log = function () { };

class ChatWs {
    constructor(onMessageReceived = null, onClose = null) {
        this.socket = new WebSocket("wss://" + urlLocal + "/chatws");

        this.socket.addEventListener("open", (event) => {

        })

        this.socket.addEventListener("message", (event) => {
        })

        this.socket.onclose = () => {
            if (onClose !== null) {
                onClose(null);
            }
        }

        this.socket.onerror = (err) => {
            if (onClose !== null) {
                onClose(err);
            }
        }

        this.socket.onopen = () => {
            console.log("CHAT WS ON OPEN");
        }

        this.socket.onmessage = (data) => {
            if (typeof (data.data) === "string") {
                if (onMessageReceived !== null) {
                    onMessageReceived(data.data);
                }
            } else {
                data.data.text().then((d) => {
                    if (onMessageReceived !== null) {
                        onMessageReceived(d);
                    }
                })
            }
        }
    }

    ready() {
        return this.socket.readyState === 1;
    }
    close() {
        this.socket.close();
    }

    registerClient(type, id, listOfParticipants = null) {
        // console.log("[register] type=" + type + ", id=" + id)
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: type,
                    id: id,
                    listOfParticipants: listOfParticipants,
                    token: getAccessToken()
                })
            )
        } else {
            // console.log("[register] socket is not ready");
            return false;
        }
        return true;
    }

    registerParticipant(id) {
        return this.registerClient("register-participant", id);
    }

    registerProctorer(id, listOfParticipants) {
        return this.registerClient("register-proctorer", id, listOfParticipants);
    }

    errorSocketIsNotReady() {
        // console.log("[commonws] socket is not ready");
    }

    sendGetBroadcastUpdate(id, lastBroadcastTs) {
        if (this.socket.readyState === 1) {
            this.socket.send(JSON.stringify({
                type: 'get-broadcast-update',
                last_ts: lastBroadcastTs,
                id: id,
                token: getAccessToken()
            }))
        }
    }

    sendChat(from, to, msg, participantId = null, folder_rand = null) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "chat",
                        chat: {
                            from: from,
                            to: to,
                            msg: msg,
                            "folder-rand": folder_rand,
                            participantId: participantId
                        },
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendChatMessageReceived(from, to, ts) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "chat-message-received",
                        to: to,
                        ts: ts,
                        from: from,
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendChatConfirmed(id, ts) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "chat-confirmed",
                        id: id,
                        ts: ts,
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendAllPrivateChat(from, to, msg) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "send-all-private-chat",
                        from: from,
                        to: to,
                        msg: msg,
                        token: getAccessToken()
                    }
                )
            )
        }
    }

    sendNewPrivateChat(participantId, proctorerId) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "new-private-chat",
                        participantId: participantId,
                        proctorerId: proctorerId,
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    // sendClosePrivateChat(participantId, proctorerId) {
    //     if (this.socket.readyState === 1) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: "close-private-chat",
    //                     participantId: participantId,
    //                     proctorerId: proctorerId
    //                 }));
    //     } else {
    //         this.errorSocketIsNotReady();
    //         return false;
    //     }
    //     return true;
    // }

    getPrivateChat(participantId, proctorerId, last_ts) {
        if (this.ready()) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "get-private-chat",
                        participantId: participantId,
                        proctorerId: proctorerId,
                        last_ts: last_ts,
                        token: getAccessToken()
                    }
                )
            )
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendPing(from) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "ping",
                        from: from,
                        token: getAccessToken()
                    }
                )
            )
        }
    }
}

export default ChatWs;