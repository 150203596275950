import React, { createRef } from 'react';
import { urlLiveScreen, urlWebCam } from '../../libs/proctor_utils';
import classes from './__index__.module.scss'
import ProfileShow from '../ProfileShow';
import Loading from '../Loading';
import notes from "../../assets/imgs/notes.png";
import video from "../../assets/imgs/video.png";
import screenshot from "../../assets/imgs/screenshot.png";
import imgfolder from "../../assets/imgs/img-folder.png";
import CheckBox from '../CheckBox';
import { LANG } from '../../libs/utils';
import PropTypes from 'prop-types';
import { Lang } from '../../libs/Language';
import { __Screenshot__ } from './Screenshot';
import { __Notes__ } from './Notes';
import { __ProfileImages__ } from './ProfileImages';

class ModalParticipant extends React.Component {
    constructor(props) {
        super(props);

        this.participantImageRef = createRef();
        this.identityImageRef = createRef();
        this.validatedImageRef = createRef();
        this.screenImageRef = createRef();

        this.state = {
            videoEl: null,
            isAudioMuted: false,
            faceImgUrl: null,
            screenImgUrl: '',
            mode: "visual", //visual, note
            flagAttention: false,
            screenshotNotes: [], // notes dari screenshot
            notes: "",
            selectedImage: ""
        }

        this.getImageInterval = null;

        this.dataReceived = false;
        this.openImageModal = false;

        this._profileimage = new __ProfileImages__(this, classes);
        this._screenshot = new __Screenshot__(this, classes);
        this._notes = new __Notes__(this, classes);
    }

    componentDidUpdate() {
        if (this.props.data.flagAttention === undefined) {
            return;
        }
        if (this.dataReceived === false) {
            this.dataReceived = true;
            this.setState({
                notes: this.props.data?.notes,
                flagAttention: this.props.data?.flagAttention === 1
            })
        }

    }

    updateImage() {
        this.setState({
            faceImgUrl: urlWebCam(this.props.data?.id, this.props.data?.folder_rand, Date.now()),
            screenImgUrl: urlLiveScreen(this.props.data?.id, this.props.data?.folder_rand, Date.now())
        })
    }

    loadPopupVideo(vEl) {
        setTimeout(() => {
            if (document.getElementById("popupVideo") !== null && document.getElementById("popupVideo") !== undefined) {
                document.getElementById("popupVideo").srcObject = vEl.srcObject;
            } else {
                this.loadPopupVideo(vEl);
            }
        }, 1000)
    }

    componentDidMount() {
        let vEl = document.getElementById(this.props.videoElId);
        this.setState({
            videoEl: vEl
        })
        if (vEl !== null) {
            this.loadPopupVideo(vEl);
        }

        clearInterval(this.getImageInterval);

        setTimeout(() => {
            this.updateImage();
        }, 1000);

        this.getImageInterval = setInterval(() => {
            this.updateImage();
        }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.getImageInterval);
        clearInterval(this.genDataInterval);
    }

    handleAudio = (event) => {
        this.setState({
            isAudioMuted: event
        })
    }

    renderNavigation() {
        return <div style={{ display: "flex", gap: "3px" }}>
            {/* <div className={classes.direction} */}
            <button className={`${classes.direction} ${this.state.mode === "visual" ? classes.active : ""}`}
                onClick={() => {
                    this.setState({ mode: "visual" })
                    this.loadPopupVideo(this.state.videoEl);
                }}
            >
                <img alt="video" src={video} height="25px" />
                <span>{Lang(`Ke Mode Visual`, LANG(), { en: `To Visual Mode` })}</span>
            </button>
            {/* <div className={classes.direction} */}
            <button className={`${classes.direction} ${this.state.mode === "note" ? classes.active : ""}`}
                onClick={() => {
                    this.setState({ mode: "note" })
                }}
            >
                <img alt="video" src={notes} height="25px" />
                <span>{Lang(`Ke Mode Catatan`, LANG(), { en: `To Note Mode` })}</span>
            </button>
            {/* <div className={classes.direction} */}
            <button className={`${classes.direction} ${this.state.mode === "screenshot" ? classes.active : ""}`}
                onClick={() => {
                    this.setState({ mode: "screenshot" })
                }}
            >
                <img alt="screenshot" src={screenshot} height="25px" />
                <span>{Lang(`Ke Mode Screenshot`, LANG(), { en: `To Screenshot Mode` })}</span>
            </button>
            <button className={`${classes.direction} ${this.state.mode === "view-screenshot" ? classes.active : ""}`}
                onClick={() => {
                    this._screenshot.getParticipantScreenshotNotes(this.props.data.id, this.props.data.folder_rand)
                    this.setState({ mode: "view-screenshot" })
                }}
            >
                <img alt="showscreenshot" src={imgfolder} height="25px" />
                <span>{Lang(`Lihat Screenshot`, LANG(), { en: `View Screenshots` })}</span>
            </button>
        </div>
    }

    renderBottomButton() {
        return <>
            <div className={classes.modal_w}>
                <div>
                    <CheckBox
                        checked={this.state.flagAttention}
                        onChange={(e) => {
                            let fa = !(this.state.flagAttention);
                            this._notes.saveNotes(fa, () => {
                                this.setState({
                                    flagAttention:
                                        fa,
                                });
                            })
                        }}
                    />
                    <div style={{
                        marginLeft: "5px",
                        fontWeight: "bold"
                    }}>
                        {Lang(`Berikan perhatian khusus bagi peserta ini`, LANG(), { en: `Give special attention to this participant.` })}
                    </div>
                </div>
                <div className={classes.i}>
                    {Lang(`Tanda perhatian khusus dapat anda berikan apabila
                                peserta dinilai melakukan hal - hal yang dianggap curang.
                                Tanda ini akan membantu penyelenggara ujian untuk menganalisa
                                lebih lanjut dan menghasilkan keputusan yang adil.`, LANG(), {
                        en: `You can provide special attention if the participant is 
                                    suspected of engaging in cheating behaviors. 
                                    This marker will assist exam proctors in further analysis and making fair decisions.`
                    })}
                </div>
            </div>
            <div className={classes.modal_c}>
                <div className={classes.modal_c_button}>
                    <button
                        className={classes.btnCancel}
                        onClick={() => {
                            this.props.handleCloseHelpButtonOnList();
                            this.props.onClose();
                        }}>
                        {Lang(`Tutup dan Hapus dari broadcast chat`, LANG(), { en: `Close and Remove from Broadcast Chat.` })}
                    </button>
                    <button
                        className={classes.btnOk}
                        onClick={this.props.onClose}>
                        {Lang(`Tutup`, LANG(), { en: `Close` })}
                    </button>
                </div>
            </div>
        </>
    }

    renderContent() {
        return (
            <div
                onClick={this.props.onClose}
                className={[classes.overlay, classes.button2div].join(" ")}>
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={[classes.modal_container, classes.ascroll, classes.button2div].join(" ")}
                >
                    <div className={classes.modal}>
                        {/* <p className={classes.close_btn} onClick={onClose}>
                            [tutup]
                        </p> */}
                        <div className={classes.modal_c}>
                            <div className={classes.modal_c_profile}>
                                <div className={classes.modal_c_profile_left}>
                                    <div
                                        className={classes.modal_c_profile_left_show}
                                        style={{ marginBottom: "5px" }}
                                    >
                                        <div className={classes.profile}>
                                            <ProfileShow
                                                profile={this.props.data}
                                                showButton={true}
                                                onValidInvalid={(v) => {
                                                    if (v === "Invalid") {
                                                        this.props.handleInvalid();
                                                    }
                                                    else if (v === "Valid") {
                                                        this.props.handleValid();
                                                    }
                                                }}
                                                handleAudio={this.handleAudio}
                                                onBlocked={(v, participant) => {

                                                    if (v === "Block") {
                                                        this.props.handleBlock(participant);
                                                    }
                                                    else if (v === "Open") {
                                                        this.props.handleOpenBlock(participant);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {this.props.children}
                                    </div>
                                </div>
                                <div className={classes.modal_c_profile_right}>
                                    {this.renderNavigation()}
                                    {
                                        (this.state.mode === "visual" && this._profileimage.showImages())
                                        || (this.state.mode === "note" && this._notes.showNotes())
                                        || (this.state.mode === "screenshot" && this._screenshot.showScreenshot())
                                        || (this.state.mode === "view-screenshot" && this._screenshot.viewScreenshot())
                                    }

                                </div>
                            </div>
                        </div>
                        {this.renderBottomButton()}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        // console.log("Participant ID : ", this.props.data?.id)
        if (!this.props.open) return null;
        if (this.props.isLoading) {
            return <div
                onClick={this.props.onClose}
                className={[classes.overlay, classes.button2div].join(" ")}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={[classes.modal_container, classes.button2div].join(" ")}
                >
                    <div className={classes.modal}>
                        <button className={[classes.close_btn, classes.button2div].join(" ")} onClick={this.props.onClose}>
                            [tutup]
                        </button>
                        <div className={classes.modal_c} style={
                            {
                                marginTop: "10px",
                                marginBottom: "10px"
                            }
                        }>
                            <Loading />
                        </div>
                    </div>
                </div>
            </div>
        }
        return this.renderContent();
    }
}

ModalParticipant.propTypes = {
    data: PropTypes.object,
    videoElId: PropTypes.string,
    imageWs: PropTypes.object,
    currentTs: PropTypes.number.isRequired,
    photo: PropTypes.string,
    dynvalidation: PropTypes.object,
    faceConfirmation: PropTypes.func,
    proctorerId: PropTypes.string.isRequired,
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    handleValid: PropTypes.func,
    handleInvalid: PropTypes.func,
    handleBlock: PropTypes.func,
    handleOpenBlock: PropTypes.func,
    children: PropTypes.object,
    handleCloseHelpButtonOnList: PropTypes.func,
    openImage: PropTypes.bool,
    onImageClose: PropTypes.func,
    onImageOpen: PropTypes.func
};

export default ModalParticipant;
