import React from 'react'
import Tips from '../../../components/Tips'
import info from "../../../assets/imgs/info.png";
import position from "../../../assets/imgs/good_position.jpg";
import classes from "./__index__.module.scss";
import { Lang } from '../../../libs/Language';

export const __VideoRotateValidation__ = function (_this, this_) {

    this.videoRotate = () => {
        return <div className={classes.container}>
            {this_.renderDisplayEl()}
            <Tips />
            <h2>{Lang(`Selamat Datang`, _this.state.lang, { en: `Hi` })}, {_this.context.profile.name}</h2>
            <h1>{Lang(`Validasi Video`, _this.state.lang, { en: `Video Validation` })}</h1>
            <div className={classes.container_profile}>
                <div className={classes.container_profile_info}>
                    <img alt="info" src={info} />
                    <ol type="*">
                        <li>
                            {Lang(`Pastikan cahaya di ruangan anda cukup terang`, _this.state.lang, { en: `Make sure the lighting in your room is sufficiently bright.` })}
                        </li>
                        <li>
                            {Lang(`Klik tombol 'Start' untuk memulai perekaman`, _this.state.lang, { en: `Click "Start" button, to start recording.` })}
                        </li>
                        <li>
                            {Lang(`Putar kamera 360 derajat, untuk melihat sekitar ruangan.`, _this.state.lang, { en: `Rotate the camera 360 degree, to look around the room.  ` })}
                        </li>
                        <li>
                            {Lang(`Klik tombol 'Stop' untuk mengakhiri perekaman`, _this.state.lang, { en: `Click "Stop" button to stop recording.` })}
                        </li>
                        <li>
                            {Lang(`Klik tombol 'Done' untuk menyelesaikan proses validasi`, _this.state.lang, { en: `Click "Done" button to finish validation` })}
                        </li>
                    </ol>

                    {/* <div className={classes.example}>
                        <img alt="pos" src={position} />
                        <span>{Lang(`Contoh pergerakan camera yang diharapkan Mas Robot`, _this.state.lang, { en: `` })}</span>
                    </div> */}

                </div>
            </div>
            <div className={classes.video_message}>
                <div className={classes.container_bordervideo}>
                    <div className={[classes.container_video, classes.FadeInPage1s].join(" ")}
                        style={{
                            // visibility: getVisibility()
                            visibility: "inherit"
                        }}
                    >
                        <video
                            ref={_this.onValidationWebCamRef}
                            width="640px"
                            height="320px"
                            autoPlay
                            muted
                            playsInline
                            style={{
                                transform: "scaleX(-1)"
                            }}
                        ></video>

                    </div>
                </div>
            </div>

            <div
                style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
                {
                    this_.startVideoRecording === false ?
                        <button
                            className={classes.btnOk}
                            onClick={_this._validation.onHandleStartTs}
                        >Start</button>
                        :
                        <button
                            className={classes.btnHelp}
                            onClick={_this._validation.onHandleStopTs}

                        >
                            Stop
                        </button>
                }

                {
                    (this_.startVideoRecording === false
                        && this_.identity_validation.video_start_validation !== 0
                        && this_.identity_validation.video_end_validation !== 0)
                    &&
                    <>
                        <button
                            className={classes.btnReload}
                            onClick={this_.onDoneValidation}

                        >
                            Done
                        </button>

                        {/* <button
                            className={classes.btnLink}
                            onClick={() => {
                                console.log("RESULT Identity: ", this_.identity_validation)
                                console.log("RESULT Threshold: ", this_.threshold)
                            }}
                        >
                            Print Result
                        </button> */}
                    </>
                }

            </div>

        </div>
    }
}


