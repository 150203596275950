import FileWebsocket from "../../libs/FileWebsocket";

export const __FileWs__ = function (_this) {
    _this.faceWs = null;
    _this.screenWs = null;
    _this.imageWs = null;
    _this.faceWsMediaRecorder = null;
    _this.screenWsMediaRecorder = null;

    this.fileWsOnMessageReceived = (_this, msg) => {
        msg = JSON.parse(msg)
        console.log(`[filews]`, msg)
    }

    // 5 minutes
    const IMAGEFILEWS_RECONNECT_TIMEOUT = 60500 * 5;
    this.fileWsRoutine = () => {
        if (_this.state.imageWsRegisteredLastTs) {
            if (_this.state.imageWsRegisteredLastTs !== -1 && Date.now() - _this.state.imageWsRegisteredLastTs > IMAGEFILEWS_RECONNECT_TIMEOUT) {
                _this.imageWs.sendRefreshConnection(_this.context.profile.id);
                _this.setState({ imageWsRegisteredLastTs: Date.now() - 10000 })
            }
        }

        // Jika belum masuk waktu ujian, return 
        if (_this.props.mode === "mobile") {
            return;
        }

        // imagefilews: file handler on image for face and screen
        if (_this.imageWs === null) {
            _this.imageWs = new FileWebsocket(false, _this.context.profile.id, "image", _this.context.profile.folder_rand, this.fileWsOnMessageReceived, (err) => {
                delete _this.imageWs;
                _this.imageWs = null;
                _this.setState({
                    imageWsRegistered: false,
                    imageWsRegisteredLastTs: -1
                });
            });
        } else {
            _this.setState({
                imageWsRegistered: true,
            });
            if (_this.state.imageWsRegistered === false) {
                _this.setState({
                    imageWsRegisteredLastTs: Date.now()
                })
            }
        }

        // videofilews: file handler on video/webm for face
        if (_this.faceWs === null) {
            _this.faceWs = new FileWebsocket(true, _this.context.profile.id, "face", _this.context.profile.folder_rand, null, (err) => {
                delete _this.faceWs;
                _this.faceWs = null;
                _this.setState({ faceWsRegistered: false });
            });
            if (_this.faceWsMediaRecorder !== null) {
                _this.faceWsMediaRecorder.stop();
            }
            _this.faceWsMediaRecorder = _this._action.recordToWebsocket(_this.faceWs, _this.state.userMediaStream, true);
        } else {
            _this.setState({ faceWsRegistered: true })
        }

        // videofilews: file handler on video/webm for screen
        if (_this.screenWs === null && _this.props.mode !== "mobile") {
            _this.screenWs = new FileWebsocket(true, _this.context.profile.id, "screen", _this.context.profile.folder_rand, null, (err) => {
                delete _this.screenWs;
                _this.screenWs = null;
                _this.setState({ screenWsRegistered: false });
            });
            if (_this.screenWsMediaRecorder !== null) {
                _this.screenWsMediaRecorder.stop();
            }
            _this.screenWsMediaRecorder = _this._action.recordToWebsocket(_this.screenWs, _this.state.displayMediaStream, false);
        } else {
            _this.setState({ screenWsRegistered: true });
        }
    }
}