import React from "react";
import ProfileShow from "../../components/ProfileShow"
import { isInsideSafeExamBrowser } from "../../libs/proctor_utils";
import { initializeMedias } from "../../libs/utils";
import { Lang } from "../../libs/Language";
import Tips from "../../components/Tips";

export const __Preparation__ = function (_this, classes) {
    _this.initWebCamRef = React.createRef();
    _this.initScreenCaptureRef = React.createRef();
    _this.browser = null;

    this.recoverVideoForPreparation = () => {
        _this.firstTimeValidation = false
        setTimeout(() => {
            if (_this.initWebCamRef.current !== null) {
                _this.initWebCamRef.current.srcObject = _this.state.userMediaStream;
            }
            if (_this.initScreenCaptureRef.current !== null && _this.mode !== "mobile") {
                _this.initScreenCaptureRef.current.srcObject = _this.state.displayMediaStream;
            }
        }, 1000)
    }
    this.handleActiveShareVideo = async () => {
        if (_this.state.numberOfScreen > 1) {
            alert("Jumlah layar terhubung ke device anda ada lebih dari satu. Anda hanya diperbolehkan untuk menggunakan layar utama saja. Putuskan layar tambahan anda.")
            return;
        }
        if (_this.state.numberOfScreen !== -1) {
            initializeMedias(
                _this.state.examProfile.video_resolution,
                true,
                _this.mode !== "mobile",
                true,
                (stream) => {
                    _this.initWebCamRef.current.srcObject = stream;
                    _this.setState({ userMediaStream: stream });


                    if (_this.state.examProfile.audio_sensitivity !== 'disabled') {
                        // handle audio analysis
                        _this._audio.analyzeStream(stream)
                    }
                },
                (stream) => {
                    this.streamByBrowser(stream);
                },
                (stream) => {
                    _this.setState({ webRtcMediaStream: stream });
                }
            );
        } else if (this.browser !== "Safari" && !isInsideSafeExamBrowser()) {
            window.getScreenDetails().then((v) => {
            }).catch((e) => {
                alert("Anda belum mengaktifkan Window Management Permission. Aktifkan Window Management Permission.");
            })
        }
    }

    this.streamByBrowser = (stream) => {
        let display = "";
        if (_this.browser === "Chrome") {
            display = stream.getVideoTracks()[0].getSettings().displaySurface;
        } else if (_this.browser === "Firefox") {
            display = stream.getVideoTracks()[0].label;
        } else {
            // Safari or other
            display = stream.getVideoTracks()[0].label;
        }

        const validDisplay = (display === 'monitor' || display === 'Primary Monitor' || display === 'Screen ')
        let entireScreen = validDisplay ? stream : null


        // if (display !== 'monitor' && display !== 'Primary Monitor' && display !== "Screen ") {
        if (!validDisplay) {
            stream.getTracks().forEach(track => track.stop());
            alert(`Mohon Untuk Memilih Entire Screen / Seluruh Layar.`);
            _this.setState({ displayMediaStream: null });
            return;
        }

        stream.getVideoTracks()[0].addEventListener('ended', () => {
            alert("Terjadi kesalahan pada kamera dan screen capture anda, sistem akan keluar. Anda dapat login kembali.")
            _this._action.onLogout();
        });

        // if (display === 'monitor' || display === 'Primary Monitor' || display === "Screen ") {
        if (validDisplay) {
            _this.initScreenCaptureRef.current.srcObject = entireScreen;
            _this.setState({ displayMediaStream: stream });
        }

        _this._action.sendWebCamImageToServer();
    }

    this.renderFaceValidationCondition = () => {
        // console.log("VALIDATION ");
        // return _this.state.userMediaStream !== null && _this._validation.onValidation();
        // return setTimeout(() => {
        //     _this.state.userMediaStream !== null && _this._validation.onValidation();
        // }, 1000)

        return this.conditionOnFaceValidation() ?
            <div>
                {
                    _this.state.participantProfile?.validation_status === 0
                        ?
                        <div
                            className={classes.btnOk}
                            onClick={() => {
                                _this._validation.onValidation()
                            }}
                            style={{ marginTop: "5px" }}
                        >
                            {_this.state.examProfile.valid_by_default ? Lang("Tangkapan Wajah Untuk Lanjut", _this.state.lang, { en: `Face Detection to Proceed` }) : Lang("Validasi Wajah", _this.state.lang, { en: `Face Validation` })}
                        </div>
                        : null
                }
            </div> : null
    }
    this.onRenderPrepareExam = () => {
        const handleOnValidationStatus = () => {
            if (_this.state.participantProfile.validation_status === 2) {
                return <div
                    className={""}
                    style={{
                        marginTop: "5px",
                        padding: "10px 15px",
                        border: "1px solid red",
                        color: "red",
                        fontWeight: "600"
                    }}
                >
                    <span>
                        Blocked
                    </span>
                </div>
            } else if (_this.mode !== "mobile" && (_this.state.userMediaStream === null || _this.state.displayMediaStream === null || _this.state.numberOfScreen === -1)) {
                return <div
                    className={classes.btnOk}
                    style={{
                        marginTop: "5px"
                    }}
                    onClick={() => {
                        this.handleActiveShareVideo()
                    }}>

                    {_this.state.numberOfScreen !== -1 ?
                        Lang("Aktifkan Web Camera dan Screen Capture", _this.state.lang, { en: `Activate Web Camera and Screen Capture` }) :
                        Lang(`Aktifkan Window Management Permission`, _this.state.lang, { en: `Activate Window Management Permission` })
                    }
                </div>
            }
            return null;
        }
        return <div className={[classes.container_prepare, classes.ascroll, classes.FadeInPage1s
        ].join(" ")}>
            <Tips />
            {_this._component.renderFaceReference()}
            <div className={[classes.prepare, classes.ascroll].join(" ")}>
                {_this._action.onCountDown()}
                {_this.mode === "mobile"
                    ? _this._component.renderHelpMobile()
                    : _this._component.renderHelp()}

                <div
                    style={{
                        width: _this.mode !== "mobile" ? "33%" : "70%",
                        border: "0px solid red"
                    }}>
                    {_this._chatws.renderProctorBroadcastChat(_this.state.broadcastFromProctorerMessage)}
                </div>

                <div className={classes.prepare_profile}>
                    <div>
                        <ProfileShow
                            profile={_this.state.participantProfile}
                            numberOfScreen={_this.state.numberOfScreen}
                        />
                    </div>
                    {
                        _this.context.profile.photo
                        &&
                        <div className={classes.prepare_profile_img}>
                            <img
                                alt="face-id"
                                src={_this.context.profile.photo}
                                style={{
                                    maxHeight: "200px"
                                }}
                            />
                        </div>
                    }

                </div>
                <div className={classes.prepare_top}>
                    <div className={classes.prepare_top_info}>
                        <div className={classes.logo}>i</div>
                        {_this.mode !== "mobile" ?
                            <span className={classes.info}>
                                {
                                    Lang(`Dengan mengaktifkan Web Camera dan Memilih 'Entire Screen/Seluruh Layar' Pada Screen Capture, anda telah menyetujui syarat dan ketentuan yang ditetapkan oleh penyelenggara ujian.`,
                                        _this.state.lang, {
                                        en: `By activating the Web Camera and selecting 'Entire Screen/Entire Screen' in Screen Capture, you have agreed to the terms and conditions set by the exam organizer.`
                                    })
                                }
                            </span>
                            :
                            <span className={classes.info}>
                                {
                                    Lang(`Dengan mengaktifkan Web Camera, anda telah menyetujui syarat dan ketentuan yang ditetapkan oleh penyelenggara ujian.`,
                                        _this.state.lang,
                                        `By activating the Web Camera, you have agreed to the terms and conditions set by the exam organizer.`)
                                }

                            </span>
                        }
                    </div>
                    {handleOnValidationStatus()}
                    {
                        _this.mode === "mobile" && _this.state.userMediaStream === null
                            ?
                            <div
                                className={classes.btnOk}
                                style={{
                                    marginTop: "5px"
                                }}
                                onClick={() => {
                                    this.handleActiveShareVideo()
                                }}>
                                {Lang(`Aktifkan Web Camera`, _this.state.lang, { en: `Activate Web Camera` })}
                            </div>
                            :
                            null
                    }

                    {this.renderFaceValidationCondition()}
                </div>
                {_this._component.renderMediaParticipants()}
                {_this._component.helpAlert()}
                {_this._chatws.privateChat(_this, classes)}
            </div>
            {_this._manual.renderManual()}
        </div>
    }

    this.conditionOnFaceValidation = () => {
        return (_this.state.userMediaStream !== null &&
            ((_this.state.displayMediaStream !== null && _this.mode !== "mobile") || _this.mode === "mobile"))
    }
}