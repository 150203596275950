import React from "react";
import classes from "./Warning.module.scss";
import warning from "../assets/imgs/warning.png";
import PropTypes from "prop-types";

class Warning extends React.Component {
    render() {
        return <div className={classes.container}>
            <div className={classes.container_c}>
                <div className={classes.container_c_title}>
                    <img alt="warning" src={warning} />
                    <span>
                        {this.props.title}
                    </span>
                </div>
                <div className={classes.container_c_bottom}>
                    {this.props.content}
                </div>
            </div>
        </div>
    }
}

Warning.propTypes = {
    content: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default Warning;