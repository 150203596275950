import * as faceapi from "face-api.js"
import { urlKTPImage, urlFaceInKTPImage, urlFaceInWebCam, fetchSaveImageID } from "./proctor_utils";
import { Lang } from "./Language";
import { LANG } from "./utils";

let console = {};
console.log = function () { };
console.error = function () { };

export const inputSizes = [
    32 * 4,
    32 * 5,
    32 * 6,
    32 * 7,
    32 * 8,
    32 * 9,
    32 * 10,
    32 * 11,
    32 * 12,
    32 * 13,
    32 * 14,
    32 * 15,
    32 * 16
];

class ProktorAI {
    constructor(participantId, folderRand, commonWs = null) {
        this.commonWs = commonWs;
        this.participantId = participantId;
        this.folderRand = folderRand;
        this.isReady = false;


        this.faceDetectionOptionForWebCam = {
            // 228 is suitable for web cam, give good result
            // inputSize: 228, as the first reference
            inputSize: inputSizes[9 - 4],

            // use score threshold 0.25
            // not too strict
            scoreThreshold: 0.25
        }

        fetchSaveImageID(participantId, this.folderRand, (err) => {
            console.error(err);
        }).then((data) => {
            // console.log('save image id', data);
        })
    }

    setCommonWs(commonWs) {
        this.commonWs = commonWs;
    }

    async load() {
        this.faceReference = null;
        try {
            this.faceReference = await faceapi.fetchImage(urlFaceInWebCam(this.participantId, this.folderRand, Date.now() % 5000));
        } catch (e) {
            console.log("error fetch urlfaceincam", e);
        };

        return Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
            faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
            faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
            faceapi.nets.faceExpressionNet.loadFromUri("/models"),
        ]).then((v) => {
            this.isReady = true;
        }).catch((e) => {
            console.log('error load Face Model', e);
            alert(Lang(
                "Gagal Mengunduh Modul AI, Mohon pastikan anda memiliki koneksi internet yang stabil. Sistem akan merefresh halaman anda, mohon untuk login kembali.", LANG(),
                { en: `Failed to Download AI Module, Please make sure you have a stable internet connection. The system will refresh your page, please log in again.` }));
            window.location.href = "/";
        }).finally((v) => {
            this.isReady = true;
        });
    }

    async onPlay() {
    }

    async extractPhotoFromId() {
        return new Promise((resolve, reject) => {
            faceapi.fetchImage(urlKTPImage(this.participantId, this.folderRand)).then((inputKTP) => {
                const options = new faceapi.TinyFaceDetectorOptions(this.faceDetectionOptionForWebCam);
                if (inputKTP.width === 0 || inputKTP.height === 0) {
                    console.error("img error");
                    resolve(null);
                    return;
                }

                faceapi.detectAllFaces(inputKTP, options)
                    .withFaceLandmarks()
                    .withFaceExpressions().then((resKTP) => {
                        const canvasKTP = document.getElementsByName("canvas")[0];
                        if (canvasKTP === undefined) {
                            resolve(undefined)
                            return;
                        }
                        canvasKTP.width = Math.round(resKTP[0].detection.box.width);
                        canvasKTP.height = Math.round(resKTP[0].detection.box.height);

                        canvasKTP.getContext("2d").drawImage(inputKTP,
                            Math.round(resKTP[0].detection.box.x),
                            Math.round(resKTP[0].detection.box.y - 50),
                            Math.round(resKTP[0].detection.box.width),
                            Math.round(resKTP[0].detection.box.height + 50),
                            0,
                            0,
                            Math.round(resKTP[0].detection.box.width),
                            Math.round(resKTP[0].detection.box.height));
                    }).catch((err) => {
                        console.error(err);
                    });
                resolve(undefined);
            }).catch((err) => {
                console.error(err);
                reject(new Error(err));
            })
        })
    }

    async extractFaceFromKTP(photoUrl, inputSize = 32 * 4, actual_photo_is_empty = false) {
        // const inputKTP = await 
        return new Promise((resolve, reject) => {
            console.log("actual photo is empty = ", actual_photo_is_empty);
            if (actual_photo_is_empty) {
                resolve(null);
                return;
            }
            faceapi.fetchImage(photoUrl).then((inputKTP) => {
                const options = new faceapi.TinyFaceDetectorOptions({
                    ...this.faceDetectionOptionForWebCam,
                    inputSize: inputSize
                });
                // const resKTP = await 
                if (inputKTP.width === 0 || inputKTP.height === 0) {
                    console.error("error width height");
                    resolve(null);
                    return;
                }
                faceapi.detectAllFaces(inputKTP, options)
                    .withFaceLandmarks()
                    .withFaceExpressions().then((resKTP) => {
                        if (resKTP.length === 0) {
                            console.log("Gagal mendapatkan wajah dari KTP");
                            resolve(null);
                            return;
                        }

                        const canvasKTP = document.createElement("canvas");
                        if (canvasKTP === undefined) {
                            console.log("canvas is not available");
                            resolve(null);
                            return;
                        }

                        canvasKTP.width = Math.round(resKTP[0].detection.box.width);
                        canvasKTP.height = Math.round(resKTP[0].detection.box.height);

                        canvasKTP.getContext("2d").drawImage(inputKTP,
                            Math.round(resKTP[0].detection.box.x),
                            Math.round(resKTP[0].detection.box.y - 50),
                            Math.round(resKTP[0].detection.box.width),
                            Math.round(resKTP[0].detection.box.height + 50),
                            0,
                            0,
                            Math.round(resKTP[0].detection.box.width),
                            Math.round(resKTP[0].detection.box.height));

                        let im = new Image()
                        im.src = canvasKTP.toDataURL();
                        canvasKTP.remove();
                        resolve(im);
                    }).catch((error) => {
                        console.error("ERROR=", error);
                        reject(new Error(error));
                    })
            }).catch((error) => {
                console.error(error);
                reject(new Error(error));
            })
        });
    }

    cropToImgEl(imgEl, results, sendimage = true) {
        const cv = document.createElement("canvas");
        if (cv === undefined) {
            console.error("failed to create canvas");
            return null;
        }
        if (results === undefined) {
            return null;
        }

        cv.width = Math.round(results.box.width);
        cv.height = Math.round(results.box.height);

        cv.getContext("2d").drawImage(imgEl,
            Math.round(results.box.x),
            Math.round(results.box.y),
            Math.round(results.box.width),
            Math.round(results.box.height),
            0,
            0,
            Math.round(results.box.width),
            Math.round(results.box.height));

        if (sendimage) {
            this.commonWs.sendFaceInCamImage(this.participantId, cv.toDataURL('image/jpeg'));
        }

        let img = new Image();
        img.src = cv.toDataURL();

        const imgr = document.getElementById("onValidationCanvas");
        if (imgr !== undefined && imgr !== null) {
            imgr.src = cv.toDataURL();
        }

        cv.remove();
        return img;
    }

    setFaceReference(img) {
        if (this.faceReference !== null) {
            this.faceReference.remove();
        }

        this.faceReference = document.createElement("img");
        this.faceReference.src = img.src;
    }

    captureVideoElementToImage320x240(video, boxwidth = (320 / 2)) {
        let canvasSource = document.createElement("canvas");
        canvasSource.width = 320;
        canvasSource.height = 240;
        const ctxSource = canvasSource.getContext("2d");

        let new_width = -1, new_height = -1;
        // if width < height // portrait
        if (video.videoWidth < video.videoHeight) {
            new_height = 240;
            new_width = Math.round((video.videoWidth * new_height) / video.videoHeight);

            ctxSource.drawImage(video,
                0,
                0,
                video.videoWidth,
                video.videoHeight,
                (320 / 2) - (new_width / 2),
                0,
                new_width,
                new_height
            );
        } else {
            new_width = 320;
            new_height = Math.round((video.videoHeight * new_width) / video.videoWidth);

            const sy = (240 / 2) - (new_height / 2);
            console.log("sy", sy, new_width, new_height);
            ctxSource.drawImage(video,
                0,
                0,
                video.videoWidth,
                video.videoHeight,
                0,
                sy,
                new_width,
                new_height
            );
        }

        let canvas = document.createElement("canvas");
        const centerX = 320 / 2;
        const centerY = 240 / 2;
        const width = boxwidth;

        // very important to set width and height of canvas
        canvas.width = width;
        canvas.height = width;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(canvasSource,
            centerX - width / 2,
            centerY - width / 2,
            width,
            width,
            0,
            0,
            width,
            width
        );

        let img = new Image();
        img.src = canvas.toDataURL();
        img.width = canvas.width;
        img.height = canvas.height;
        canvas.remove();
        return img;
    }

    captureVideoElementToImage2(video, boxwidth = 80) {
        let canvas = document.createElement("canvas");

        const centerX = video.videoWidth / 2;
        const centerY = video.videoHeight / 2;
        const width = boxwidth;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video,
            centerX - width / 2,
            centerY - width / 2,
            width,
            width,
            0,
            0,
            width,
            width
            // canvas.width, 
            // canvas.height
        );

        let img = new Image();
        img.src = canvas.toDataURL();
        img.width = canvas.width;
        img.height = canvas.height;
        canvas.remove();
        return img;
    }

    captureVideoElementToImage(video) {
        if (video === null) {
            return null;
        }
        let canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas
            .getContext("2d")
            .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        let img = new Image();
        img.src = canvas.toDataURL('image/jpeg');
        img.width = canvas.width;
        img.height = canvas.height;
        canvas.remove();
        return img;
    }

    async captureFaceFromWebCam(videoRef) {
        const videoWeb = videoRef.current;
        let img = this.captureVideoElementToImage(videoWeb);
        return img;
    }

    async extractFaceFromImg(img, inputSize = 32 * 4) {
        return new Promise((resolve, reject) => {
            const options = new faceapi.TinyFaceDetectorOptions(
                {
                    ...this.faceDetectionOptionForWebCam,
                    inputSize: inputSize
                });

            if (img.width === 0 || img.height === 0) {
                console.error("img error");
                resolve(null);
                console.log("AFTER RESOLVE");
                return;
            }
            faceapi.detectSingleFace(img, options).then((results) => {
                console.log('extract face img ', results);
                img = this.cropToImgEl(img, results, false);
                resolve({ img: img, score: results === undefined ? 0 : results.score });
            }).catch((error) => {
                console.log("Found error here");
                resolve(null);
            }).finally(() => {
                console.log("FINALLY");
            })
        })
    }

    async extractFaceFromWebCam(videoRef) {
        return new Promise((resolve, reject) => {
            const videoWeb = videoRef.current;
            let img = this.captureVideoElementToImage(videoWeb);

            const options = new faceapi.TinyFaceDetectorOptions(this.faceDetectionOptionForWebCam);
            if (img.width === 0 || img.height === 0) {
                console.error("img error");
                resolve(null);
                return;
            }

            faceapi.detectSingleFace(img, options).then((results) => {
                img = this.cropToImgEl(img, results);
                resolve(img);
            }).catch(error => {
                reject(new Error(error));
            })
        })
    }

    async faceValidation2(img1, img2) {
        let desc1 = await faceapi.computeFaceDescriptor(img1);
        let desc2 = await faceapi.computeFaceDescriptor(img2);
        let dist = faceapi.utils.round(
            faceapi.euclideanDistance(desc1, desc2));
        return dist;
    }

    async faceValidationFromImg(img) {
        const inputFaceInKTP = await faceapi.fetchImage(urlFaceInKTPImage(this.participantId));
        let desc1 = await faceapi.computeFaceDescriptor(img);
        let desc2 = await faceapi.computeFaceDescriptor(inputFaceInKTP);
        let dist = faceapi.utils.round(
            faceapi.euclideanDistance(desc1, desc2));

        img = null;
        return dist;
    }


    async faceValidation(videoRef) {
        let img = await this.extractFaceFromWebCam(videoRef);
        if (img === null) {
            return -1;
        }
        const inputFaceInKTP = await faceapi.fetchImage(urlFaceInKTPImage(this.participantId));

        let desc1 = await faceapi.computeFaceDescriptor(img);
        let desc2 = await faceapi.computeFaceDescriptor(inputFaceInKTP);
        let dist = faceapi.utils.round(
            faceapi.euclideanDistance(desc1, desc2));

        img = null;
        return dist;
    }

    async findFaceSimilarity(videoEl, results) {
        let dist = -1;
        if (results.length >= 1) {
            let canvasWebcam = document.createElement("canvas");
            canvasWebcam.width = Math.round(results[0].box.width);
            canvasWebcam.height = Math.round(results[0].box.height);
            canvasWebcam.getContext("2d").drawImage(videoEl,
                Math.round(results[0].box.x),
                Math.round(results[0].box.y),
                Math.round(results[0].box.width),
                Math.round(results[0].box.height),
                0,
                0,
                Math.round(results[0].box.width),
                Math.round(results[0].box.height));

            // find eucliedean
            let descriptors = { desc1: null, desc2: null }
            try {
                let img1 = document.createElement("img");
                img1.src = canvasWebcam.toDataURL();

                const img2 = this.faceReference;
                canvasWebcam.remove();

                descriptors["desc1"] = await faceapi.computeFaceDescriptor(img1);
                descriptors["desc2"] = await faceapi.computeFaceDescriptor(img2);
                img1.remove();

                dist = faceapi.utils.round(
                    faceapi.euclideanDistance(descriptors.desc1, descriptors.desc2)
                )
            } catch (e) {
                console.log("error face similarity", e);
            }
        }
        return dist;
    }

    async onExamFaceDetection(img, img_screen) {
        return new Promise((resolve, reject) => {
            if (this.isReady === false && this.faceReference !== null) {
                console.log("is ready == false or faceReference is null");
                resolve(undefined);
                return;
            }

            const options = new faceapi.TinyFaceDetectorOptions({
                ...this.faceDetectionOptionForWebCam,
                scoreThreshold: 0.1
            });

            if (img.width === 0 || img.height === 0) {
                console.error("img error", img.height, img.naturalHeight);
                resolve(undefined);
                return;
            }

            faceapi.detectAllFaces(
                img,
                options).then((results) => {
                    // if the face number is > 1, check the score, if the score is > 0.4, take is as a face
                    let numberOfFaces = 0;
                    if (results.length > 1) {
                        for (let i in results) {
                            const f = results[i]
                            if (f.score > 0.4) {
                                numberOfFaces++;
                            }
                        }
                        if (numberOfFaces === 0) {
                            numberOfFaces = 1;
                        }
                    } else {
                        numberOfFaces = results.length
                    }
                    this.findFaceSimilarity(
                        img,
                        results).then((faceSimilar) => {
                            resolve({
                                "face": {
                                    "number": numberOfFaces
                                },
                                "faceid": {
                                    "dist": faceSimilar
                                },
                                img_face: img,
                                img_screen: img_screen
                            });
                        }).catch((err) => { reject(new Error(err)) })
                }).catch((err) => { reject(new Error(err)) });
        })
    }

    // Analytics
    static scoringWajahTidakTerdeteksi = (warningvalue) => {
        let res = {
            calm: 0,
            anxiety: 0,
            pasrah: 0
        };
        if (warningvalue >= 0 && warningvalue <= 5) {
            res.calm = 5;
            res.anxiety = 0;
            res.pasrah = 0;
        } else if (warningvalue > 5 && warningvalue <= 15) {
            res.calm = 4;
            res.anxiety = 1;
            res.pasrah = 0;
        } else if (warningvalue > 15 && warningvalue <= 25) {
            res.calm = 3;
            res.anxiety = 1;
            res.pasrah = 1;
        } else if (warningvalue > 25 && warningvalue <= 50) {
            res.calm = 2.5;
            res.anxiety = 1.5;
            res.pasrah = 1;
        } else if (warningvalue > 50 && warningvalue <= 75) {
            res.calm = 1;
            res.anxiety = 2.5;
            res.pasrah = 1.5;
        } else if (warningvalue > 75) {
            res.calm = 0;
            res.anxiety = 2.5;
            res.pasrah = 2.5;
        }
        return res;
    }
    static scoringWajahLebihDariSatu = this.scoringWajahTidakTerdeteksi
    static scoringWajahTidakCocokId = this.scoringWajahTidakTerdeteksi
    static scoringAdaTabBaru = (warningvalue) => {
        let res = {
            calm: 0,
            anxiety: 0,
            pasrah: 0
        };
        if (warningvalue >= 0 && warningvalue <= 25) {
            res.calm = 5;
            res.anxiety = 0;
            res.pasrah = 0;
        } else if (warningvalue > 25 && warningvalue <= 50) {
            res.calm = 2.5;
            res.anxiety = 2.5;
            res.pasrah = 0;
        } else if (warningvalue > 50 && warningvalue <= 75) {
            res.calm = 0;
            res.anxiety = 2.5;
            res.pasrah = 2.5;
        } else if (warningvalue > 75) {
            res.calm = 0;
            res.anxiety = 1.5;
            res.pasrah = 3.5;
        }
        return res;
    }

    static scoringPrintScreen = (warningvalue) => {
        let res = {
            calm: 0,
            anxiety: 0,
            pasrah: 0
        };
        if (warningvalue >= 1 && warningvalue <= 25) {
            res.calm = 0;
            res.anxiety = 2.5;
            res.pasrah = 2.5;
        } else if (warningvalue > 25 && warningvalue <= 50) {
            res.calm = 0;
            res.anxiety = 1.5;
            res.pasrah = 3.5;
        } else if (warningvalue > 50 && warningvalue <= 75) {
            res.calm = 0;
            res.anxiety = 0;
            res.pasrah = 5;
        } else if (warningvalue > 75) {
            res.calm = 0;
            res.anxiety = 0;
            res.pasrah = 5;
        }
        return res;
    }

    static warningAnalytic(wd = {
        start: 0,
        data: [0, 0, 0, 0, 0]
    }, prev = { calm: 0, anxiety: 0, pasrah: 0 }) {
        const analysis = (duration, data) => {
            if (duration === 0) {
                duration = 1000;
            }
            const time = Math.floor(duration / 1000);
            let type = data;
            const scoring = [];
            scoring[0] = this.scoringWajahTidakTerdeteksi
            scoring[1] = this.scoringWajahLebihDariSatu
            scoring[2] = this.scoringWajahTidakCocokId
            scoring[3] = this.scoringAdaTabBaru
            scoring[4] = this.scoringPrintScreen

            let reswarning = [];

            // special case type0 and type2 (wajah tidak terdeteksi, wajah tidak sesuai id)
            if (type[0] > type[2]) {
                type[2] = Math.floor(type[0] * 0.75)
            } else {
                type[0] = Math.floor(type[2] * 0.75)
            }
            for (let i = 0; i <= 4; i++) {
                if (i === 1) {
                    continue;
                }
                if (type[i] > 0) {
                    reswarning.push(scoring[i]((type[i] / time) * 100))
                }
            }

            let av = {
                calm: 0,
                anxiety: 0,
                pasrah: 0
            }
            if (reswarning.length > 0) {
                for (const element of reswarning) {
                    av.calm += element.calm;
                    av.anxiety += element.anxiety;
                    av.pasrah += element.pasrah
                }

                av.calm = Math.floor((av.calm / (reswarning.length * 5)) * 100);
                av.anxiety = Math.floor((av.anxiety / (reswarning.length * 5)) * 100);
                av.pasrah = Math.floor((av.pasrah / (reswarning.length * 5)) * 100);

                if (av.calm === 0) {
                    av.calm = Math.floor(5 + Math.random() * 15);
                    av.anxiety = Math.floor(av.anxiety - (av.calm / (Math.random() * 3)));
                    av.pasrah = Math.floor(100 - av.calm - av.anxiety);
                }
            }
            if (av.calm < 0) {
                return prev;
            }
            if (av.anxiety < 0) {
                return prev;
            }
            if (av.pasrah < 0) {
                return prev;

            }

            if (av.calm > 100) {
                return prev;

            }
            if (av.anxiety > 100) {
                return prev;

            }
            if (av.pasrah > 100) {
                return prev;
            }

            return av;
        }

        const start = wd.start
        const end = Date.now();

        const data = wd.data

        const result = analysis(end - start === 0, data)
        return {
            start: start,
            end: end,
            warningdata: data,
            emotion: result
        };
    }

}

export default ProktorAI;