import FileWebsocket from "../../libs/FileWebsocket";

export const __FileWs__ = function (_this) {
    /* ImageWs */
    _this.imageWs = null;

    this.fileWsProctorOnMessageReceived = (_this, msg) => {

    }

    this.filewsRoutine = () => {
        if ( _this.imageWs === null ) {
            _this.imageWs = new FileWebsocket(
                false,
                _this.context.profile.id,
                "proctorer",
                null,
                this.fileWsProctorOnMessageReceived,
                (err) => {
                    _this.imageWs?.close();
                    _this.imageWs = null;
                }
            )
        }
    }
}