import React, { useEffect, useRef } from 'react';
import classes from './Indicator.module.scss';
import PropTypes from "prop-types";

const Indicator = ({ color }) => {

    const indicatorRef = useRef();

    useEffect(() => {
        indicatorRef.current.style.backgroundColor = color;
    }, [color])

    return (
        <div ref={indicatorRef} className={classes.indicator_circle}></div>
    )
}

Indicator.propTypes = {
    color: PropTypes.string.isRequired
}

export default Indicator;