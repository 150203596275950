import React from "react";
import greaterthan from "../../assets/imgs/greaterthan.png";
import new_message from "../../assets/sounds/new-message.mp3";
import reload from "../../assets/imgs/reload.png";
import help from "../../assets/imgs/help.png";
import ProgressStatus from "../../components/ProgressStatus";
import LoadingFullScreen from "../../components/LoadingFullScreen";
import { convertTimestampToTime, getAccessExamToken, getIframeToken, getQuestionUrl } from "../../libs/utils";
import { TELKOM_CASE, urlProctorerRandom } from "../../libs/proctor_utils";
import WebCam from "../../components/WebCam";
import Timer from "../../components/Timer";
import ProfileShow from "../../components/ProfileShow";
import Indicator from "../../components/Indicator";
import amanin from "../../assets/imgs/logo512.png";
import { __Manual__ } from "./Manual";
import { __Mobile__ } from "./Mobile";
import Troubleshooting from "../../components/Troubleshooting";
import { Lang } from "../../libs/Language";
import Language from "../../components/Language";
import Tips from "../../components/Tips";
import Logout from "../../components/Logout";

export const __Component__ = function (_this, classes) {
    _this._manual = new __Manual__(_this, classes);
    _this._mobile = new __Mobile__(_this, classes);
    _this.audioRef = React.createRef();


    // proctorerImageRef
    _this.proctorerImageRef = React.createRef();
    _this.proctorerImageRefLastTs = 0;
    //

    _this.iframeRef = React.createRef();
    _this.mode = _this.props.mode;
    _this.faceReferenceDebugEnable = false;

    this.helpAlert = () => {
        let result = Lang(`Mohon untuk menunggu, pengawas akan segera menghubungi anda melalui private chat. Klik 'Bantuan Pengawas' kembali apabila belum direspon setelah beberapa saat.`,
            _this.state.lang,
            { en: `Please wait, the proctorer will immediately contact you via private chat. Click 'Help' again if you haven't responded after a while.` });
        return <>
            {
                _this.state.helpAlert &&
                <div className={classes.help_alert} >
                    <span>{result}</span>
                </div>
            }
        </>
    }

    this.renderHelp = (showReloadExam = false) => {
        if (_this.state.examProfile.hide_chat_help_button) {
            return <></>
        }
        return <>
            <div className={classes.btnHelp}
                onClick={() => {
                    _this._action.onHelp();
                    alert(Lang("Notifikasi bantuan telah kami sampaikan. Pengawas yang tersedia akan segera menghubungi anda via Private Chat.", _this.state.lang,
                        { en: `We have provided assistance notification. Available proctorers will immediately contact you via Private Chat.` }));
                }}
                style={{
                    margin: "5px",
                    minWidth: _this.state.showControlBox !== "small" ? "200px" : "40px"
                }}
            >
                <img alt="help" src={help} width="20px" />
                {_this.state.showControlBox !== "small" &&
                    <span style={{ whiteSpace: "nowrap" }}>{Lang(`Bantuan Pengawas`, _this.state.lang, { en: `Help` })}</span>
                }
            </div>

            {/*showReloadExam === true ?
                <div className={classes.btnReload}
                    onClick={() => {
                        if (window.confirm("Do you want to reload Exam Page ?")) {

                            document.getElementById("exam-iframe") && (document.getElementById("exam-iframe").src = getQuestionUrl(_this.state.examProfile.url, _this.context.profile.question_id));
                        }
                    }}
                    style={{
                        margin: "5px",
                        minWidth: _this.state.showControlBox !== "small" ? "200px" : "40px"
                    }}
                >
                    <img alt="reload" src={reload} width="20px" />
                    {_this.state.showControlBox !== "small" &&
                        <span style={{ whiteSpace: "nowrap" }}>
                            Reload Exam Page
                        </span>}
                </div> : null
                */}

        </>
    }



    // this.renderPrepareExam = () => {
    //     return _this._preparation.onRenderPrepareExam()
    // }


    this.renderMediaParticipants = () => {
        return <div className={classes.media}>
            <div className={classes.media_b}>
                <video ref={_this.initWebCamRef} autoPlay muted playsInline></video>
                <div>
                    WebCam
                </div>
            </div>

            {
                _this.mode !== "mobile" &&
                <div className={classes.media_b}>
                    <video ref={_this.initScreenCaptureRef} autoPlay muted></video>
                    <div>
                        Screen Capture
                    </div>
                </div>
            }
        </div>
    }

    this.renderFaceReference = () => {
        return _this.faceReferenceDebugEnable && <img alt="reference" className={classes.facereference} id="faceReference" />
    }

    this.renderMainExam = () => {
        _this.state.examProfile.url_in_iframe && _this._action.sendDataToIframe();
        return (
            <div className={classes.container}>
                <Tips />
                {this.renderFaceReference()}
                {this.renderAudio()}
                {this.renderLeftExam()}
                {
                    _this.mode !== "mobile" &&
                    <div className={classes.container_right} >
                        {this.renderControlBox()}
                    </div>
                }
            </div >
        );
    }

    this.renderWaitingExam = () => {
        return <div className={[classes.container, classes.ascroll].join(" ")}>
            {this.renderAudio()}
            {this.renderLeftExam(false)}
        </div>
    }

    this.renderInit = () => {
        return <div className={[classes.container, classes.ascroll, classes.FadeInPage1s
        ].join(" ")} style={{
            backgroundColor: "white"
        }}
        >
            <div className={classes.container_conmsg}>
                <div className={classes.container_conmsg_progress}>
                    <ProgressStatus
                        type="ai"
                        state={_this.state.progressStateAI}
                    />
                    <ProgressStatus
                        state={_this.state.progressStateUser}
                    />
                    <ProgressStatus
                        type="connection"
                        state={_this.state.progressStateConn}
                    />
                </div>
                <div className={classes.container_conmsg_content}>
                    {_this.state.connectingMessage}
                </div>
            </div>
        </div>
    }

    this.renderConnectionLost = () => {
        return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
            <div className={classes.container_conmsg}>
                <div className={classes.container_conmsg_content}>
                    {_this.state.connectingMessage}
                </div>
            </div>
        </div>
    }


    this.renderOnValidation = () => {
        return _this._validation.onRenderValidation();
    }

    this.renderAudio = () => {
        return <audio ref={_this.audioRef}>
            <track kind="captions" {..._this.props} />
            <source src={new_message} type="audio/mpeg" />
            Browser Anda tidak mendukung tag audio.
        </audio>
    }

    this.generateLoadingFullScreen = () => {
        if (_this.state.loadingFS.mainText !== "") {
            return <LoadingFullScreen
                mainText={_this.state.loadingFS.mainText}
                subText={_this.state.loadingFS.subText}
                displayTextDuration={_this.state.loadingFS.displayTextDuration}
            />
        }
        return <></>
    }

    this.renderCountDownTime = () => {
        return <div className={classes.preparation_countDown}>
            <div className={classes.preparation_countDown_text}>
                <span style={{
                    fontSize: ".75rem",
                    fontWeight: "bold",
                    padding: "3px 3px 3px 0px"
                }}>
                    {/* Menuju Waktu Ujian */}
                </span>
            </div>
            <div className={classes.preparation_countDown_timer}>
                {convertTimestampToTime(_this.currentTs)}
            </div>
        </div >
    }

    this.renderEventNormal = (info) => {
        return <div className={classes.c}>
            <div>i</div>
            <span>{info}</span>
        </div>
    }

    this.renderEventWarning = (info) => {
        return <div className={classes.c}>
            <div className={classes.warning}>!</div>
            <span>{info}</span>
        </div>
    }

    this.renderImageProctorer = (smallMode) => {
        return <div
            style={{
                width: smallMode === false ? "132px" : "70px",
                height: smallMode === false ? "107px" : "70px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "black"
            }}
        >
            <img
                alt="proctorer"
                style={{
                    maxWidth: smallMode === false ? "132px" : "70px",
                    maxHeight: smallMode === false ? "132px" : "70px"
                }}
                ref={_this.proctorerImageRef}
                src={urlProctorerRandom()}
                onError={() => {
                    setTimeout(() => {
                        _this.proctorerImageRef.current.src = urlProctorerRandom()
                    }, 1000);
                }} />
        </div>
    }

    this.render2x2Video = () => {
        console.log(_this.state.examProfile);
        return <div className={[
            classes.control_webcam,
            _this.state.showControlBox === "small" && classes.control_webcamsmall
        ].join(" ")} style={{ display: "flex", justifyContent: "center" }}>
            {_this.state.userMediaStream !== null &&
                _this.state.userMediaStream !== undefined ? (
                <div className={classes.media_facescreen} >
                    <WebCam videoId="inputVideo"
                        stream={_this.state.userMediaStream}
                        smallMode={_this.state.showControlBox === "small"}
                    />
                    <span>
                        WebCam
                    </span>
                </div>
            ) : null}
            {_this.state.displayMediaStream !== null &&
                _this.state.displayMediaStream !== undefined ? (
                <div className={classes.media_facescreen}>
                    <WebCam videoId="inputDisplayVideo"
                        stream={_this.state.displayMediaStream}
                        smallMode={_this.state.showControlBox === "small"}
                    />
                    <span>
                        Screen Capture
                    </span>
                </div>
            ) : null}

            {/* {_this.state.userMediaStream !== null &&
                _this.state.userMediaStream !== undefined ? (
                this.renderImageProctorer(_this.state.showControlBox === "small")
            ) : (
                <div></div>
            )} */}

            {/* <WebCam type="AI"
                smallMode={_this.state.showControlBox === "small"}
            /> */}
        </div>
    }

    this.renderTroubleShootingButton = () => {
        return <>
            <Troubleshooting />
            {TELKOM_CASE === false &&
                <Language currentLang={_this.state.lang}
                    onChangeLang={(v) => { _this.setState({ lang: v }) }}
                />
            }
        </>
    }

    this.renderControlBox = () => {
        const calcWidth = () => {
            if (_this.state.showControlBoxState === "normal") {
                return "280px";
            } else if (_this.state.showControlBoxState === "small") {
                return "70px"
            } else {
                return "0px"
            }
        }
        return (
            <div
                style={{
                    width: calcWidth()
                }}
                className={[classes.control, classes.ascroll].join(" ")}>
                <div
                    className={classes.control_toggle}
                    onClick={() => {
                        let dest = "small";
                        if (_this.state.showControlBoxState === "small") {
                            dest = "normal";
                        }
                        _this.setState({
                            showControlBoxState: "close"
                        });
                        setTimeout(() => {
                            _this.setState({
                                showControlBox: dest,
                                showControlBoxState: dest,
                            });
                        }, 1000)
                    }}
                    title="try me"
                >
                    <img
                        alt="<>"
                        src={greaterthan}
                        width="20px"
                        style={{
                            transform: _this.state.showControlBox === "small" ?
                                "rotate(180deg)" :
                                "rotate(0deg)"
                        }}
                    />
                </div>
                {
                    _this.state.showControlBox !== "small" &&
                    <div className={classes.control_timer}
                        style={{
                            color: _this.state.displayTimeAlertColor,
                            width: "280px"
                        }}>
                        <Timer
                            value={_this.state.examStage.time}
                        />
                    </div>
                }
                {/* <div className={classes.control_examTime}>
          <span>{_this.state.examStage.title}</span>
        </div> 
        */}

                {_this.state.showControlBox !== "small" &&
                    <div className={classes.control_profile}
                        style={{ width: "280px" }}
                    >
                        <ProfileShow
                            profile={_this.state.participantProfile}
                        />
                    </div>
                }
                {this.render2x2Video()}

                {
                    _this.state.showControlBox !== "small" &&
                    <div className={classes.control_info}
                        style={{ minWidth: "270px" }}
                    >
                        {(_this.state.eventState.event === 'normal' || _this.state.examProfile.show_warning === 0) && this.renderEventNormal(
                            Lang('Usahakan wajah anda selalu tertangkap kamera.', _this.state.lang, { en: `Make sure your face is always caught on camera.` }))}
                        {(_this.state.eventState.event !== 'normal' && _this.state.examProfile.show_warning === 1) && this.renderEventWarning(_this.state.eventState.info[0])}
                    </div>
                }
                <div className={classes.control_chat}>
                    {this.renderHelp(true)}
                </div>

                {
                    _this.state.showControlBox !== "small" &&
                    _this._chatws.renderProctorBroadcastChat(_this.state.broadcastFromProctorerMessage)
                }

                {_this._manual.renderManualExam()}
                <div className={classes.control_indicator}>
                    <Indicator color={_this.state.indicator.commonws} />
                    <Indicator color={_this.state.indicator.recording} />
                    <Indicator color={_this.state.indicator.chatws} />
                    <Indicator color={_this.state.indicator.ai} />
                </div>

                {_this._chatws.privateChat()}
            </div>
        );
    }

    this.renderLeftExam = (showIFrame = true) => {
        const renderAlternative = () => {
            return _this.state.initExam ?
                <div className={classes.container_left_top}>
                    <div>
                        <img alt="amanin"
                            src={amanin}
                            width="26px" />
                        <img
                            alt="client"
                            src={_this.state.examProfile.secondary_logo_url}
                            width="26px"
                        />
                    </div>
                    <div>
                        <div className={classes.title}>
                            <span className={classes.big}>
                                {_this.state.examProfile.title}
                            </span>
                            <span className={classes.small}>
                                {_this.state.examProfile.title_2}
                            </span>
                        </div>
                    </div>
                    <div style={{ marginRight: "5px" }}>
                        <Logout onLogout={() => _this.onLogout(true)} />
                    </div>
                </div>
                :
                // _this._mobile.renderHelpMobile(true)
                this.renderHelpMobile(true)
        }

        let question_url = getQuestionUrl(_this.state.examProfile.url, _this.context.profile.question_id);
        const param = `${question_url.includes("?") ? "&" : "?"}exam_token=${getAccessExamToken()}&token=${getIframeToken()}`
        question_url = `${question_url}${param}`

        return <div className={classes.container_left}>
            {_this.mode !== "mobile"
                ?
                <div className={classes.container_left_top}>
                    <div className={classes.images}>
                        {TELKOM_CASE === false && <>
                            <img alt="amanin"
                                src={amanin}
                                width="26px" />
                            <img
                                alt="client"
                                src={_this.state.examProfile.secondary_logo_url}
                                width="26px"
                            />
                        </>
                        }
                        {this.renderTroubleShootingButton()}

                    </div>
                    <div className={classes.title}>
                        <span className={classes.big}>
                            {_this.state.examProfile.title}
                        </span>
                        <span className={classes.small}>
                            {_this.state.examProfile.title_2}
                        </span>
                    </div>
                    {TELKOM_CASE === false &&
                        <div style={{ marginRight: "40px" }}>
                            <Logout onLogout={() => _this._action.onLogout(true)} />
                        </div>
                    }
                </div>
                :
                renderAlternative()
            }
            <div
                className={
                    [classes.container_left_bottom,
                    classes.ascroll].join(" ")}
            >
                {showIFrame === true ?
                    <>
                        {_this.mode === "mobile" &&
                            <>
                                <div className={classes.container_left_bottom_video} >
                                    {_this.state.userMediaStream !== null &&
                                        _this.state.userMediaStream !== undefined ? (<WebCam
                                            type="ExamMobile"
                                            videoId="inputVideo"
                                            stream={_this.state.userMediaStream}
                                            mode="mobile"
                                            smallMode={true}
                                        />

                                    )
                                        :
                                        null}
                                </div>
                                <div style={{ zIndex: 6, position: "absolute" }}>
                                    {/* {_this.privateChat()} */}
                                    {_this._chatws.privateChat("mobile")}
                                </div>
                            </>
                        }

                        {/* {console.log("EXAM TOKEN : ", getAccessExamToken())} */}

                        {_this.state.examProfile.url_in_iframe ?

                            <iframe
                                title="exam-view"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                // src={getQuestionUrl(_this.state.examProfile.url, _this.context.profile.question_id)}
                                src={question_url}
                                allowFullScreen
                                id="exam-iframe"
                                rel="noreferrer"
                                ref={_this.iframeRef}
                            >
                            </iframe>

                            : <div
                                className={classes.linkquiz}
                            >
                                <div className={classes.btnLink}
                                    onClick={() => {
                                        let url = getQuestionUrl(_this.state.examProfile.url, _this.context.profile.question_id);
                                        let newWindow = window.open(url, "_blank");
                                        newWindow.focus();
                                    }}
                                >
                                    Klik untuk menuju ke soal ujian
                                </div>
                            </div>
                        }
                    </>
                    :
                    _this._preparation.onRenderPrepareExam()
                }
            </div>
        </div>
    }

    this.renderHelpMobile = (showReloadExam = false) => {
        return <div className={classes.btnMobile}>
            <div className={classes.btnHelp}
                onClick={() => {
                    _this._action.onHelp();
                    alert("Notifikasi bantuan telah kami sampaikan. Pengawas yang tersedia akan segera menghubungi anda via Private Chat.");
                }}
                style={{
                    margin: "5px",
                    // minWidth: _this.state.showControlBox !== "small" ? "200px" : "40px"
                }}
            >
                <img alt="help" src={help} width="20px" />
                {_this.state.showControlBox !== "small" &&
                    <span style={{ whiteSpace: "nowrap" }}>
                        {/* Bantuan Pengawas */}
                        Bantuan
                    </span>
                }
            </div>

            {showReloadExam === true ?
                <div className={classes.btnReload}
                    onClick={() => {
                        if (window.confirm("Do you want to reload Exam Page ?")) {
                            document.getElementById("exam-iframe").src = _this.state.examProfile.url;
                        }
                    }}
                    style={{
                        margin: "5px",
                        // minWidth: _this.state.showControlBox !== "small" ? "200px" : "40px"
                    }}
                >
                    <img alt="reload" src={reload} width="20px" />
                    {_this.state.showControlBox !== "small" &&
                        <span style={{ whiteSpace: "nowrap" }}>
                            {/* Reload Exam Page */}
                            Reload
                        </span>}
                </div> : null}
        </div>
    }

    // this.renderHelpMobile2 = (showReloadExam = false) => {
    //     return <div className={classes.btnWrapper}>
    //         <div className={classes.btnHelp}
    //             onClick={() => {
    //                 _this._action.onHelp();
    //                 alert("Notifikasi bantuan telah kami sampaikan. Pengawas yang tersedia akan segera menghubungi anda via Private Chat.");
    //             }}
    //             style={{ margin: "5px" }}
    //         >
    //             <img alt="help" src={help} width="20px" />
    //             {
    //                 _this.state.initExam ? <span>Bantuan Pengawas</span> :
    //                     <span>Help</span>
    //             }
    //         </div>

    //         {showReloadExam === true ?
    //             <div className={classes.btnReload}
    //                 onClick={() => {
    //                     if (window.confirm("Do you want to reload Exam Page ?")) {
    //                         document.getElementById("exam-iframe").src = _this.state.examProfile.url;
    //                     }
    //                 }}
    //                 style={{ margin: "5px" }}
    //             >
    //                 <img alt="reload" src={reload} width="20px" />
    //                 {/* <span>Reload Exam Page</span> */}
    //                 <span>Reload</span>
    //             </div> : null}
    //         {
    //             (_this.state.initExam === false && _this.state.userMediaStream !== null &&
    //                 _this.state.userMediaStream !== undefined) && <WebCam type="Exam" videoId="inputVideo" stream={_this.state.userMediaStream} />
    //         }
    //         {
    //             _this.state.initExam === false &&
    //             <div
    //                 style={{
    //                     position: "relative",
    //                     width: "40px",
    //                     height: "36px",
    //                     marginLeft: "5px",
    //                     // border: "1px solid black"
    //                 }}
    //             >
    //                 {_this._chatws.privateChat("mobile")}
    //             </div>
    //         }
    //     </div>
    // }

}