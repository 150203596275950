import React from "react";
import classes from "./Timer.module.scss"
import PropTypes from "prop-types";

class Timer extends React.Component {
    render() {
        return <div className={classes.container}>{this.props.value}</div>
    }
}

Timer.propTypes = {
    value: PropTypes.string.isRequired
}
export default Timer;