import React from "react";
import classes from "./LoadingFullScreen.module.scss";
import Loading from "./Loading";
import PropTypes from "prop-types";

class LoadingFullScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            closeNow: false,
            completed: false
        }

        this.mainText = this.props.mainText ? this.props.mainText : "Loading ...";
        this.subText = this.props.subText ? this.props.subText : "Anda akan diarahkan ke halaman tujuan sesaat lagi.";
        this.displayTextDuration = this.props.displayTextDuration ? this.props.displayTextDuration : 3000;
    }

    componentDidMount() {
        // wait for 1s for fadeIn
        const totalDisplayDuration = 1000 + this.displayTextDuration
        setTimeout(() => {
            this.setState({
                closeNow: true
            })
            // wait 1s to accomplish fadeOut
            setTimeout(() => {
                this.setState({
                    completed: true
                })
            }, 1000)
        }, totalDisplayDuration);


    }

    render() {
        if (this.state.completed === true) {
            return <></>;
        }
        return <div className={[
            classes.container,
            this.state.closeNow ? classes.close_fadeout : classes.open_fadein
        ].join(" ")}>
            <Loading
            />
            <span className={classes.container_mt}>
                {this.mainText}
            </span>
            <span className={classes.container_st}>
                {this.subText}
            </span>
        </div>
    }
}

LoadingFullScreen.propTypes = {
    mainText: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    displayTextDuration: PropTypes.number.isRequired
}

export default LoadingFullScreen;