import { getAccessToken, urlLocal } from "./utils";

let console = {};
console.log = function () { };

class CommonWebsocket {
    constructor(_parent, folderRand, onMessageReceived = null, onClose = null) {
        this._parent = _parent;
        this.folderRand = folderRand
        this.socket = new WebSocket("wss://" + urlLocal + "/commonws");


        this.socket.addEventListener("open", (event) => {
            // console.log("[commonws] on open");
        })

        this.socket.addEventListener("message", (event) => {
            // console.log("[commonws] on message");
        })

        this.socket.onclose = () => {
            // console.log("[commonws] on close");
            if (onClose !== null) {
                onClose(this._parent, null);
            }
        }

        this.socket.onerror = (err) => {
            // console.log("[commonws] on error");
            if (onClose !== null) {
                onClose(this._parent, err);
            }
        }

        this.socket.onopen = () => {
            // console.log("[commonws] on open");
        }

        this.socket.onmessage = (data) => {
            if (typeof (data.data) === "string") {
                if (onMessageReceived !== null) {
                    onMessageReceived(this._parent, data.data);
                }
            } else {
                data.data.text().then((d) => {
                    if (onMessageReceived !== null) {
                        onMessageReceived(this._parent, d);
                    }
                })
            }
        }
    }

    ready() {
        return this.socket.readyState === 1;
    }
    close() {
        this.socket.close();
    }

    registerClient(type, id, listOfParticipants = null) {
        // console.log("[register] type=" + type + ", id=" + id)
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: type,
                    id: id,
                    listOfParticipants: listOfParticipants,
                    token: getAccessToken()
                })
            )
        } else {
            // console.log("[register] socket is not ready");
            return false;
        }
        return true;
    }
    registerParticipant(id) {
        return this.registerClient("register-participant", id);
    }
    registerProctorer(id, listOfParticipants) {
        return this.registerClient("register-proctorer", id, listOfParticipants);
    }

    getCurrentTS(id) {
        // console.log("[getCurrentTS] type= getCurrentTs" + ", id=" + id)
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: "getcurrentts",
                    id: id,
                    token: getAccessToken()
                })
            )
        } else {
            // console.log("[getCurrentTS] socket is not ready");
            return false;
        }
        return true;
    }

    sendStoreParticipantImage(participantId) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: "store-participant-image",
                    participantId: participantId,
                    'folder-rand': this.folderRand,
                    token: getAccessToken()
                })
            )
        }
    }

    sendRefreshConnection(participantId) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: "refresh-connection",
                    participantId: participantId,
                    token: getAccessToken()
                })
            )
        }
    }

    errorSocketIsNotReady() {
        // console.log("[commonws] socket is not ready");
    }

    // sendImage(id, base64) {
    // sendProctorerImage(id, base64) {
    //     return this.sendImage("proctorimg", id, base64);
    // }

    // sendParticipantImage(id, base64, type = "face", is_warning = false, ts = null) {
    //     if (type === "screen") {
    //         return this.sendImage("participantscreenimg", id, base64, is_warning, ts);
    //     }
    //     return this.sendImage("participantimg", id, base64, is_warning, ts);
    // }

    // sendFaceScreenScreenShotImage(id, face_base64, screen_base64, notes, ts, folder_rand) {
    //     return this.sendScreenShotImage("screenshot", id, face_base64, screen_base64, notes, ts, folder_rand);
    // }

    // sendFaceInKTPImage(id, base64) {
    //     return this.sendImage("faceinktpimg", id, base64);
    // }

    // sendFaceInCamImage(id, base64) {
    //     return this.sendImage("faceincamimg", id, base64);
    // }

    sendStartWebRTC(proctorerId, participantId, proctorId, videoRouter = null) {
        if (this.socket.readyState === 1) {
            this.socket.send(JSON.stringify({
                type: 'start-webrtc',
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                videoRouter: videoRouter,
                token: getAccessToken()
            })
            )
        }
    }

    sendWebRTCStarted(proctorerId, participantId, proctorId) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: 'webrtc-started',
                    proctorId: proctorId,
                    participantId: participantId,
                    proctorerId: proctorerId,
                    token: getAccessToken()
                })
            )
        }
    }

    sendWebRTCFailed(mediaStatus, proctorerId, participantId, proctorId) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify({
                    type: 'webrtc-failed',
                    mediaStatus: mediaStatus,
                    proctorId: proctorId,
                    participantId: participantId,
                    proctorerId: proctorerId,
                    token: getAccessToken()
                })
            )
        }
    }

    // sendImage(type, id, base64, is_warning = false, ts = null) {
    //     if (this.socket.readyState === 1) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: type,
    //                     is_warning: is_warning,
    //                     profile: {
    //                         id: id,
    //                         img: base64,
    //                         'folder-rand': this.folderRand,
    //                         ts: ts
    //                     },
    //                     token: getAccessToken()
    //                 }));
    //     } else {
    //         this.errorSocketIsNotReady();
    //         return false;
    //     }
    //     return true;
    // }

    // sendScreenShotImage(type, id, face_base64, screen_base64, notes, ts, folder_rand) {
    //     if (this.socket.readyState === 1) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: type,
    //                     profile: {
    //                         id: id,
    //                         img_face: face_base64,
    //                         img_screen: screen_base64,
    //                         notes: notes.replace(/\n/g, "\n"),
    //                         ts: ts,
    //                         'folder-rand': folder_rand
    //                     },
    //                     token: getAccessToken()
    //                 }));
    //     } else {
    //         this.errorSocketIsNotReady();

    //         return false;
    //     }
    //     return true;
    // }

    // sendChat(from, to, msg, participantId = null) {
    //     if (this.socket.readyState === 1) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: "chat",
    //                     chat: {
    //                         from: from,
    //                         to: to,
    //                         msg: msg,
    //                         participantId: participantId
    //                     }
    //                 }));
    //     } else {
    //         this.errorSocketIsNotReady();
    //         return false;
    //     }
    //     return true;
    // }

    sendBroadcastToParticipant(from, to, msg, participantId = null) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "broadcast-alert",
                        chat: {
                            from: from,
                            to: to,
                            msg: msg,
                            participantId: participantId
                        },
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendUpdateValidationStatus(proctorerId, participantId, status) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "update-validation",
                        participantId: participantId,
                        proctorerId: proctorerId,
                        status: status,
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendVideoValidationTime(proctorerId, participantId, startTs, endTs) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "update-video-validation",
                        participantId: participantId,
                        proctorerId: proctorerId,
                        startTs: startTs,
                        endTs: endTs,
                        token: getAccessToken()
                    }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    // sendNewPrivateChat(participantId, proctorerId) {
    //     if (this.socket.readyState === 1) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: "new-private-chat",
    //                     participantId: participantId,
    //                     proctorerId: proctorerId
    //                 }));
    //     } else {
    //         this.errorSocketIsNotReady();
    //         return false;
    //     }
    //     return true;
    // }

    // sendClosePrivateChat(participantId, proctorerId) {
    //     if (this.socket.readyState === 1) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: "close-private-chat",
    //                     participantId: participantId,
    //                     proctorerId: proctorerId
    //                 }));
    //     } else {
    //         this.errorSocketIsNotReady();
    //         return false;
    //     }
    //     return true;
    // }

    sendUpdateVideoDistribution(participantId, proctorerId, ipaddress, stats) {
        if (this.socket.readyState === 1) {
            this.socket.send(JSON.stringify({
                type: "update-video-distribution",
                participantId: participantId,
                proctorerId: proctorerId,
                ipaddress: ipaddress,
                stats: stats,
                token: getAccessToken()
            }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendUpdateTs(id, ipAddress, type = "participant") {
        if (this.socket.readyState === 1) {
            this.socket.send(JSON.stringify({
                type: "update-ts",
                userType: type,
                id: id,
                token: getAccessToken(),
                ipAddress: ipAddress,
            }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendLogout(participantId) {
        if (this.socket.readyState === 1) {
            this.socket.send(JSON.stringify({
                type: "logout",
                participantId: participantId,
                token: getAccessToken()
            }));
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    // getPrivateChat(participantId, proctorerId) {
    //     if (this.ready()) {
    //         this.socket.send(
    //             JSON.stringify(
    //                 {
    //                     type: "get-private-chat",
    //                     participantId: participantId,
    //                     proctorerId: proctorerId

    //                 }
    //             )
    //         )
    //     } else {
    //         this.errorSocketIsNotReady();
    //         return false;
    //     }
    //     return true;
    // }

    sendAIWarning(from, warning_type, ts, warningdata = null) {
        // AI Warning Type
        /*
        0 - no face detected
        1 - face detected more than 1
        2 - face id unmatch
        3 - open new tab
        4 - print screen
        5 - hear the sound
        */
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "aiwarning",
                        from: from,
                        warningtype: warning_type,
                        ts: ts,
                        'folder-rand': this.folderRand,
                        warningdata: warningdata,
                        token: getAccessToken()
                    }
                )
            )
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendKeyStroke(from, key) {
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "keystroke",
                        from: from,
                        key: key,
                        'folder-rand': this.folderRand,
                        token: getAccessToken()
                    }
                )
            )
        } else {
            this.errorSocketIsNotReady();
            return false;
        }
        return true;
    }

    sendPing(from) {
        // console.log("[commonws] send ping");
        if (this.socket.readyState === 1) {
            this.socket.send(
                JSON.stringify(
                    {
                        type: "ping",
                        from: from,
                        'folder-rand': this.folderRand,
                        token: getAccessToken()
                    }
                )
            )
        }
    }
}


export default CommonWebsocket;