import WebCam from "../../components/WebCam";
import help from "../../assets/imgs/help.png";
import reload from "../../assets/imgs/reload.png";

export const __Mobile__ = function (_this, classes) {
    this.renderHelpMobile = (showReloadExam = false) => {
        return <div className={classes.btnWrapper}>
            <div className={classes.btnHelp}
                onClick={() => {
                    _this._action.onHelp();
                    alert("Notifikasi bantuan telah kami sampaikan. Pengawas yang tersedia akan segera menghubungi anda via Private Chat.");
                }}
                style={{
                    margin: "5px",
                    minWidth: _this.state.showControlBox !== "small" ? "200px" : "40px"
                }}
            >
                <img alt="help" src={help} width="20px" />
                {_this.state.showControlBox !== "small" &&
                    <span style={{ whiteSpace: "nowrap" }}>Bantuan Pengawas</span>
                }
            </div>

            {showReloadExam === true ?
                <div className={classes.btnReload}
                    onClick={() => {
                        if (window.confirm("Do you want to reload Exam Page ?")) {
                            document.getElementById("exam-iframe").src = _this.state.examProfile.url;
                        }
                    }}
                    style={{
                        margin: "5px",
                        minWidth: _this.state.showControlBox !== "small" ? "200px" : "40px"
                    }}
                >
                    <img alt="reload" src={reload} width="20px" />
                    {_this.state.showControlBox !== "small" &&
                        <span style={{ whiteSpace: "nowrap" }}>
                            Reload Exam Page
                        </span>}
                </div> : null}
        </div>
    }

    this.renderHelpMobile2 = (showReloadExam = false) => {
        return <div className={classes.btnWrapper}>
            <div className={classes.btnHelp}
                onClick={() => {
                    _this._action.onHelp();
                    alert("Notifikasi bantuan telah kami sampaikan. Pengawas yang tersedia akan segera menghubungi anda via Private Chat.");
                }}
                style={{ margin: "5px" }}
            >
                <img alt="help" src={help} width="20px" />
                {
                    _this.state.initExam ? <span>Bantuan Pengawas</span> :
                        <span>Help</span>
                }
            </div>

            {showReloadExam === true ?
                <div className={classes.btnReload}
                    onClick={() => {
                        if (window.confirm("Do you want to reload Exam Page ?")) {
                            document.getElementById("exam-iframe").src = _this.state.examProfile.url;
                        }
                    }}
                    style={{ margin: "5px" }}
                >
                    <img alt="reload" src={reload} width="20px" />
                    {/* <span>Reload Exam Page</span> */}
                    <span>Reload</span>
                </div> : null}
            {
                (_this.state.initExam === false && _this.state.userMediaStream !== null &&
                    _this.state.userMediaStream !== undefined) && <WebCam type="Exam" videoId="inputVideo" stream={_this.state.userMediaStream} />
            }
            {
                _this.state.initExam === false &&
                <div
                    style={{
                        position: "relative",
                        width: "40px",
                        height: "36px",
                        marginLeft: "5px",
                        // border: "1px solid black"
                    }}
                >
                    {_this.privateChat("mobile")}
                </div>
            }
        </div>
    }

}
