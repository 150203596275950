import { createRef } from "react";
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";
import ScreenshotCard from "../ScreenshotCard";
import { fetchScreenshotList } from "../../libs/proctor_utils";

export const __Screenshot__ = function (_this, classes) {
    _this.screenShotRef = createRef();

    this.getParticipantScreenshotNotes=(participantId, folderRand) => {
        fetchScreenshotList(participantId, folderRand, (err) => {
            console.log("[ERROR]", err);
        }).then((data) => {
            if (data && data.status === 'success') {
                _this.setState({ screenshotNotes: data.result })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    this.saveScreenshot = () => {
        try {
            this.sendFaceScreenScreenShotToServer();
            alert(Lang("Data berhasil disimpan", LANG(), { en: `Data is saved successfully` }));
            _this.screenShotRef.current.value = "";

        } catch (error) {
            console.error(error)
            alert(Lang("Data gagal disimpan, silakan coba kembali.", LANG(), { en: `Data is failed to save, try again.` }));
        }
    }

    this.showScreenshot = () => {
        return <div
            className={classes.modal_c_profile_right}
        >
            <div className={classes.modal_c_profile_right_photos}>
                <div>
                    <span>Image</span>
                    <img
                        alt="webcam"
                        src={_this.state.faceImgUrl}
                        height="250px"
                        ref={_this.participantImageRef}
                        onError={() => {
                            setTimeout(() => {
                                _this.participantImageRef.current.src = _this.state.faceImgUrl;
                            }, 1000);
                        }}
                        onClick={(event) => {
                            if (_this.props.openImage) {
                                _this.props.onImageClose()
                            } else {
                                _this.props.onImageOpen([event.target.src])
                            }
                        }}
                        style={{ cursor: "zoom-in" }}
                    />
                </div>
                <div>
                    <span>Screen Capture</span>
                    <img
                        alt="livescreen"
                        src={_this.state.screenImgUrl}
                        height="250px"
                        ref={_this.screenImageRef}
                        onError={() => {
                            setTimeout(() => {
                                _this.screenImageRef.current.src = _this.state.screenImgUrl;
                            }, 1000);
                        }}
                        onClick={(event) => {
                            if (_this.props.openImage) {
                                _this.props.onImageClose()
                            } else {
                                _this.props.onImageOpen([event.target.src])
                            }
                        }}
                        style={{ cursor: "zoom-in" }}
                    />
                </div>


            </div>

            <textarea
                style={{ marginTop: "10px" }}
                cols="70"
                rows="8"
                ref={_this.screenShotRef}
                onChange={(ev) => {
                }}
                // defaultValue={_this.props.data?.notes}
                defaultValue={""}
                placeholder={Lang('Ketik Keterangan Screenshot', LANG(), { en: `Type Screenshot Description` })}
            >
            </textarea>

            <button
                style={{ margin: "10px" }}
                className={classes.btnOk}
                onClick={() => {
                    this.saveScreenshot();
                }}
            >
                {Lang(`Simpan Screenshot`, LANG(), { en: `Save Screenshot` })}
            </button>
        </div>
    }

    this.viewScreenshot = () => {
        let k = 0;
        return <div className={classes.screenshot_card_wrapper}>
            {
                _this.state.screenshotNotes.map((state, index) => {
                    return (
                        <div key={k++}
                            className={classes.screenshot_card}>
                            <ScreenshotCard
                                data={state}
                                participantId={_this.props.data.id}
                                folderRand={_this.props.data.folder_rand}
                                index={index}
                                updateData={(participantId, folderRand) => _this.getParticipantScreenshotNotes(participantId, folderRand)}
                                imageModal={(event) => {
                                    if (_this.props.openImage) {
                                        _this.props.onImageClose()
                                    } else {
                                        _this.props.onImageOpen(event)
                                    }
                                    // console.log("data : ", event)
                                }}
                                style={{ cursor: "zoom-in" }}
                            />
                        </div>
                    );
                })}
        </div>
    }

    this.sendFaceScreenScreenShotToServer = () => {
        try {
            let els_face = _this.participantImageRef.current;
            let els_screen = _this.screenImageRef.current;

            if (els_face === undefined || els_face === null) {
                return;
            }

            if (els_screen === undefined || els_screen === null) {
                return;
            }
            let canvas_face = document.createElement('canvas');
            let canvas_screen = document.createElement('canvas');

            canvas_face.width = 640;
            canvas_face.height = 480;

            canvas_screen.width = 854;
            canvas_screen.height = 480;

            let ctx_face = canvas_face.getContext('2d');
            let ctx_screen = canvas_screen.getContext('2d');

            ctx_face.drawImage(els_face, 0, 0, canvas_face.width, canvas_face.height);
            let image_face = canvas_face.toDataURL('image/jpeg');

            ctx_screen.drawImage(els_screen, 0, 0, canvas_screen.width, canvas_screen.height);
            let image_screen = canvas_screen.toDataURL('image/jpeg');

            if (_this.props.imageWs !== null && _this.props.imageWs.sendFaceScreenScreenShotImage(_this.props.data?.id, image_face, image_screen, _this.screenShotRef.current.value, _this.props.currentTs, _this.props.data?.folder_rand) === false) {
                _this.props.imageWs.close();
                _this.props.imageWs = null;
            }
            canvas_face.remove();
            canvas_screen.remove();
        } catch (e) {
            console.error(e)
        }
    }
}