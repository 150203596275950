import WebRTC from "./WebRTC";
import * as constants from "./constants.js";
import { fetchParticipantsByProctorId, fetchParticipantsBySearch } from "./proctor_utils";

// let console = {};
// console.log = function () { };
// console.error = function () { };

class ParticipantManager {
  constructor(proctorerId, proctorerName, stateCallback) {
    this.participants = {};
    this.stateCallback = stateCallback;
    this.commonWs = null;
    this.seq = 0;

    this.searchText = ""

    this.proctorerId = proctorerId;
    this.proctorerName = proctorerName;

    this.callingInterval = null;
    this.updateStatusInterval = null;
    this.waitingForStartedWebRTC = false;

    this.videoElInterval = null;
    this.videoRouter = [];

    if (this.updateStatusInterval !== null) {
      clearInterval(this.updateStatusInterval);
    }

    this.updateStatusInterval = setInterval(() => {
      if (this.commonWs === null) {
        return;
      }

      if (this.proctorerId.includes("proctoradmin")) {
        this.getParticipantStatus(this.searchText, true)
      } else {
        this.getParticipantStatus(this.proctorerId);
      }
    }, 10000);

    if (this.callingInterval !== null) {
      clearInterval(this.callingInterval);
    }

    this.callingInterval = setInterval(() => {
      if (this.proctorerId.includes("#") || this.proctorerId.includes("admin")) {
        // console.log("do not call, you are sub proctor or admin");
        return;
      }
      this.callingRoutine();
    }, 5000);
  }

  setSearchParam(searchText) {
    this.searchText = searchText
  }

  getSearchParam() {
    return this.search;
  }

  updateVideoRouter(videoRouter) {
    this.videoRouter = videoRouter;
  }

  getVideoRouterCandidate = () => {
    const id = Math.round(Math.random() * (this.videoRouter.length - 1))
    return this.videoRouter[id];
  }


  handleEstablished(participantId) {
    if (this.participants[participantId].establishedStartTs !== 0) {
      if (Date.now() - this.participants[participantId].establishedStartTs > 30000) {
        let el = document.getElementById("status_" + this.participants[participantId].proctorId);
        el && (el.style.display = "none");

        el = document.getElementById(this.participants[participantId].proctorId);
        el && (el.style.display = "flex");

        el = document.getElementById("img_" + this.participants[participantId].proctorId);
        el && (el.style.display = "none");
      }
    } else {
      let el = document.getElementById(this.participants[participantId].proctorId);
      el && (el.style.display = "none");

      el = document.getElementById("img_" + this.participants[participantId].proctorId);
      el && (el.style.display = "flex");
    }
  }

  callingRoutine() {
    if (this.commonWs === null) {
      return;
    }

    let servedNumber = 0;

    let sortByTry = [];

    let onlineNumber = 0;
    for (let u in this.participants) {
      this.handleEstablished(u);

      if (this.participants[u].status !== "online") {
        continue;
      }

      if (this.participants[u].call_status === "established") {
        continue;
      }
      onlineNumber++;

      let x = {
        try: this.participants[u].try,
        id: u
      }
      let i = 0;
      for (; i < sortByTry.length; i++) {
        let s = sortByTry[i];
        if (s.try >= this.participants[u].try) {
          sortByTry.splice(i, 0, x)
          break;
        }
      }
      if (i === sortByTry.length) {
        sortByTry.push(x);
      }
    }

    // check if there is participant is calling or not
    let nCalling = 10;
    if (sortByTry.length < nCalling) {
      nCalling = sortByTry.length;
    }
    for (let i = 0; i < nCalling; i++) {
      let sbt = sortByTry[i];
      if (sbt.try !== -1) {
        let u = sbt.id;

        //there is only one participant left, keep try number to 0
        if (onlineNumber === 1) {
          this.participants[u].try = 0;
        }

        // First In First Serve
        let p = this.participants[u];
        if (p.waitingWebRTC.send_start_webrtc_ts === 0) {
          servedNumber++;
          this.participants[u].try++;

          // if there is online participant, using commonWs call 
          // the participant to activate webrtc
          this.participants[u].waitingWebRTC.send_start_webrtc_ts = Date.now();
          this.participants[u].videoRouter = this.getVideoRouterCandidate();
          this.commonWs.sendStartWebRTC(
            this.proctorerId,
            u,
            this.proctorerId + u,
            this.participants[u].videoRouter)
        } else if (Date.now() - p.waitingWebRTC.send_start_webrtc_ts > 10000) {
          if (p.waitingWebRTC.sendPreOffer !== "failed") {
            this.participants[u].waitingWebRTC.send_start_webrtc_ts = 0;
          }
        }
      }
    }

    if (servedNumber === 0) {
      //  reset all of the sendPreOffer failed
      for (let u in this.participants) {
        this.participants[u].waitingWebRTC.sendPreOffer = "";
      }
    }
  }

  webRTCAction(participantId, isWebRTCRunning, mediaStatus) {
    if (isWebRTCRunning === true) {
      this.participants[participantId].waitingWebRTC.sendPreOffer = "start";
      this.callParticipant(participantId);
      return;
    }
    this.participants[participantId].waitingWebRTC.sendPreOffer = "failed";
    this.participants[participantId].waitingWebRTC.send_start_webrtc_ts = 0;
  }

  setCommonWs(commonWs) {
    this.commonWs = commonWs;
  }

  setSoundDetectedTs(participantId, ts) {
    this.participants[participantId].soundDetectedTs = ts;
  }

  checkDynValidationValue(dx) {
    if (this.participants[dx.id].dynamic_validation.confirmed !== dx.confirmed ||
      this.participants[dx.id].dynamic_validation.value !== dx.value) {
      this.participants[dx.id].dynamic_validation.confirmed = dx.confirmed;
      this.participants[dx.id].dynamic_validation.value = dx.value;
      this.participants[dx.id].dynamic_validation = {
        green: dx.green,
        yellow: dx.yellow,
        orange: dx.orange,
        pink: dx.pink,
        red: dx.red,
        value: dx.value,
        valid: dx.valid,
        always_valid: dx.always_valid,
        confirmed: dx.confirmed
      }
      return true;
    }
    return false;
  }

  getParticipantStatus(searchOrProctorerId, search = false) {
    const handleDataResult = (data) => {
      if (data !== null && data.status === "success") {
        let dataUpdated = false;
        for (let d in data.result) {
          let dx = data.result[d];
          if (this.participants[dx.id]) {
            dataUpdated = this.checkDynValidationValue(dx);

            this.participants[dx.id].validation_status = dx.validation_status;
            this.participants[dx.id].ice_id = dx.ice_id;

            const diff = (Date.now() / 1000 - parseInt(dx.last_connected))
            if (diff < 40) {
              if (this.participants[dx.id].livevideo_available === false) {
                this.participants[dx.id].livevideo_available = true;
                dataUpdated = true;
              }
            } else {
              if (this.participants[dx.id].livevideo_available === true) {
                this.participants[dx.id].livevideo_available = false;
                dataUpdated = true;
              }
            }

            if (this.participants[dx.id].status !== dx.status) {
              if (dx.status === 'offline' && this.participants[dx.id].offlineCount > 2) {
                this.participants[dx.id].status = dx.status;
                this.participants[dx.id].offlineCount = 0;
                dataUpdated = true;
              } else if (dx.status === 'offline') {
                this.participants[dx.id].offlineCount++;
              } else if (dx.status === 'online') {
                this.participants[dx.id].status = "online";
                this.participants[dx.id].offlineCount = 0;
              }
            }

            if (this.participants[dx.id].currentTime === undefined) {
              this.participants[dx.id].currentTime = 0;
            }
            let elv = document.getElementById(this.participants[dx.id].proctorId);

            let el = document.getElementById("status_" + this.participants[dx.id].proctorId);

            if (el !== undefined && el !== null) {
              if (this.participants[dx.id].status === 'online') {
                el.style.display = "none";
              } else {
                el.style.display = "flex";
              }
            }

            if (this.participants[dx.id].status === 'online' && elv !== null && this.participants[dx.id].currentTime < elv.currentTime) {
              this.participants[dx.id].currentTime = elv.currentTime;
              this.participants[dx.id].try = 0;
            } else if (this.participants[dx.id]?.currentTime > elv?.currentTime) {
              this.participants[dx.id].currentTime = elv.currentTime;
            }
          }
        }
        if (dataUpdated && this.stateCallback !== null) {
          this.stateCallback();
        }
      }
    }
    const handleError = (error) => {
      console.error(error);
    }
    if (search === false) {
      const proctorerId = searchOrProctorerId
      fetchParticipantsByProctorId(proctorerId, handleError)
        .then(handleDataResult);
    } else {
      const search = searchOrProctorerId
      fetchParticipantsBySearch(search, handleError)
        .then(handleDataResult);
    }
  }

  deleteAllParticipants() {
    this.participants = {};
  }

  addParticipant(userId, userUUId, userName, ice_id, photo, folder_rand, userStatus, validation_status, dynamic_validation) {
    this.participants[userId] = {
      seq: this.seq++,
      id: userId,
      uuid: userUUId,
      username: userName,
      ice_id: ice_id,
      status: userStatus,
      photo: photo,
      folder_rand: folder_rand,
      call_status: "idle",
      try: 0,
      proctorId: this.proctorerId + userId,
      currentTime: 0,
      currentTimeTry: 0,
      webrtc: null,
      countCalling: 0,
      offlineCount: 0,
      establishedStartTs: 0,
      validation_status: validation_status,
      dynamic_validation: dynamic_validation,
      livevideo_available: false,
      soundDetectedTs: 0,
      waitingWebRTC: {
        // the time we send start webrtc signal
        send_start_webrtc_ts: 0,

        // webrtc status is received, either
        // participant webrtc is failed or running
        received_webrtc_status_ts: 0,

        // sendPreOffer
        sendPreOffer: ""
      }
    };
  }

  getParticipants() {
    return this.participants;
  }

  callParticipant(userId) {
    this.participants[userId].call_status = "calling";
    delete this.participants[userId].webrtc;
    this.participants[userId].webrtc = null;
    this.participants[userId].webrtc = new WebRTC(
      this.participants[userId].ice_id,
      this.participants[userId].proctorId,
      this.participants[userId].proctorName,
      this.participants[userId].videoRouter,
      () => {
        if (this.participants[userId].webrtc === null) {
          return;
        }
        this.participants[userId].webrtc.sendPreOffer(
          constants.callType.VIDEO_PERSONAL_CODE,
          userId,
          this.participants[userId].proctorId,
          this.participants[userId].proctorName
        );
      },
      false,
      null,
      null,
      (status) => {
        if (this.participants[userId] !== undefined) {
          this.participants[userId].call_status = status;
        }
        if (status !== "established") {
          this.participants[userId].call_status = "idle";
          this.participants[userId].establishedStartTs = 0;
        } else if (status === "established") {
          this.participants[userId].call_status = "established"
          this.participants[userId].currentTime = 0;
          this.participants[userId].establishedStartTs = Date.now();
        }
        this.participants[userId].webrtc = null;
      }
    );
  }

  printParticipants() {
    console.log(this.participants);
  }
}

export default ParticipantManager;
