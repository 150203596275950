import React from "react";
import { LANG, convertTimestampToDayMonth, convertTimestampToTime } from "../libs/utils";
import classes from "./Chat.module.scss";
import close from '../assets/imgs/close.png';
import { TrimText, urlFaceInWebCam, } from "../libs/proctor_utils";
import needhelp from "../assets/imgs/needhelp.png";
import send from "../assets/imgs/send.png";
import Tick from "./Tick";
import down from "../assets/imgs/down.png";
import PropTypes from 'prop-types';
import { Lang } from "../libs/Language";

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            showArrow: false
        }
        this.lastTsMessage = 0;

        this.numberId = -1;
        if (this.props.proctorerId !== undefined) {
            this.numberId = this.props.proctorerId.replace("proctor", "");
        }

        this.roomRef = React.createRef();
        this.isThereMoving = false;
    }

    sendOnChange() {
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    sendChat() {
        this.props.onChatSend(this.inputRef.current?.value)
        this.inputRef.current.value = "";
    }

    processBroadcast(msg) {
        if (msg === undefined) {
            return [];
        }
        let ch = msg;
        return ch;
    }

    handleValidationPopup = (participantId, participantUUId) => {
        return this.props.onValidation(participantId, participantUUId);
    }
    handleCloseHelpButton = (participantId, participantUUId) => {
        return this.props.handleCloseHelpButton(participantId, participantUUId);
    }

    needHelp(from_id, from_uuid, folder_rand) {
        return <div className={classes.validation}>
            <button
                className={[classes.validation_btn, classes.button2div].join(" ")}
                onClick={() => this.handleValidationPopup(from_id, from_uuid)}
                title={`${from_id} ${Lang('butuh bantuan', LANG(), { en: 'need assistance' })}`}
            >
                <img
                    alt="faceinwebcam"
                    src={urlFaceInWebCam(
                        from_id,
                        folder_rand)} width="50px" />
                <img
                    alt="needhelp"
                    src={needhelp}
                    width="20px"
                >
                </img>
                <span>{Lang('butuh bantuan', LANG(), { en: 'need assistance' })}</span>
            </button>
            <img onClick={() => this.handleCloseHelpButton(from_id, from_uuid)} style={{
                width: "15px",
                marginLeft: "5px",
                border: "1px solid gray",
                borderRadius: "50px",
                cursor: "pointer"
            }}
                alt="close" src={close} />
        </div>
    }

    bubbleBox(k, time, from_id, from_uuid, text, received_state, folder_rand = null) {
        if (text.includes("[cmd]")) {
            return;
        }
        let useRight = false;
        let b = classes.bubble;
        let bttri = classes.bubble_text_triangle;

        if (this.props.privateId !== undefined && this.props.privateId !== from_id) {
            useRight = true;
        }

        if (useRight === true) {
            b = classes.bubbler;
            bttri = classes.bubbler_text_triangle;
        }
        let state = "wait";
        if (received_state.server === true) {
            if (received_state.receiver === false) {
                state = "tick1";
            } else {
                state = "tick2";
            }
        }
        return <div
            key={k}
            className={b}>
            <div
                className={classes.bubble_text}
                style={{
                    borderTopLeftRadius: useRight === true ? "5px" : "0px",
                    borderTopRightRadius: useRight === false ? "5px" : "0px",
                    backgroundColor: useRight === true ? "#f8fff7" : "white"
                }}
            >
                <div
                    className={bttri}
                ></div>
                <button className={[classes.bubble_text_h, classes.button2div].join(" ")}
                    onClick={() => {
                        if (this.props.onIdClick) {
                            this.props.onIdClick(from_id, from_uuid)
                        }
                    }}
                    title={from_id}
                >{TrimText(from_id, 30)}
                </button>
                <span className={classes.bubble_text_c}>{
                    text === "validation" ?
                        this.needHelp(from_id, from_uuid, folder_rand) :
                        text
                }</span>
                <div className={classes.bubble_text_t}>
                    <span className={classes.bubble_text_t_t}>
                        {time}
                    </span>
                    {useRight === false && this.props.title !== "Broadcast Chat" && <Tick
                        state={state}
                    />}
                </div>
            </div>

        </div>
    }

    handleScroll = (e) => {
        // Update the scroll position when the user scrolls
        // this.setState({ scrollPosition: window.scrollY });
        // console.log(e);
        this.isThereMoving = this.isScrollMoving();
    };


    isScrollMoving() {
        return this.roomRef.current.scrollTop * -1 > 5;
    }

    componentDidMount() {
        this.roomRef.current.scrollTop = this.roomRef.current.scrollHeight;
        this.roomRef.current.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        // Remove the event listener when the component unmounts
        this.roomRef.current.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        if (this.isThereMoving === false) {
            this.roomRef.current.scrollTop = this.roomRef.current.scrollHeight;
        }
        let d = "";
        try {
            d = this.props.broadcastMessage;
            if (d !== "") {
                const bc = this.processBroadcast(d);
                if (bc && bc.length > 0) {
                    if (this.lastTsMessage !== bc[bc.length - 1].ts) {
                        this.lastTsMessage = bc[bc.length - 1].ts;
                        if (this.isThereMoving) {
                            this.setState({ showArrow: true })
                        }
                        this.setState({ bc: bc });
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }

    }

    generateChatContent(bc, chat_content = []) {
        let k = 0;
        for (const element of bc) {
            if (element.ts !== "" && element.text !== "" && element.text !== "new private chat") {
                let received = { server: true, receiver: true };
                if (element.received) {
                    received = element.received;
                }
                let isShow = false;
                let misc = {}
                try {
                    if (element.misc) {
                        misc = JSON.parse(element.misc);
                    }
                } catch (e) {
                    // console.error("failed to parse misc", element);
                }
                let parProfile = {
                    folder_rand: misc["folder-rand"] ? misc["folder-rand"] : null
                }

                if (this.props.participantsList !== undefined) {
                    for (let pr in this.props.participantsList) {
                        if (element.text === "validation" && element.from_id === this.props.participantsList[pr].id) {
                            isShow = true;
                            parProfile.folder_rand = this.props.participantsList[pr].folder_rand;
                            parProfile.from_uuid = this.props.participantsList[pr].uuid;
                            break;
                        }
                    }
                }
                if (isShow === false && element.text === "validation" && this.numberId !== -1) {
                    let M = 100;
                    let moda = element.from_id % M;
                    let modb = this.numberId % M;
                    if (moda !== modb
                        && String(this.props.proctorerId).includes("proctoradmin") === false
                    ) {
                        isShow = false;
                    } else {
                        isShow = true;
                    }
                }

                if (isShow === false && element.text === "validation") {
                    continue;
                }

                let time = 0;
                try {
                    time = /*bc[p].ts + " " + */convertTimestampToDayMonth(element.ts) + ", " + convertTimestampToTime(element.ts)
                } catch (e) {
                }

                chat_content.unshift(
                    this.bubbleBox(k++, time, element.from_id, parProfile.from_uuid, element.text, received, parProfile.folder_rand)
                )
            }
        }
    }

    render() {
        let d = "";
        let bc = [];
        try {
            d = this.props.broadcastMessage;
            if (d !== "") {
                bc = this.processBroadcast(d);
            }
        } catch (e) { }

        let chat_content = [];
        this.generateChatContent(bc, chat_content);
        return <div className={classes.container}>
            {this.props.privateId?.includes("proctor") && <div className={classes.container_showchat}>
                <div onClick={() => {
                    this.props.onChatSend("[cmd]close_your_window")
                }}>close participant chat</div>
            </div>
            }
            <span>{this.props.title}</span>
            {
                this.state.showArrow && <button
                    onClick={() => {
                        this.roomRef.current.scrollTop = 0;
                        this.setState({ showArrow: false })
                    }}
                    className={[classes.newmessage, classes.button2div].join(" ")}>
                    <img alt="down" src={down} />
                </button>
            }
            <div ref={this.roomRef} className={[
                classes.container_room,
                classes.ascroll
            ].join(" ")}>
                {chat_content}
            </div>
            <div className={classes.container_input}>
                <input
                    type="text"
                    placeholder={Lang("Ketik pesan anda", LANG(), { en: `Type your message` })}
                    ref={this.inputRef}
                    onKeyDown={(e) => {
                        this.sendOnChange()
                        if (e.key === "Enter") {
                            this.sendChat();
                        }
                    }}

                />
                <button className={[classes.send, classes.button2div].join(" ")}
                    title={Lang("kirim", LANG(), { en: 'send' })}
                    onClick={
                        () => {
                            this.sendChat();
                        }
                    }>
                    <img alt="send" src={send} width="25px" />
                </button>
            </div>
        </div>;
    }
}

Chat.propTypes = {
    proctorerId: PropTypes.string,
    onChatSend: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onIdClick: PropTypes.func,
    privateId: PropTypes.string,
    onValidation: PropTypes.func,
    handleCloseHelpButton: PropTypes.func,
    title: PropTypes.string.isRequired,
    broadcastMessage: PropTypes.array.isRequired,
    participantsList: PropTypes.object,


}
export default Chat;