import React, { createRef } from "react";
import classes from "./ScreenshotCard.module.scss";
import { fetcDeleteScreenshotNotes, fetchUpdateScreenshotNotes, urlScreenShotList } from "../libs/proctor_utils";
import {
    LANG,
    convertTimestampToDateShort,
    convertTimestampToTime
} from "../libs/utils";
import PropTypes from "prop-types";
import { Lang } from "../libs/Language";

let console = {};
console.log = function () { };
console.error = function () { };

class ScreenshotCard extends React.Component {
    constructor(props) {
        super(props);
        this.notesRef = createRef()
        this.notes = this.notesRef.current?.value
        this.state = {
            ready: false,
            edit: false,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                ready: true
            })
        }, this.props.index * 50)
        // console.log("Data : ", this.props.participantId)

    }

    handleEdit = () => {
        // console.log("Screenshot Update : ", this.notesRef.current.value)
        console.log("Screenshot Update : ", {
            participantId: this.props.participantId,
            folderRand: this.props.folderRand,
            ts: this.props.data.ts,
            notes: this.notesRef.current.value
        })
        fetchUpdateScreenshotNotes({
            participantId: this.props.participantId,
            folderRand: this.props.folderRand,
            ts: this.props.data.ts,
            notes: this.notesRef.current.value
        }, (err) => {
            console.log("[ERROR]", err);
        }).then((data) => {
            console.log("Data handleEdit : ", data);
            if (data && data.status === 'success') {
                alert(Lang("Data Berhasil Diupdate!", LANG, {en: `Data is updated`}))
                this.props.updateData(this.props.participantId, this.props.folderRand)
                this.setState({ edit: false })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    handleDelete = () => {
        console.log("Screenshot Delete : ", {
            participantId: this.props.participantId,
            folderRand: this.props.folderRand,
            ts: this.props.data.ts,
            notes: this.notes
        })
        let ret = window.confirm(
            Lang("Apakah anda ingin menghapus data ini ?", LANG, { en: `Do you want to delete this data ?` })
        );
        if (ret === false) {
            return;
        }
        fetcDeleteScreenshotNotes({
            participantId: this.props.participantId,
            folderRand: this.props.folderRand,
            ts: this.props.data.ts
        }, (err) => {
            console.log("[ERROR]", err);
        }).then((data) => {
            console.log("Data handleDelete : ", data);
            if (data && data.status === 'success') {
                this.props.updateData(this.props.participantId, this.props.folderRand)
                return
            }
            alert(data.errorMessage)
        }).catch(err => {
            console.log(err)
        })
    }

    onChangeEditButton = () => {
        this.setState({
            edit: true,
        })
    }

    render() {
        console.log(this.state);
        if (this.state.ready === false) {
            return <></>
        }
        return <div className={classes.screenshot_wrapper} >
            <span>{convertTimestampToDateShort(this.props.data.ts)}{" "}{convertTimestampToTime(this.props.data.ts)}</span>
            <div className={classes.screenshot_image}
                onClick={() => this.props.imageModal(
                    [urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.face),
                    urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.screen)])
                }
                style={{ cursor: "pointer" }}>
                <img
                    alt="screen1"
                    src={urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.face)}
                />
                <img
                    alt="screen2"
                    src={urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.screen)}
                />
            </div>
            {
                this.state.edit
                    ? <textarea
                        rows="3"
                        ref={this.notesRef}
                        defaultValue={this.props.data.notes}
                    />
                    :
                    <pre>
                        {this.notes || this.props.data.notes}
                    </pre>
            }

            <div className={classes.btn_wrapper}>

                {this.state.edit
                    ? <button className={classes.btnOk} onClick={this.handleEdit}>{Lang(`Simpan`, LANG, { en: `Save` })}</button>
                    : <button className={classes.btnLink} onClick={() => this.onChangeEditButton()}>Edit</button>
                }
                <button className={classes.btnHelp} onClick={this.handleDelete}>{Lang(`Hapus`, LANG, { en: `Delete` })}</button>
            </div>
        </div>

    }
}

ScreenshotCard.propTypes = {
    index: PropTypes.number.isRequired,
    participantId: PropTypes.string.isRequired,
    folderRand: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    imageModal: PropTypes.func.isRequired
}

export default ScreenshotCard;