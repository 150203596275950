import { createRef } from "react";
import { Lang } from "../../libs/Language";
import { fetchNotes } from "../../libs/proctor_utils";
import { LANG } from "../../libs/utils";

export const __Notes__ = function (_this, classes) {
    _this.notesRef = createRef();

    this.saveNotes = (flagAttention, cb) => {
        fetchNotes(
            _this.props.proctorerId,
            _this.props.data?.id,
            _this.notesRef.current === null ? _this.state.notes :
                _this.notesRef.current.value,
            flagAttention, (e) => {
                console.log(e);
                alert("Data gagal disimpan, silakan coba kembali.");
            }).then((data) => {
                if (data !== null && data.status === "success") {
                    alert("Data berhasil disimpan");
                    cb()
                } else {
                    alert("Data gagal disimpan, silakan coba kembali.")
                }
            })
    }

    this.showNotes = () => {
        return <div
            className={classes.modal_c_profile_right}
        >
            {/* <div
            style={{
                display: _this.state.mode === "note" ? "flex" : "none"
            }}
            className={classes.modal_c_profile_right}
        >
            <div className={classes.direction}
                onClick={() => {
                    _this.setState({ mode: "visual" })
                }}
            >
                <img alt="video" src={video} height="25px" />
                Ke Mode Visual
            </div> */}

            <textarea
                cols="70"
                rows="25"
                ref={_this.notesRef}
                onChange={(ev) => {
                    _this.setState({ notes: _this.notesRef.current.value })
                }}
                defaultValue={_this.state.notes}
            >
            </textarea>
            <button
                style={{ margin: "10px" }}
                className={classes.btnOk}
                onClick={() => {
                    this.saveNotes(_this.state.flagAttention, () => {
                        if (_this.notesRef.current !== null) {
                            _this.setState({ notes: _this.notesRef.current.value })
                        }
                    });
                }}
            >
                {Lang(`Simpan Catatan`, LANG(), { en: `Save` })}
            </button>
        </div>
    }
}