class PrivateMessage {
    readLocalStorage(localStorageId, raw = false) {
        // get from local storage
        let msg = localStorage.getItem(localStorageId);
        if (raw === true) {
            return msg;
        }
        try {
            if (msg === null) {
                msg = {};
            } else {
                msg = JSON.parse(msg);
            }
        } catch (e) {
            msg = {};
        }
        return msg;
    }

    read(localStorageId) {
        return this.readLocalStorage(localStorageId);
    }

    write(localStorageId, msg) {
        localStorage.setItem(localStorageId, JSON.stringify(msg));
    }

    merge(localStorageId, ts, fromId, message, received_value = false) {
        let msgStorage = this.read(localStorageId)
        // let friends = msgStorage.friends
        let msg = msgStorage.messages
        if (msg === undefined) {
            msg = [];
        }
        for (let m in msg) {
            if (msg[m].ts === ts) {
                return msg;
            }
        }
        if (message !== "") {
            try {
                msg.push({
                    ts: ts,
                    from_id: fromId,
                    text: message,
                    received: {
                        server: received_value, // received by server
                        receiver: received_value// received by receiver
                    }
                });

                // sort
                for (let i = 0; i < msg.length; i++) {
                    for (let j = i + 1; j < msg.length; j++) {
                        if (msg[i].ts > msg[j].ts) {
                            let temp = msg[i];
                            msg[i] = msg[j];
                            msg[j] = temp;
                        }
                    }
                }
            } catch (e) {
                console.error(e);
                msg = [];
            }
        }

        if (!msgStorage.friends) {
            msgStorage.friends = []
        }
        msgStorage.messages = msg
        this.write(localStorageId, msgStorage)
        return msg;
    }

    updateState(localStorageId, ts, fromId, server_state, receiver_state) {
        let msgStorage = this.read(localStorageId);
        // const friends = msgStorage.friends
        let msg = msgStorage.messages
        for (let m in msg) {
            if (String(msg[m].ts) === String(ts)) {
                msg[m].received.server = server_state;
                msg[m].received.receiver = receiver_state;
                break;
            }
        }

        msgStorage.messages = msg;
        this.write(localStorageId, msgStorage);
        return msg;
    }

    pack(ts, from_id, text) {
        return {
            ts: ts,
            from_id: from_id,
            text: text
        }
    }

    chatInsert(currentId, fromId, ts, text, isparticipant = true, proctorerId = null) {
        let localStorageId = `${currentId}-${fromId}`;

        if (isparticipant) {
            localStorageId = `private-chat-${currentId}`;
        }

        return this.merge(
            localStorageId,
            ts,
            proctorerId !== null ? proctorerId : fromId,
            text
        );
    }

    // receiveChat(localStorageId, msg, chatWs) {
    //     console.log(msg);
    //     let listMessage = msg.message;
    //     for (let m in listMessage) {
    //         let message = listMessage[m]
    //         console.log("msg = ", message);

    //         // insert to chat window
    //         this.chatInsert(message);

    //         chatWs.sendChatMessageReceived(
    //             this.context.profile.id,
    //             message.from_id,
    //             message.ts
    //         );
    //     }
    //     console.log("read = ", msg)
    //     return this.read()
    // }

    sendUnsentMessage(from, to, chatWs, isparticipant = true) {
        let localStorageId = `${from}-${to}`

        if (isparticipant) {
            localStorageId = `private-chat-${from}`
        }

        let msg = this.read(localStorageId);
        // const friends = msg.friends;

        msg = msg.messages

        for (let m in msg) {
            if (msg[m].received &&
                msg[m].received.server === false &&
                msg[m].from_id === from) {
                chatWs.sendChat(
                    from,
                    to,
                    JSON.stringify(this.pack(
                        msg[m].ts,
                        from,
                        msg[m].text
                    ))
                )
            } else {
                // console.log("[send unsent message] not send");
            }
        }
    }

    prepare(id, fromId, message, raw = false) {
        // get from local storage
        let msgStorage = localStorage.getItem(id);
        if (raw === true) {
            return msgStorage;
        }

        if (msgStorage === null) {
            return [];
        }

        try {
            msgStorage = JSON.parse(msgStorage);
        } catch (e) {
            return [];
        }
        let msg = msgStorage.messages;
        try {
            if (!msg) {
                msg = [];
            } else {
                // msg = JSON.parse(msg);
            }
        } catch (e) {
            msg = [];
        }
        if (message !== "") {
            try {
                msg.push({
                    ts: Date.now(),
                    from_id: fromId,
                    text: message
                });
            } catch (e) {
                msg = [];
            }
        }
        return msg;
    }
}

export default PrivateMessage;