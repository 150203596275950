import React from "react";
import classes from "./__index__.module.scss";

class EmbeddedParent extends React.Component {
    render() {
        return <div className={classes.container}>
            <iframe
                title="demo"
                allow="camera; microphone; display-capture; window-placement;"
                src="https://proktor.amanin.id"
            />
        </div>
    }
}

export default EmbeddedParent;