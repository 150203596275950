import React from "react";
import loading from "../assets/imgs/loading.gif";
import loading_dot from "../assets/imgs/loading-dot.gif";
import PropTypes from "prop-types";

class Loading extends React.Component {
    render() {
        if (this.props.type === "dot") {
            return <div>
                <img alt="loading" src={loading_dot} width="70px" style={{ borderRadius: "10px" }} />
            </div>
        }
        return <div>
            <img alt="loading" src={loading} width="70px" style={{ borderRadius: "10px" }} />
        </div>
    }
}

Loading.propTypes = {
    type: PropTypes.string
}

export default Loading;