import Chat from "../../components/Chat"
import CheckBox from "../../components/CheckBox"
import greaterthan from "../../assets/imgs/greaterthan.png";
import Logout from "../../components/Logout";
import zoomin from "../../assets/imgs/zoomin.png";
import zoomout from "../../assets/imgs/zoomout.png";
import send from "../../assets/imgs/send.png";
import ProgressStatus from "../../components/ProgressStatus";
import megaphone from "../../assets/imgs/multicast.png";
import info from "../../assets/imgs/info.png";
import amanin from "../../assets/imgs/logo512.png";
import Timer from "../../components/Timer";
import { convertTimestampToTime } from "../../libs/utils";
import WebCam from "../../components/WebCam";
import ParticipantVideo from "../../components/ParticipantVideo";
import React from "react";
import { fetchConfirmValidationResult } from "../../libs/proctor_utils";
import ImageModal from "../../components/ImageModal";
import Troubleshooting from "../../components/Troubleshooting";
import { Lang } from "../../libs/Language";
import Language from "../../components/Language";
import ModalParticipant from "../../components/ModalParticipant/__index__";

export const __Component__ = function (_this, classes) {
    _this.inputRef = React.createRef();
    _this.inputMulticastRef = React.createRef();
    _this.searchRef = React.createRef();

    this.showPopupDetailParticipant = (participantId, participantUUId, videoElId) => {
        _this._action.fetchDetailParticipant(participantId, participantUUId, '', videoElId)
    }

    this.renderPopupModal = () => {
        return <ModalParticipant
            type={_this.state.type}
            open={_this.state.isOpen}
            onClose={_this._action.onClose}

            openImage={_this.state.isImageModalOpen}
            onImageOpen={_this._action.onImageModalOpen}
            onImageClose={_this._action.onImageModalClose}

            proctorerId={_this.context.profile.id}
            isLoading={_this.state.isLoading}
            data={_this.state.participantProfile}
            videoElId={_this.state.videoElId}
            handleValid={() => _this._action.handleValidInvalid("valid")}
            handleInvalid={() => _this._action.handleValidInvalid("invalid")}

            handleBlock={(participant) => _this._action.handleBlocked("block", participant)}
            handleOpenBlock={(participant) => _this._action.handleBlocked("open", participant)}

            handleCloseHelpButtonOnList={() => {
                let answer = window.confirm(`${Lang('Apakah anda yakin menghapus semua button bantuan', _this.state.lang, { en: 'Are you sure you want to delete all help buttons' })} ${_this.state.participantProfile.id} - ${_this.state.participantProfile.name} ?`);
                if (answer) {
                    if (_this.chatWs) {
                        _this.chatWs.sendChat(
                            _this.context.profile.id,
                            "close-help-button",
                            _this.state.participantProfile.id);
                    }
                }
            }}
            participantValidation={_this.state.participantValidation}
            photo={_this.state.participantProfile?.photo}
            currentTs={_this.state.currentTs}
            commonWs={_this.commonWs}
            imageWs={_this.imageWs}
            faceConfirmation={() => {
                if (window.confirm(Lang("Apakah wajah telah sesuai ?", _this.state.lang, { en: 'Has the face been matched?' }))) {
                    let vElId = _this.state.videoElId;

                    _this.setState({
                        temporaryVideoElId: vElId,
                        videoElId: "none"
                    })
                    fetchConfirmValidationResult(_this.state.participantUUId, (err) => {
                        console.error(err)
                        alert("Failed to confirm");
                    }).then((data) => {
                        if (data?.status === "success") {
                            _this._component.showPopupDetailParticipant(
                                _this.state.participantId,
                                _this.state.participantUUId,
                                _this.state.temporaryVideoElId)
                        } else {
                            alert(data.errorMessage);
                        }
                    })
                }
            }}
            // dynvalidation={{
            //     green: 0.45,
            //     yellow: 0.55,
            //     orange: 0.65,
            //     pink: 0.7,
            //     red: 1,
            //     value: 0.76
            // }}
            dynvalidation={{
                green: _this.state.participantProfile.green,
                yellow: _this.state.participantProfile.yellow,
                orange: _this.state.participantProfile.orange,
                pink: _this.state.participantProfile.pink,
                red: _this.state.participantProfile.red,
                value: _this.state.participantProfile.value
            }}
        >
            {_this.state.isOpen === true ?
                <Chat
                    title="Private Chat"
                    privateId={_this.context.profile.id}
                    onCloseParticipantChat={() => {
                        console.log("on close participant chat");
                    }}
                    onChatSend={
                        (msg) => {
                            let id = `${_this.context.profile.id}-${_this.state.participantProfile.id}`;

                            let ts = Date.now();
                            let message = _this.privateMessage.merge(
                                id,
                                ts,
                                _this.context.profile.id,
                                msg
                            )

                            _this.setState({
                                privateMessage: message
                            })

                            _this.chatWs && _this.privateMessage && _this.chatWs.sendChat(
                                _this.context.profile.id,
                                _this.state.participantProfile.id,
                                JSON.stringify(_this.privateMessage.pack(
                                    ts,
                                    _this.context.profile.id,
                                    msg
                                )),
                                _this.state.participantProfile.id
                            );
                        }
                    }
                    // closeChat={() => { _this.commonWs.sendClosePrivateChat() }}
                    broadcastMessage={_this.state.privateMessage === undefined ? [] : _this.state.privateMessage}
                />
                : null}
        </ModalParticipant>

    }

    this.renderPopupImageModal = () => {
        return <ImageModal
            imageUrl={_this.state.selectedImage}
            open={_this.state.isImageModalOpen}
            onClose={_this._action.onImageModalClose}
        />

    }

    this.renderProctorBroadcastChat = (message) => {
        return <div className={[classes.container_broadcastProc, classes.ascroll].join(" ")}>
            <div className={classes.container_broadcastProc_tittle}>
                <span>{Lang(`PERHATIAN`, _this.state.lang, { en: `NOTIFICATION` })}</span>
            </div>
            <div className={[
                classes.container_broadcastProc_message,
                classes.ascroll
            ].join(" ")}>
                {message}
            </div>
            <div className={classes.container_broadcastProc_input}>
                <input
                    type="text"
                    ref={_this.inputRef}
                    placeholder={Lang("Ketik pesan anda", _this.state.lang, { en: `Type your message` })}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            _this._action.handleChatBroadcast();
                        }
                    }}
                />
                <button
                    className={[classes.send, classes.button2div].join(" ")}
                    title="kirim"
                    onClick={
                        () => {
                            _this._action.handleChatBroadcast();
                        }
                    }>
                    <img alt="send" src={send} width="25px" />
                </button>
            </div>
        </div>
    }

    this.renderControlBox = () => {
        const calcWidth = (state) => {
            if (state === "normal") {
                return "280px"
            } else if (state === "small") {
                return "70px";
            } else {
                return "0px";
            }
        }
        return (
            <div
                style={{
                    width: calcWidth(_this.state.showControlBoxState)
                }}
                className={[classes.control, classes.ascroll].join(" ")}>
                <button
                    className={classes.control_toggle}
                    onClick={() => {
                        let dest = "small";
                        if (_this.state.showControlBoxState === "small") {
                            dest = "normal";
                        }
                        _this.setState({
                            showControlBoxState: "close"
                        });
                        setTimeout(() => {
                            _this.setState({
                                showControlBox: dest,
                                showControlBoxState: dest,
                            });
                        }, 1000)
                    }}
                    title="try me"
                >
                    <img
                        alt="<>"
                        src={greaterthan}
                        width="20px"
                        style={{
                            transform: _this.state.showControlBox === "small" ?
                                "rotate(180deg)" :
                                "rotate(0deg)"
                        }}
                    />
                </button>
                {_this.state.showControlBox !== "small" && <>
                    <div className={classes.control_timer}>
                        <Timer
                            value={`${convertTimestampToTime(_this.state.currentTs)}`}
                        />
                    </div>

                    <div className={classes.control_profile}>
                        <span>ID : {_this.context.profile.id}</span>
                        <span>{Lang(`Nama :`, _this.state.lang, { en: `Name :` })} {_this.context.profile.name}</span>
                    </div>
                </>
                }
                <div className={[
                    classes.control_webcam,
                    _this.state.showControlBox === "small" && classes.control_webcamsmall
                ].join(" ")}>
                    {_this.state.userMediaStream !== null ? (
                        <WebCam
                            isFullscreen={true}
                            stream={_this.state.userMediaStream}
                            videoId="proctorerVideo"
                            smallMode={_this.state.showControlBox === "small"}
                        />
                    ) : null}
                </div>
                {
                    _this.state.showControlBox !== "small" && <>
                        <div style={{ marginTop: "5px" }}>
                            <Chat
                                title="Broadcast Chat"
                                onChatSend={
                                    (msg) => {
                                        if (_this.chatWs) {
                                            _this.chatWs.sendChat(
                                                _this.context.profile.id,
                                                "broadcast",
                                                msg);
                                        }
                                    }
                                }
                                onIdClick={
                                    (id, uuid) => {
                                        this.showPopupDetailParticipant(id, uuid)
                                    }
                                }
                                handleCloseHelpButton={_this._action.handleCloseHelpButton}
                                proctorerId={_this.context.profile.id}
                                broadcastMessage={_this.state.broadcastMessage}
                                onValidation={_this._action.onValidation}
                                participantsList={_this.state.participants}
                            />
                        </div>

                        {
                            this.renderProctorBroadcastChat(_this.state.broadcastToParticipantMessage?.text)
                        }
                    </>}
            </div>
        );
    }

    this.getDisplayValue = (showOffline, participantStatus) => {
        if (showOffline) {
            return "flex"
        } else if (participantStatus !== "online") {
            return "none"
        } else return "flex"
    }

    this.generateParticipantVideoElement = (pv, participantId, allOffline, i, k) => {
        const p = participantId;

        let nml = _this.state.newMessageState;
        if (_this.state.participants[participantId].status === "online") {
            allOffline = false;
        }

        pv.push(
            <button
                key={k++}
                onClick={() => {
                    let elId = _this.state.participants[participantId].proctorId;
                    _this.setState({
                        isOpen: true,
                        isLoading: true,
                        videoElId: elId,
                    })
                    this.showPopupDetailParticipant(participantId,
                        _this.state.participants[participantId].uuid,
                        elId);
                }}
                style={{
                    display: this.getDisplayValue(_this.state.filterShowOffline, _this.state.participants[participantId].status)
                }}
                className={[classes.pv, classes.button2div].join(" ")}
            >
                <ParticipantVideo
                    scale={_this.state.scaleParticipantVideo}
                    participantId={participantId}
                    photoUrl={_this.state.participants[p].photo}
                    showID={!_this.state.filterHideID}
                    showEmotion={!_this.state.filterHideEmotion}
                    showScreen={!_this.state.filterHideScreen}
                    isParticipantModalOpen={_this.state.isOpen}
                    participantName={_this.state.participants[p].username}
                    proctorId={_this.state.participants[p].proctorId}
                    participantUUId={_this.state.participants[p].uuid}
                    proctorName={_this.state.participants[p].proctorName}
                    seq={_this.state.participants[p].seq}
                    validation={_this.state.participants[p].validation_status === 1 ? "valid" : "invalid"}
                    folderRand={_this.state.participants[p].folder_rand}
                    soundDetected={Date.now() - (_this.state.soundDetectedTs[p] === undefined ? 0 : _this.state.soundDetectedTs[p]) < 20000}
                    key={i++}
                    newMessage={nml.indexOf(participantId) > -1}
                    status={_this.state.participants[p].status} //offline, online, connecting
                    liveVideoAvailable={_this.state.participants[p].livevideo_available}
                    aiwarning={
                        _this.state.stateAI[participantId] ?
                            _this.state.stateAI[participantId].wt :
                            -1
                    }
                    emotion={(_this.state.stateEmotion[participantId]) ?
                        _this.state.stateEmotion[participantId].emotion :
                        { calm: -1, anxiety: -1, pasrah: -1 }}
                    dynvalidation={
                        _this.state.participants[participantId].dynamic_validation
                    }
                />
            </button>
        );
        return allOffline;
    }

    this.generateSearchForm = () => {
        return <div className={classes.search}>
            <div>Search</div>
            <input
                ref={_this.searchRef}
                placeholder={
                    Lang("Cari berdasarkan id atau nama peserta, tekan \"enter\"",
                        _this.state.lang, {
                        en: `Find id or name of participants, press "enter"`
                    })}
                type="text"
                onChange={(v) => {
                    const data = _this.searchRef.current.value;
                    _this.setState(prevState => ({
                        search: {
                            ...prevState.search,
                            requestedText: data
                        }
                    }))
                }}
                onKeyUp={(v) => {
                    if (v.key === "Enter") {
                        _this._action.searchByProctorAdminRoutineLastTs = 0;
                    }
                }}
            />
        </div>
    }

    this.renderProctorPage = () => {
        let pv = [];
        let allOffline = true;
        let i = 0;
        let k = 0;

        if (_this.pm !== null) {
            for (let p in _this.state.participants) {
                allOffline = this.generateParticipantVideoElement(pv, p, allOffline, i++, k++)
            }
        }

        return <div
            className={[classes.container, classes.ascroll].join(" ")}
            style={{ backgroundColor: _this.state.bgColor }}>
            <div className={classes.container_left}>
                <div className={classes.container_left_top}>
                    <div>
                        <img
                            alt="amanin"
                            src={amanin}
                            width="26px" />
                        <img
                            alt="client"
                            src={_this.state.examProfile.secondary_logo_url}
                            width="26px" />
                        <Troubleshooting />
                        <Language
                            currentLang={_this.state.lang}
                            onChangeLang={(v) => { _this.setState({ lang: v }) }}
                        />
                    </div>
                    <div>
                        <div className={classes.title}>
                            <span className={classes.big}>
                                {_this.state.examProfile.title}
                            </span>
                            <span className={classes.snall}>
                                {_this.state.examProfile["title-2"]}
                            </span>
                        </div>
                    </div>
                    <div style={{ marginRight: "40px" }}>
                        <Logout onLogout={() => _this._action.onLogout()} />
                    </div>
                </div>
                <div className={classes.container_left_bottom}>
                    <div className={classes.container_left_bottom_c}>
                        <div>
                            <button
                                className={classes.c_btn}
                                onClick={() => {
                                    _this.setState({
                                        scaleParticipantVideo:
                                            _this.state.scaleParticipantVideo > 1 ?
                                                _this.state.scaleParticipantVideo - 0.1 :
                                                _this.state.scaleParticipantVideo
                                    })
                                }}>
                                <img
                                    alt="zoomout"
                                    title="zoom out"
                                    src={zoomout}
                                    width="20px" />
                            </button>
                            <span style={{ fontSize: ".7rem", padding: "0px 3px 0px 3px" }}>{_this.state.scaleParticipantVideo.toFixed(1)}x</span>
                            <button
                                className={classes.c_btn}
                                onClick={() => {
                                    _this.setState({
                                        scaleParticipantVideo:
                                            _this.state.scaleParticipantVideo < 10 ? _this.state.scaleParticipantVideo + 0.1 :
                                                _this.state.scaleParticipantVideo
                                    })
                                }}>
                                <img alt="zoomin"
                                    title="zoom in"
                                    src={zoomin}
                                    style={{ transform: "scaleX(-1)" }}
                                    width="20px" />
                            </button>
                        </div>
                        <div>
                            <CheckBox
                                checked={!_this.state.filterHideID}
                                onChange={(e) => {
                                    _this.setState({
                                        filterHideID:
                                            !_this.state.filterHideID,
                                    });
                                }}
                            />
                            <span style={{ marginLeft: "2px" }}>{Lang(`Tampilkan ID`, _this.state.lang, { en: `Show ID` })}</span>
                        </div>
                        <div>
                            <CheckBox
                                checked={!_this.state.filterHideScreen}
                                onChange={(e) => {
                                    _this.setState({
                                        filterHideScreen:
                                            !_this.state.filterHideScreen,
                                    });
                                }}
                            />
                            <span style={{ marginLeft: "2px" }}>{Lang(`Tampilkan Screen`, _this.state.lang, { en: `Display the Screen` })}</span>
                        </div>
                        <div>
                            <CheckBox
                                checked={!_this.state.filterHideEmotion}
                                onChange={(e) => {
                                    _this.setState({
                                        filterHideEmotion:
                                            !_this.state.filterHideEmotion,
                                    });
                                }}
                            />
                            <span style={{ marginLeft: "2px" }}>{Lang(`Tampilkan Emosi`, _this.state.lang, { en: `Show Emotions` })}</span>
                        </div>
                        <div>
                            <CheckBox
                                checked={_this.state.filterShowOffline}
                                onChange={(e) => {
                                    _this.setState({
                                        filterShowOffline:
                                            !_this.state.filterShowOffline,
                                    });
                                }}
                            />
                            <span style={{ marginLeft: "2px" }}>{Lang(`Tampilkan Peserta Offline`, _this.state.lang, { en: `Show offline participants` })}</span>
                        </div>
                        <div>
                            <div className={classes.c}>
                                <button
                                    className={classes.c_btn}
                                    style={{ position: "relative" }}
                                    onClick={() => {
                                        _this.setState({
                                            showMulticastSend: !_this.state.showMulticastSend
                                        })
                                    }}>
                                    <img
                                        alt="multicast"
                                        title="multicast"
                                        src={megaphone}
                                        width="20px" />
                                </button>
                                {/* <span style={{ marginLeft: "2px" }}>Kirim pesan ke peserta yang diawasi</span> */}
                                {
                                    _this.state.showMulticastSend &&
                                    <div className={classes.multicast}>
                                        <div className={classes.multicast_tri} />
                                        <span>
                                            {Lang(`Kirim pesan ke seluruh peserta yang diawasi`, _this.state.lang, { en: `Send a message to all monitored participants.` })}
                                        </span>
                                        <div className={classes.multicast_content}>
                                            <input
                                                type="text"
                                                ref={_this.inputMulticastRef}
                                                placeholder={Lang("Ketik pesan anda", _this.state.lang, { en: `Type your message` })}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        _this._action.handleChatMulticast();
                                                    }
                                                }}

                                            />
                                            <button
                                                className={[classes.send, classes.button2div].join(" ")}
                                                title="kirim"
                                                onClick={
                                                    () => {
                                                        _this._action.handleChatMulticast();
                                                    }
                                                }>
                                                <img alt="send" src={send} width="25px" />
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {(allOffline && _this.state.filterShowOffline === false) && <div className={classes.alloff}>
                        <div>
                            <div>
                                <img alt="info" src={info} />
                            </div>
                            <div>
                                <span>Opsi 'Tampilkan Peserta Offline' tidak dipilih, Saat ini tidak ada peserta yang Online.
                                </span>
                                <span>
                                    Centang Opsi 'Tampilkan Peserta Offline' untuk melihat semua peserta.
                                </span>
                            </div>
                        </div>
                    </div>}
                    {
                        _this.context.profile.id.includes("proctoradmin") &&
                        this.generateSearchForm()
                    }
                    <div className={[
                        classes.container_left_bottom_p,
                        classes.ascroll
                    ].join(" ")}>
                        {
                            _this.state.search.isProcessing ?
                                <span className={[classes.search_info, classes.FadeInPage1s].join(" ")}>Processing ...</span> :
                                pv
                        }
                    </div>
                </div>
            </div>
            <div className={classes.container_right}>
                {this.renderControlBox()}
            </div>
            {_this.state.isOpen && this.renderPopupModal()}
            {_this.state.isImageModalOpen && this.renderPopupImageModal()}
        </div>
    }

    this.renderConnectionLost = () => {
        return <div className={[
            classes.container,
            classes.FadeInPage1s
        ].join(" ")}
            style={{ backgroundColor: _this.state.bgColor }}>
            <div className={classes.container_conmsg}>
                <div className={classes.container_conmsg_progress}>

                    <ProgressStatus type="connection" state="processing" />
                </div>
                <div className={classes.container_conmsg_content}>
                    {_this.state.connectingMessage}
                </div>
            </div>
        </div>
    }
}