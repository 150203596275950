import React from "react";
import classes from "./CountDown.module.scss";
import PropTypes from "prop-types";
import { Lang } from "../libs/Language";
import { LANG } from "../libs/utils";

class CountDown extends React.Component {
    renderNumber(title, value) {
        return <div className={classes.number}>
            <div className={classes.number_d}>{title}</div>
            <div className={classes.number_v}>
                <span>{value.length === 1 ? 0 : value[0]}</span>
                <span>{value.length === 1 ? value[0] : value[1]}</span>
            </div>
        </div>
    }

    renderDoublePoint(print = true) {
        return <div className={classes.number}>
            <div className={classes.number_d}> </div>
            <div className={classes.number_v}>
                <span className={classes.dp}>
                    {print ? ":" : ""}
                </span>
            </div>
        </div>
    }

    render() {
        return <div className={classes.container}>
            {this.renderNumber(Lang("Hari", LANG(), { en: `Day` }), String(this.props.value.days))}
            {this.renderDoublePoint(false)}
            {this.renderNumber(Lang("Jam", LANG(), { en: `Hour` }), String(this.props.value.hours))}
            {this.renderDoublePoint()}
            {this.renderNumber(Lang("Men", LANG(), { en: 'Min' }), String(this.props.value.minutes))}
            {this.renderDoublePoint()}
            {this.renderNumber(Lang("Det", LANG(), { en: `Sec` }), String(this.props.value.seconds))}
            {/* {this.renderDoublePoint()} */}
        </div>
    }
}

CountDown.propTypes = {
    value: PropTypes.object.isRequired
}

export default CountDown;