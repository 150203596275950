import React from "react";
import classes from "./Tick.module.scss";
import tick from "../assets/imgs/tick.png";
import wait from "../assets/imgs/wait.png";
import PropTypes from "prop-types";

class Tick extends React.Component {
    render() {
        return <div className={classes.container}
            style={{
                width: this.props.state !== "tick2" ? "10px" : "15px"
            }}
        >
            {this.props.state === "tick1" && <img className={classes.container_img1} alt="tick" src={tick} />
            }
            {this.props.state === "tick2" && <>
                <img className={classes.container_img1} alt="tick" src={tick} />
                <img className={classes.container_img2} alt="tick" src={tick} />
            </>}

            {this.props.state === "wait" && <img className={classes.container_img1} alt="tick" src={wait} />}
        </div>
    }
}

Tick.propTypes = {
    state: PropTypes.string.isRequired,

}
export default Tick;