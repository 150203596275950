import React, { Component, createRef } from 'react'
import classes from './ImageModal.module.scss'
import PropTypes from "prop-types";
import { Lang } from '../libs/Language';
import { LANG } from '../libs/utils';

class ImageModal extends Component {
    constructor(props) {
        super(props);
        this.imageRef = [createRef(), createRef()];
        this.containerImgRef = createRef();
        this.state = {
            zoomIn: false,
            useTransition: true
        }

        this.magnify = 1.8
    }

    isScreenShot() {
        return this.props.imageUrl.length > 1;
    }

    handleZoomInOut() {
        this.setState({
            useTransition: true
        })
        setTimeout(() => {
            if (this.state.zoomIn === false) {
                const newh = this.imageRef[0].current.height;
                this.imageRef[0].current.height = newh * this.magnify;
                if (this.isScreenShot()) {
                    this.imageRef[1].current.height = newh * this.magnify
                }

                this.containerImgRef.current.style.position = "fixed"
                this.setState({ zoomIn: true });
            } else {
                const newh = this.imageRef[0].current.height;
                this.imageRef[0].current.height = newh / this.magnify;
                if (this.isScreenShot()) {
                    this.imageRef[1].current.height = newh / this.magnify;
                }
                this.containerImgRef.current.style.position = "inherit"
                this.setState({ zoomIn: false });
            }
            // setTimeout(() => {
            // this.handleMouseHandler(e)
            // set visibility 
            // this.setImageVisibility("visible");
            // }, 500)
        }, 500)
    }

    handleMouseHandler(e) {
        if (this.state.zoomIn) {
            const x = e.pageX / window.innerWidth * 100;
            const y = e.pageY / window.innerHeight * 100;

            const centerx = window.innerWidth / this.magnify;
            const centery = window.innerHeight / this.magnify;

            const imgw = this.containerImgRef.current.offsetWidth
            const imgh = this.containerImgRef.current.offsetHeight

            const startx = centerx - (imgw / this.magnify);
            const starty = centery - (imgh / this.magnify);

            const top = Math.round(starty + (((50 - y) / 100) * imgh));
            const left = Math.round(startx + (((50 - x) / 100) * imgw));

            this.containerImgRef.current.style.top = `${top}px`;
            this.containerImgRef.current.style.left = `${left}px`;
        }
    }

    // setImageVisibility(v = "hidden") {
    //     return;
    //     this.imageRef[0].current.style.visibility = v
    //     if (this.isScreenShot()) {
    //         this.imageRef[1].current.style.visibility = v;
    //     }
    // }

    render() {
        return <div
            className={[classes.overlay, classes.FadeInPage1s].join(" ")}
            onMouseMove={(e) => {
                this.handleMouseHandler(e);
            }}
            onMouseUp={(e) => {
                setTimeout(() => {
                    this.handleMouseHandler(e)
                    setTimeout(() => {
                        if (this.state.zoomIn) {
                            this.setState({
                                useTransition: false
                            })
                        }
                    }, 500)
                }, 1000)
            }}
        >
            <div
                className={[classes.image_content, classes.ascroll].join(" ")}
                style={{ alignItems: this.isScreenShot() ? "flex-start" : "center" }}
            >
                <div
                    ref={this.containerImgRef}
                    className={[
                        classes.image_content_container,
                        classes.ascroll,
                        this.state.useTransition ?
                            classes.transitionactive :
                            classes.transitioninactive
                    ].join(" ")}
                    style={{
                        boxShadow: this.state.zoomIn ?
                            "0px 0px 0px 99999px rgb(73, 73, 73)" :
                            "inherit"
                    }}
                >
                    {this.props.imageUrl?.map((url, index) => {
                        return <img
                            key={url}
                            src={url}
                            alt="modal"
                            ref={this.imageRef[index]}
                            onClick={() => {
                                this.handleZoomInOut();
                            }}
                            onError={() => {
                                setTimeout(() => {
                                    this.imageRef[index].current.src = url;
                                }, 1000);
                            }}
                            className={[
                                this.state.zoomIn === false ? classes.zoomIn : classes.zoomOut,
                                classes.FadeInPage1s
                            ].join(" ")}
                        />
                    })}
                </div>
                <div className={classes.btn}>
                    <div onClick={this.props.onClose}
                        className={`${classes.btnHelp} ${classes.button2Help}`}
                    >
                        <span>{Lang(`Tutup`, LANG, { en: `Close` })}</span>
                    </div>
                </div>
            </div>
        </div>
    }
}

ImageModal.propTypes = {
    onClose: PropTypes.func,
    imageUrl: PropTypes.array
}

export default ImageModal;