let console = {};
console.log = function () { };
console.error = function () { };

// ai is implemented here
export const __Warning__ = function (_this) {
    _this.warningData = {
        start: 0,
        last: 0,
        data: [0, 0, 0, 0, 0]
    }
    _this.onExamFaceDetectionStatus = "done";
    // Menghitung total warning dalam range waktu yang ditentukan
    _this.totalWarning = 0;

    this.sendAIWarningSaveImage = (ev, id_error, info) => {
        //Kirim image ketika ada warning
        const used_ts = Date.now();
        console.log(used_ts, "sending img element to server because warning = ", info);
        console.log("EV = ", ev);
        // const used_ts = _this.currentTs

        // disable this, because it makes bottlenect,
        // now it's using filews not commonws, try again

        // the delay between one image to another
        const SAVE_IMAGE_DELAY = 15000
        // const SAVE_IMAGE_DELAY = 30000
        if (Date.now() - _this.lastSendImageWarningTs < SAVE_IMAGE_DELAY ) {
            console.log("ignore send image element to server, diff=", Date.now() - _this.lastSendImageWarningTs);
        } else {
            _this.lastSendImageWarningTs = Date.now();
            _this._action.sendImgElementToServer(ev.img_face, "face", used_ts)
            _this._action.sendImgElementToServer(ev.img_screen, "screen", used_ts)
        }

        _this.commonWs?.sendAIWarning(
            _this.context.profile.id,
            id_error,
            used_ts,
            _this.warningData);
        // if there are warning tab is open send here

        // send again aiwarning2file, to save warning to file
        console.log("[aiwarning2file] send aiwarning2file");
        _this.imageWs?.sendAIWarning2File(
            _this.context.profile.id,
            id_error,
            used_ts,
            _this.warningData
        )
    }

    this.examFaceDetectionHandler = (ev) => {
        _this.onExamFaceDetectionStatus = "done";
        const faceNumberWarning = this._faceNumberWarning(ev);
        const faceIdWarning = this._faceIdWarning(ev, _this.state.examProfile.face_detection_on_exam_thresh);
        const openNewTabOrBrowserWarning = this._openNewTabOrBrowserWarning();

        if (faceNumberWarning === null || faceIdWarning === null) {
            return;
        }
        let isWarning = false;
        let info = [];
        let id_error = -1;

        // untuk count jumlah warning
        if (_this.state.eventState.event !== "normal") {
            _this.totalWarning += 1;
        }

        if (faceNumberWarning.event === 'warning') {
            info.push(faceNumberWarning.info);
            isWarning = true;
            id_error = faceNumberWarning.id_error;
            _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay
        }
        if (faceIdWarning.event === 'warning') {
            info.push(faceIdWarning.info);
            isWarning = true
            id_error = faceIdWarning.id_error; // Tidak sama dengan identitas
            _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay
        }

        // handle warning for face cases
        if (isWarning) {
            console.log(Date.now(), "SEND WARNING FOR FACE", info)
            this.sendAIWarningSaveImage(ev, id_error, info);
        }

        if (openNewTabOrBrowserWarning.event === 'warning') {
            info.push(openNewTabOrBrowserWarning.info);
            isWarning = true
            id_error = openNewTabOrBrowserWarning.id_error;
            _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay

            // handle warning for open new tab case
            this.sendAIWarningSaveImage(ev, id_error, info);
            console.log(Date.now(), "SEND WARNING FOR TAB", info)
        }

        if (_this.warningData.start === 0) {
            _this.warningData.start = Date.now();
        }
        _this.warningData.last = Date.now();

        if (isWarning === false) {
            _this.setState({
                eventState: {
                    event: 'normal',
                    info: ['Usahakan wajah anda selalu tertangkap kamera.']
                }
            })
        } else {
            _this.setState({
                eventState: {
                    event: 'warning',
                    info: info
                }
            })
        }

        // setInterval(() => {
        //   console.log("DISPLAY FROM WARNING INTERVAL")
        //   const { display_warning_on_exam_thresh, interprocess_ai_delay } = _this.state.examProfile;
        //   // let displayWarning = "Warning";
        //   // let limit = (display_warning_on_exam_thresh / (interprocess_ai_delay * 1000)) / 2;
        //   let limit = ((1 * 30 * 1000) / (interprocess_ai_delay * 1000)) / 2;

        //   if (_this.totalWarning >= limit) {
        //     _this.totalWarning = 0
        //       console.log("DISPLAY WARNING");
        //     setTimeout(() => {
        //     console.log("DISPLAY NORMAL");
        //     }, 30000);
        //   }

        //   // }, _this.state.examProfile.display_warning_on_exam_thresh);
        // }, 1 * 30 * 1000);
    }

    // this.DISABLE_WARNING_ROUTINE = true
    this.DISABLE_WARNING_ROUTINE = false
    this.warningIdentificationRoutine = () => {
        if (this.DISABLE_WARNING_ROUTINE === true) {
            console.log("disable warning");
            return;
        }
        // console.log("AI ROUTINE 1/3");
        if (_this.pai !== null &&
            _this.state.userMediaStream !== null
            &&
            (
                (_this.state.displayMediaStream !== null && _this.mode !== "mobile") ||
                (_this.mode === "mobile")
            )
        ) {
            if (_this.onExamFaceDetectionStatus === "processing") {
                console.log("still processing, skip");
                return;
            } else {
                _this.onExamFaceDetectionStatus = "processing";
            }

            // get face image and screenshot if any, pass it to handler
            const img_face = _this.pai.captureVideoElementToImage(document.getElementById("inputVideo"));
            const img_screen = _this.pai.captureVideoElementToImage(document.getElementById("inputDisplayVideo"));
            // console.log("AI ROUTINE 2/3");

            if (img_face) {
                // console.log("AI ROUTINE 3/3, on exam face detection");

                _this.pai.onExamFaceDetection(img_face, img_screen).then((ev) => {
                    this.examFaceDetectionHandler(ev)
                })
            } else {
                // very important to set status done here.
                _this.onExamFaceDetectionStatus = "done";
            }
        } else {
            // console.log("PAI IS NULL");
        }
    }

    this._faceIdWarning = (ev, threshold) => {
        if (ev === undefined || ev.face === undefined) {
            return null;
        }

        // console.log(_this.state.examProfile);
        // console.log(ev.faceid.dist, threshold);
        if (ev.faceid.dist > threshold) {
            return {
                event: 'warning',
                info: 'Wajah tidak sesuai dengan kartu identitas.',
                id_error: 2
            }
        }
        return {
            event: 'normal'
        };
    }

    this._faceNumberWarning = (ev) => {
        if (ev === undefined || ev.face === undefined) {
            return null;
        }
        console.log(ev);
        if (ev.face.number === 0) {
            return {
                event: 'warning',
                info: "Pelanggaran terdeteksi, wajah anda tidak tertangkap kamera, " +
                    "usahakan wajah anda selalu tertangkap kamera.",
                id_error: 0
            }
        } else if (ev.face.number > 1) {
            return {
                event: 'warning',
                info: "Pelanggaran terdeteksi, ada lebih dari satu wajah tertangkap kamera. "
                    + "Diharapkan tiap peserta mengerjakan ujian dengan jujur.",
                id_error: 1
            }
        }
        return {
            event: 'normal'
        };
    }

    this._openNewTabOrBrowserWarning = () => {
        let warn = false;

        if (_this.state.examProfile.useExtension) {
            if (_this.quizActivity.tab === false || _this.quizActivity.windowFocused === false) {
                warn = true;
            }
        } else if (document.visibilityState === 'hidden') {
            warn = true;

        }

        if (warn) {
            return {
                event: 'warning',
                info: "Pelanggaran terdeteksi, ada tab atau browser baru dibuka.",
                id_error: 3
            }
        }

        return {
            event: 'normal'
        };
    }

    this._printScreenWarning = (event) => {
        if (event && (event.key === "PrintScreen" || event.keyCode === 44)) {
            _this.setState({
                eventState: {
                    event: 'warning',
                    info: "Pelanggaran terdeteksi, peserta melakukan Print Screen atau Screenshot."
                }
            })
            _this.commonWs.sendAIWarning(_this.context.profile.id, 4, _this.currentTs);
        }
    }
}